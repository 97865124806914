import useTranslate from '@hooks/useTranslate';
import SCREENS from '@constants/Screens';
import Screen from '@ui/templates/Screen';
import { useNavigation } from '@react-navigation/native';
import useToast from '@hooks/useToast';
import { useFormik } from 'formik';
import { object, string } from '@utils/yup';
import apiErrorsToFormik from '@utils/apiErrorsToFormik';
import { useState } from 'react';
import { Button, Input, View } from '@ui/atoms';
import { FormControl } from '@ui/molecules';
import useInviteUserMutation from '@hooks/mutations/useInviteUserMutation';

function InviteUserScreen() {
  const toast = useToast();
  const { t } = useTranslate();
  const { goBack } = useNavigation();

  const { mutateAsync } = useInviteUserMutation();

  const [loading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
    },
    validationSchema: object({
      firstname: string().required(),
      lastname: string().required(),
      email: string().email().required(),
    }),
    onSubmit: async (data, { setErrors }) => {
      try {
        setLoading(true);

        await mutateAsync(data);
        toast.success(t('InviteUserScreen.success'));

        goBack();
      } catch (err) {
        if (422 === err.response?.status) {
          apiErrorsToFormik(err.response.data.errors, setErrors);
          toast.error(t('errors.checkData'));
        } else {
          toast.error(t('errors.api.default'));
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Screen title={t('InviteUserScreen.title')} presentation={InviteUserScreen.presentation} scrollable>
      <View>
        <FormControl
          label={t('InviteUserScreen.firstname')}
          sx={{ mb: '$4' }}
          error={form.touched.firstname && form.errors.firstname}
          required
        >
          <Input
            value={form.values.firstname}
            onChangeText={form.handleChange('firstname')}
            onBlur={form.handleBlur('firstname')}
          />
        </FormControl>
        <FormControl
          label={t('InviteUserScreen.lastname')}
          sx={{ mb: '$4' }}
          error={form.touched.lastname && form.errors.lastname}
          required
        >
          <Input
            value={form.values.lastname}
            onChangeText={form.handleChange('lastname')}
            onBlur={form.handleBlur('lastname')}
          />
        </FormControl>
        <FormControl
          label={t('InviteUserScreen.email')}
          sx={{ mb: '$4' }}
          error={form.touched.email && form.errors.email}
          required
        >
          <Input
            value={form.values.email}
            onChangeText={form.handleChange('email')}
            onBlur={form.handleBlur('email')}
          />
        </FormControl>
        <Button color="primary" onPress={form.submitForm} loading={loading} title={t('InviteUserScreen.submit')} />
      </View>
    </Screen>
  );
}

InviteUserScreen.presentation = 'modal';
InviteUserScreen.screenName = SCREENS.inviteUser;

export default InviteUserScreen;
