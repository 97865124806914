import SurveyAnswersScreen from './SurveyAnswersScreen.json';
import CreateSurveyScreen from './CreateSurveyScreen.json';
import AddPostScreen from './AddPostScreen.json';
import LoginScreen from './LoginScreen.json';
import RegisterScreen from './RegisterScreen.json';
import ForgottenScreen from './ForgottenScreen.json';
import SelectResidenceScreen from './SelectResidenceScreen.json';
import FeedScreen from './FeedScreen.json';
import NeighborsScreen from './NeighborsScreen.json';
import NoAccessScreen from './NoAccessScreen.json';
import DocumentsScreen from './DocumentsScreen.json';
import EditProfileScreen from './EditProfileScreen.json';
import EditPasswordScreen from './EditPasswordScreen.json';
import PostScreen from './PostScreen.json';
import CreateEventScreen from './CreateEventScreen.json';
import EventsScreen from './EventsScreen.json';
import EventScreen from './EventScreen.json';
import EditEventScreen from './EditEventScreen.json';
import ResourcesScreen from './ResourcesScreen.json';
import EditPostScreen from './EditPostScreen.json';
import SurveysScreen from './SurveysScreen.json';
import SurveyScreen from './SurveyScreen.json';
import PostLikesScreen from './PostLikesScreen.json';
import NotificationsScreen from './NotificationsScreen.json';
import NotificationsSettingsScreen from './NotificationsSettingsScreen.json';
import InviteUserScreen from './InviteUserScreen.json';
import JoinResidenceScreen from './JoinResidenceScreen.json';
import BlockedUsersScreen from './BlockedUsersScreen.json';
import NeighborScreen from './NeighborScreen.json';

export default {
  LoginScreen,
  RegisterScreen,
  ForgottenScreen,
  SelectResidenceScreen,
  FeedScreen,
  NeighborsScreen,
  NoAccessScreen,
  DocumentsScreen,
  EditProfileScreen,
  EditPasswordScreen,
  PostScreen,
  CreateEventScreen,
  EventsScreen,
  EventScreen,
  EditEventScreen,
  ResourcesScreen,
  EditPostScreen,
  SurveysScreen,
  SurveyAnswersScreen,
  CreateSurveyScreen,
  AddPostScreen,
  SurveyScreen,
  PostLikesScreen,
  NotificationsScreen,
  NotificationsSettingsScreen,
  InviteUserScreen,
  JoinResidenceScreen,
  BlockedUsersScreen,
  NeighborScreen,
};
