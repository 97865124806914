
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({
  "replaceAttrValues": {
    "#000": "{props.color}"
  }
});
        /* eslint-disable react/jsx-props-no-spreading */
import Input from '@ui/atoms/Input';

  const EyeSlashIcon = __ReactNativeSvgLoader(import('@src/assets/icons/eyeSlash.svg'));
  ;

  const EyeIcon = __ReactNativeSvgLoader(import('@src/assets/icons/eye.svg'));
  ;
import { forwardRef, useState } from 'react';
import { Pressable } from '@ui/atoms/index';

const PasswordInput = forwardRef(function PasswordInput(props, ref) {
  const [hidden, setHidden] = useState(true);

  const Icon = hidden ? EyeSlashIcon : EyeIcon;
  const handlePress = (e) => {
    e.stopPropagation();
    setHidden(!hidden);
  };

  return (
    <Input
      ref={ref}
      endIcon={
        <Pressable onPress={handlePress} sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Icon height={25} width={25} color="grey" />
        </Pressable>
      }
      secureTextEntry={hidden}
      {...props}
    />
  );
});

export default PasswordInput;

        