import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import { useQueryClient } from 'react-query';
import useApiMutation from './useApiMutation';

function useCommentMutation(options) {
  const queryClient = useQueryClient();

  return useApiMutation('post', `/v1/comments`, {
    ...options,
    onSuccess: async (res, variables, ...args) => {
      await queryClient.invalidateQueries(QUERY_CACHE_KEY.comments(variables.model.type, variables.model.id));

      if ('function' === typeof options?.onSuccess) {
        options.onSuccess(res, variables, ...args);
      }
    },
  });
}

export default useCommentMutation;
