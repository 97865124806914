import { useQuery } from 'react-query';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';

function useSurveyQuery(id, query, options) {
  return useQuery(QUERY_CACHE_KEY.survey(id, query), {
    staleTime: 30 * 1000,
    ...options,
  });
}

export default useSurveyQuery;
