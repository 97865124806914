import useDialogStore from '@src/stores/dialogStore';

function useDialog() {
  const open = useDialogStore((state) => state.open);
  const close = useDialogStore((state) => state.close);

  return { open, close };
}

export default useDialog;
