import { useQueryClient } from 'react-query';
import { USER_RELATED_CACHE_KEY } from '@constants/QueryCacheKey';
import useApiMutation from './useApiMutation';

function useBlockUserMutation(userId, options) {
  const queryClient = useQueryClient();

  return useApiMutation('post', `/v1/users/${userId}/block`, {
    ...options,
    onSuccess: () => {
      USER_RELATED_CACHE_KEY.forEach((key) => queryClient.invalidateQueries(key));
    },
  });
}

export default useBlockUserMutation;
