import { useQuery } from 'react-query';
import QUERY_CACHE_KEY from '@src/constants/QueryCacheKey';

function useNeighborsQuery(options) {
  return useQuery(QUERY_CACHE_KEY.neighbors(), {
    staleTime: 10 * 60 * 1000,
    ...options,
  });
}

export default useNeighborsQuery;
