import useWebView from '@hooks/useWebView';
import { useCallback } from 'react';
import config from '@src/config';
import useTranslate from '@hooks/useTranslate';

function useOpenGtu() {
  const { t } = useTranslate();
  const openWebView = useWebView();

  return useCallback(() => {
    openWebView(t('common.gtu'), config.gtu);
  }, [t, openWebView]);
}

export default useOpenGtu;
