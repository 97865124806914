/* eslint-disable react/jsx-props-no-spreading */
import { View } from '@ui/atoms';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';

function Panel({ children, sx, shadow, borderless, as, ...props }) {
  const Component = as ?? View;

  return (
    <Component
      sx={{
        bg: '$common.white',
        borderRadius: '$2',
        p: '$4',
        borderWidth: borderless ? 0 : 1,
        borderColor: '$grey.200',
        boxShadow: shadow ? 'sm' : undefined,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Component>
  );
}

Panel.propTypes = {
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.node.isRequired,
  sx: CustomPropTypes.style,
  shadow: PropTypes.bool,
  borderless: PropTypes.bool,
};

Panel.defaultProps = {
  as: undefined,
  sx: undefined,
  shadow: false,
  borderless: false,
};

export default Panel;
