import { useCallback } from 'react';
import { Platform } from 'react-native';
import useWebView from './useWebView';

export default function useOpenFile() {
  const openWebView = useWebView();

  return useCallback(
    (file) => {
      if ('web' === Platform.OS) {
        window.open(file.url);
      } else {
        openWebView(file.name, file.url);
      }
    },
    [openWebView]
  );
}
