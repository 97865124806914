export default {
  $common: {
    white: '#ffffff',
    black: '#000000',
  },
  $text: {
    primary: '#273238',
  },
  $primary: {
    main: '#0F6375',
  },
  $secondary: {
    main: '#0F6375',
  },
  $grey: {
    100: '#FBFBFB',
    200: '#f1f1f1',
    300: '#E5E5E5',
    400: '#C4C4C4',
    500: '#B3B3B3',
    600: '#A3A3A3',
    700: '#5A5A5A',
    800: '#333333',
    900: '#1A1A1A',
  },
  $transparent: {
    primary: {
      15: 'rgba(15, 99, 117, .15)',
      20: 'rgba(15, 99, 117, .20)',
      50: 'rgba(15, 99, 117, .5)',
    },
    white: {
      30: 'rgba(255, 255, 255, .3)',
      70: 'rgba(255, 255, 255, .7)',
    },
    black: {
      70: 'rgba(0, 0, 0, .7)',
    },
    red: {
      10: 'rgba(241, 39, 39, .1)',
    },
  },
  $red: {
    main: '#F12727',
    dark: '#C33C3C',
    notifications: '#e41e3f',
  },
  $green: {
    main: '#00BE86',
  },
  $blue: {
    main: '#217AEF',
  },
};
