import useTranslate from '@hooks/useTranslate';
import useUnblockUserMutation from '@hooks/mutations/useUnblockUserMutation';
import { Button, Flex, Text, UserAvatar } from '@ui/atoms';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';

function BlockedUserRow({ user, isLast }) {
  const { t } = useTranslate();

  const { mutate, isLoading } = useUnblockUserMutation(user.id);

  return (
    <Flex
      alignItems="center"
      sx={{
        p: '$4',
        borderBottomWidth: isLast ? 0 : 1,
        borderBottomColor: '$grey.200',
      }}
    >
      <UserAvatar sx={{ mr: '$3' }} size="large" user={user} />
      <Text variant="subtitle" sx={{ color: '$primary.main', flex: 1 }}>
        {user.fullname}
      </Text>
      <Button loading={isLoading} onPress={() => mutate()} title={t('ui.BlockedUserRow.unblock')} color="primary" />
    </Flex>
  );
}

BlockedUserRow.propTypes = {
  user: PropTypes.shape({
    id: CustomPropTypes.uuid,
    fullname: PropTypes.string,
  }).isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default BlockedUserRow;
