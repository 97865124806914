import { useState } from 'react';
import { AutoHeightInput, Button, FilePicker, Flex, Input, Ionicons, Select, Text, View } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import { FormControl } from '@ui/molecules';
import POST_TYPE, { getPostTypeMaxLength, getPublishablePostTypes, hasCategory } from '@constants/PostType';
import { FILE_MIMES } from '@constants/MimeType';
import useTranslate from '@hooks/useTranslate';
import useToast from '@hooks/useToast';
import useProfileQuery from '@hooks/queries/useProfileQuery';
import usePostCategoriesQuery from '@hooks/queries/usePostCategoriesQuery';
import { useFormik } from 'formik';
import { array, mixed, object, string } from '@utils/yup';
import apiErrorsToFormik from '@utils/apiErrorsToFormik';
import useUploadMutation from '@hooks/mutations/useUploadMutation';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import PostEmbed from '@ui/organisms/PostEmbed';
import ImagesPicker from '@ui/organisms/ImagesPicker';
import useCurrentResidenceQuery from '@hooks/queries/useCurrentResidenceQuery';
import MAX_LENGTH from '@constants/MaxLength';

const emptyArray = [];

function PostForm({ post, onSubmit, embed }) {
  const { t } = useTranslate();
  const toast = useToast();

  const { data: residence } = useCurrentResidenceQuery();
  const { data: profile } = useProfileQuery();

  const [loading, setLoading] = useState(false);

  const isEdit = !!post;

  const { data: categories } = usePostCategoriesQuery({
    placeholderData: emptyArray,
    select: (data) => data?.items ?? emptyArray,
  });

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: embed ? POST_TYPE.repost : post?.type ?? '',
      categoryId: post?.category?.id || '',
      title: post?.title || '',
      content: post?.content || '',
      link: post?.link || '',
      images: post?.images || [],
      file: post?.file || null,
    },
    validationSchema: object({
      type: string().required(),
      categoryId: string().when('type', {
        is: (value) => hasCategory(value),
        then: string().required(),
      }),
      title: string().when('type', {
        is: POST_TYPE.repost,
        then: string().nullable(),
        otherwise: string().required(),
      }),
      content: string().when('type', {
        is: POST_TYPE.repost,
        then: string().nullable(),
        otherwise: string().required(),
      }),
      link: string().nullable(),
      images: array().max(15),
      file: mixed().nullable(),
    }),
    onSubmit: async (data, { setErrors }) => {
      try {
        setLoading(true);

        const values = {
          ...data,
          title: data.title ?? null,
          content: data.content ?? null,
          link: data.type === POST_TYPE.alert ? null : data.link,
          categoryId: 0 < data.categoryId.length ? data.categoryId : undefined,
          images: data.images.map((image) => image.id),
          file: data.file?.id,
        };

        if (isEdit) {
          values.file = data.file?.id === post.file?.id ? undefined : data.file?.id;

          values.images = data.images
            .filter((image) => !post.images.some((original) => original.id === image.id))
            .map((image) => image.id);

          values.shouldDeleteFile = isEdit ? null === data.file : undefined;

          values.imagesToDelete = post.images
            .map((image) => image.id)
            .filter((id) => !data.images.some((image) => image.id === id));
        } else {
          values.embed = embed;
        }

        await onSubmit(values);
      } catch (err) {
        if (422 === err.response?.status) {
          apiErrorsToFormik(err.response.data.errors, setErrors);
          toast.error(t('errors.checkData'));
        } else {
          toast.error(t('errors.api.default'));
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const { mutate: uploadImage, isLoading: uploadingImage } = useUploadMutation({
    onSuccess: (res) => {
      form.setFieldValue('images', [...form.values.images, res.data]);
    },
    onError: (err) => {
      if (422 === err.response?.status) {
        toast.error(err.response.data.errors?.file[0]);
      } else {
        toast.error(t('errors.api.default'));
      }
    },
  });

  const { mutate: uploadFile, isLoading: uploadingFile } = useUploadMutation({
    onSuccess: (res) => {
      form.setFieldValue('file', res.data);
    },
    onError: (err) => {
      if (422 === err.response?.status) {
        toast.error(err.response.data.errors?.file[0]);
      } else {
        toast.error(t('errors.api.default'));
      }
    },
  });

  const handleChangeType = (value) => {
    form.setValues((prev) => ({
      ...prev,
      type: value,
      categoryId: '',
    }));
  };

  const handleDeleteImage = (image) => {
    form.setFieldValue(
      'images',
      form.values.images.filter((item) => item.id !== image.id)
    );
  };

  const handleDeleteFile = () => {
    form.setFieldValue('file', null);
  };

  const typeCategories = categories
    .filter((category) => category.type === form.values.type)
    .map((category) => ({
      value: category.id,
      label: category.name,
    }));

  const types = getPublishablePostTypes(profile.role, residence.type).map((postType) => ({
    value: postType,
    label: t('constants.PostType', { value: postType }),
  }));

  const category = categories.find((item) => item.id === form.values.categoryId);

  const contentMaxLength = getPostTypeMaxLength(form.values.type);

  return (
    <View>
      {!embed && !isEdit && (
        <Flex justifyContent="space-between" sx={{ mb: '$4' }} wrap>
          <View sx={{ width: ['100%', '49%', '40%'], mb: ['$4', 0] }}>
            <FormControl label={t('ui.PostForm.type.label')} error={form.touched.type && form.errors.type} required>
              <Select
                placeholder={{ label: t('common.verb.select'), value: '' }}
                items={types}
                value={form.values.type}
                onValueChange={handleChangeType}
              />
            </FormControl>
          </View>
          {hasCategory(form.values.type) && (
            <FormControl
              label={t('ui.PostForm.category.label')}
              sx={{ width: ['100%', '49%', '58%'] }}
              error={form.touched.categoryId && form.errors.categoryId}
              required
            >
              <Select
                placeholder={{ label: t('common.verb.select'), value: '' }}
                items={typeCategories}
                value={form.values.categoryId}
                onValueChange={(value) => form.setFieldValue('categoryId', value)}
              />
            </FormControl>
          )}
        </Flex>
      )}
      {'REPORT' === category?.code && !isEdit && (
        <View
          sx={{
            p: '$4',
            mb: '$4',
            bg: '$secondary.main',
            borderRadius: '$1',
          }}
        >
          <Text sx={{ color: '$common.white' }}>
            <FormattedMessage id="ui.PostForm.reportWarning" />
          </Text>
        </View>
      )}
      <FormControl
        label={t('ui.PostForm.title.label')}
        sx={{ mb: '$4' }}
        error={form.touched.title && form.errors.title}
        required={form.values.type !== POST_TYPE.repost}
      >
        <Input
          value={form.values.title}
          onChangeText={form.handleChange('title')}
          onBlur={form.handleBlur('title')}
          maxLength={MAX_LENGTH.postTitle}
        />
      </FormControl>
      <FormControl
        label={t('ui.PostForm.content.label', { maxLength: contentMaxLength })}
        sx={{ mb: '$4' }}
        error={form.touched.content && form.errors.content}
        required={form.values.type !== POST_TYPE.repost}
      >
        <AutoHeightInput
          value={form.values.content}
          onChangeText={form.handleChange('content')}
          onBlur={form.handleBlur('content')}
          maxLength={contentMaxLength}
          minHeight={100}
          withCharCounter
        />
      </FormControl>
      {form.values.type !== POST_TYPE.alert && (
        <FormControl
          label={t('ui.PostForm.link.label')}
          sx={{ mb: '$4' }}
          error={form.touched.link && form.errors.link}
        >
          <Input
            value={form.values.link}
            onChangeText={form.handleChange('link')}
            onBlur={form.handleBlur('link')}
            maxLength={MAX_LENGTH.postLink}
          />
        </FormControl>
      )}
      {embed ? (
        <PostEmbed embed={{ ...embed, available: true }} sx={{ my: '$4' }} disabled />
      ) : (
        <>
          <View sx={{ mb: '$4' }}>
            <Text variant="subtitle" sx={{ color: '$primary.main', mb: '$2' }}>
              <FormattedMessage id="ui.PostForm.addFile" />
            </Text>
            <FilePicker
              file={form.values.file}
              loading={uploadingFile}
              onChange={uploadFile}
              mimeTypes={FILE_MIMES}
              onDelete={handleDeleteFile}
            />
          </View>
          <View sx={{ mb: '$6' }}>
            <Text variant="subtitle" sx={{ color: '$primary.main', mb: '$2' }}>
              <FormattedMessage id="ui.PostForm.addImages" />
            </Text>
            <ImagesPicker
              images={form.values.images.map((temporaryUpload) => ({
                id: temporaryUpload.id,
                uri: temporaryUpload.preview ?? temporaryUpload.small,
              }))}
              max={15}
              loading={uploadingImage}
              onSelect={uploadImage}
              sx={{ mt: '$3' }}
              onDelete={handleDeleteImage}
            />
          </View>
        </>
      )}

      <Button
        color="primary"
        onPress={form.submitForm}
        disabled={uploadingImage}
        loading={loading}
        title={t('ui.PostForm.submit')}
        startIcon={<Ionicons name="checkmark" size={24} sx={{ color: '$common.white' }} />}
      />
    </View>
  );
}

PostForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  post: CustomPropTypes.post,
  embed: PropTypes.shape({
    id: CustomPropTypes.uuid,
    type: PropTypes.string,
  }),
};

PostForm.defaultProps = {
  post: null,
  embed: null,
};

export default PostForm;
