import { Flex, Ionicons, Skeleton, Text, View } from '@ui/atoms';
import useThemeColor from '@hooks/useThemeColor';
import { FormattedMessage } from 'react-intl';
import { Panel } from '@ui/molecules';

function EventScreenSkeleton() {
  const greyColor = useThemeColor('$grey.700');

  return (
    <Panel
      sx={{
        maxWidth: [null, null, 800],
        width: '100%',
        marginVertical: [0, 0, '$6'],
        borderRadius: [0, 0, '$2'],
        borderWidth: [0, 0, 1],
        marginLeft: 'auto',
        marginRight: 'auto',
        p: 0,
        pb: ['$8', null, '$4'],
      }}
    >
      <Skeleton width="100%" height={[240, null, null, 360]} radius={0} sx={{ mb: '$2' }} />
      <View sx={{ mt: '$5', mx: '$4' }}>
        <Skeleton width={300} height={26} radius={13} sx={{ mb: '$2', alignSelf: 'flex-start' }} />
        <Flex alignItems="center" sx={{ mt: '$4' }}>
          <Ionicons name="calendar-sharp" size={25} color={greyColor} />
          <View sx={{ ml: '$4' }}>
            <Skeleton width={140} height={14} sx={{ mb: '$1' }} />
            <Skeleton width={90} height={14} />
          </View>
        </Flex>
        <Flex alignItems="center" sx={{ mt: '$4' }}>
          <Ionicons name="location-sharp" size={25} color={greyColor} />
          <View sx={{ ml: '$4' }}>
            <Skeleton width={140} height={14} sx={{ mb: '$1' }} />
          </View>
        </Flex>
        <View sx={{ mt: '$6' }}>
          <Text variant="subtitle2">
            <FormattedMessage id="EventScreen.description" />
          </Text>
          <Skeleton width="100%" height={14} sx={{ mt: '$2', mb: '$1' }} />
          <Skeleton width="100%" height={14} sx={{ mb: '$1' }} />
          <Skeleton width="100%" height={14} sx={{ mb: '$1' }} />
          <Skeleton width={240} height={14} sx={{ mb: '$1' }} />
        </View>
        <View sx={{ mt: '$6' }}>
          <Text variant="subtitle2">
            <FormattedMessage id="EventScreen.organizer" />
          </Text>
          <Flex alignItems="center" sx={{ mt: '$2' }}>
            <Skeleton height={32} width={32} radius={16} />
            <View sx={{ ml: '$2' }}>
              <Skeleton width={140} height={14} sx={{ mb: '$1' }} />
            </View>
          </Flex>
        </View>
        <View sx={{ mt: '$6' }}>
          <Text variant="subtitle2">
            <FormattedMessage id="EventScreen.participants" values={{ total: 0 }} />
          </Text>
          <Flex alignItems="center" sx={{ mt: '$2' }}>
            <Skeleton height={32} width={32} radius={16} />
            <View sx={{ ml: '$2' }}>
              <Skeleton width={140} height={14} sx={{ mb: '$1' }} />
            </View>
          </Flex>
        </View>
      </View>
    </Panel>
  );
}

export default EventScreenSkeleton;
