const SCREENS = {
  login: 'LoginScreen',
  register: 'RegisterScreen',
  forgotten: 'ForgottenScreen',
  home: 'HomeScreen',
  feed: 'FeedScreen',
  neighbors: 'NeighborsScreen',
  documents: 'DocumentsScreen',
  resources: 'ResourcesScreen',
  editProfile: 'EditProfileScreen',
  editPassword: 'EditPasswordScreen',
  blockedUsers: 'BlockedUsersScreen',
  post: 'PostScreen',
  neighbor: 'NeighborScreen',
  createEvent: 'CreateEventScreen',
  events: 'EventsScreen',
  event: 'EventScreen',
  editEvent: 'EditEventScreen',
  editPost: 'EditPostScreen',
  surveys: 'SurveysScreen',
  surveyAnswers: 'SurveyAnswersScreen',
  createSurvey: 'CreateSurveyScreen',
  addPost: 'AddPostScreen',
  survey: 'SurveyScreen',
  postLikes: 'PostLikesScreen',
  notifications: 'NotificationsScreen',
  notificationsSettings: 'NotificationsSettingsScreen',
  web: 'WebScreen',
  inviteUser: 'InviteUserScreen',
  joinResidence: 'JoinResidenceScreen',
};

export default SCREENS;
