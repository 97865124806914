import { useQueryClient } from 'react-query';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import useApiMutation from './useApiMutation';

function useCancelEventMutation(id, options) {
  const queryClient = useQueryClient();

  return useApiMutation('delete', `/v1/events/${id}`, {
    ...options,
    onSuccess: async (res, ...args) => {
      // Provide fast feedback to user
      queryClient.setQueryData(QUERY_CACHE_KEY.event(id), res.data);

      queryClient.invalidateQueries(QUERY_CACHE_KEY.event(id), { exact: true });

      if ('function' === typeof options?.onSuccess) {
        options.onSuccess(res, ...args);
      }
    },
  });
}

export default useCancelEventMutation;
