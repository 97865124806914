import { useQueryClient } from 'react-query';
import useRefetchNotifications from '@hooks/useRefetchNotifications';
import { useEffect } from 'react';
import useNotificationsQuery from '@hooks/queries/useNotificationsQuery';
import useNotificationsStore, { setCountSelector } from '@src/stores/notificationsStore';

function InAppNotificationProvider() {
  const queryClient = useQueryClient();

  const setCount = useNotificationsStore(setCountSelector);

  useNotificationsQuery({
    onSuccess: (data) => {
      if (!data) {
        return;
      }

      setCount(data.pages[0]?.count);
    },
  });

  const refetch = useRefetchNotifications();

  useEffect(() => {
    const interval = setInterval(() => refetch(), 10 * 1000);

    return () => clearInterval(interval);
  }, [queryClient, refetch]);

  return null;
}

export default InAppNotificationProvider;
