import { useQueryClient } from 'react-query';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import useApiMutation from './useApiMutation';

function useUpdateEventMutation(id, options) {
  const queryClient = useQueryClient();

  return useApiMutation('patch', `/v1/events/${id}`, {
    ...options,
    onSuccess: (res) => {
      // Provide fast feedback to user
      queryClient.setQueryData(QUERY_CACHE_KEY.event(id), res.data);

      queryClient.invalidateQueries(QUERY_CACHE_KEY.event(id), { exact: true });
    },
  });
}

export default useUpdateEventMutation;
