
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({
  "replaceAttrValues": {
    "#000": "{props.color}"
  }
});
        import {
  Button,
  Checkbox,
  Chip,
  Flex,
  Hidden,
  Ionicons,
  Pressable,
  ScrollView,
  TagButton,
  Text,
  View,
} from '@ui/atoms';
import { useState } from 'react';
import SURVEY_STATE from '@constants/SurveyState';
import useBreakpointName from '@hooks/useBreakpointName';
import useThemeColor from '@hooks/useThemeColor';
import { ContactsWidget, Feed, SurveySkeleton } from '@ui/organisms';

  const TagIcon = __ReactNativeSvgLoader(import('@src/assets/icons/tag.svg'));
  ;
import { FormattedMessage } from 'react-intl';
import { Panel } from '@ui/molecules';
import useTranslate from '@hooks/useTranslate';
import usePermissions from '@hooks/usePermissions';
import { useNavigation } from '@react-navigation/native';
import SCREENS from '@constants/Screens';
import useFeed from '@hooks/useFeed';
import FEED_ITEM_TYPE from '@constants/FeedItemType';

const STICKY_INDICES = {
  xl: [0],
  lg: [0],
};

const types = [FEED_ITEM_TYPE.survey];

function SurveysScreen() {
  const breakpoint = useBreakpointName();
  const primaryColor = useThemeColor('$primary.main');
  const { t } = useTranslate();
  const { navigate } = useNavigation();

  const [selectedStates, setSelectedStates] = useState([SURVEY_STATE.inProgress, SURVEY_STATE.expired]);

  const permissions = usePermissions();
  const { items, refetch, isFetching, hasNewItems } = useFeed(types, { surveyStates: selectedStates });

  const handleChange = (value) => {
    setSelectedStates((prev) => {
      if (prev.includes(value)) {
        return prev.filter((state) => state !== value);
      }

      return [...prev, value];
    });
  };

  const widget = (
    <Panel shadow>
      {permissions.createSurvey && (
        <View sx={{ mb: '$4' }}>
          <Button
            color="primary"
            title={t('SurveysScreen.widget.create')}
            onPress={() => navigate(SCREENS.createSurvey)}
          />
        </View>
      )}
      <Flex alignItems="center">
        <TagIcon height={20} width={20} color={primaryColor} />
        <Text variant="subtitle2" sx={{ color: '$primary.main', ml: '$2' }}>
          <FormattedMessage id="SurveysScreen.widget.title" />
        </Text>
      </Flex>
      <View sx={{ pl: 28, mt: '$3' }}>
        {Object.values(SURVEY_STATE).map((type) => (
          <Checkbox
            key={type}
            onChange={() => handleChange(type)}
            checked={selectedStates.includes(type)}
            sx={{ mb: '$2' }}
            disabled={selectedStates.includes(type) && 1 === selectedStates.length}
            label={
              <Text sx={{ color: '$primary.main' }}>
                <FormattedMessage id="constants.SurveyState" values={{ value: type }} />
              </Text>
            }
          />
        ))}
      </View>
    </Panel>
  );

  return (
    <ScrollView
      stickyHeaderIndices={STICKY_INDICES[breakpoint]}
      contentContainerSx={{
        p: '$4',
        flexDirection: 'row',
        alignItems: 'flex-start',
        maxWidth: 'container',
        width: '100%',
        mx: 'auto',
      }}
    >
      <Hidden except={['xl', 'lg']}>
        <View sx={{ width: 285, mr: '$4', top: '$4' }}>{widget}</View>
      </Hidden>
      <View
        sx={{
          flex: 1,
          width: '100%',
          maxWidth: [null, null, null, 'calc(100vw - 285px - 285px - 90px)'],
          mt: [null, null, '$4'],
        }}
      >
        <Hidden except={['xs', 'sm']}>
          <View sx={{ mb: '$4' }}>
            <View sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Text variant="subtitle2" sx={{ color: '$primary.main' }}>
                <FormattedMessage id="SurveysScreen.title" />
              </Text>
              {permissions.createSurvey && (
                <TagButton
                  icon={<Ionicons name="add-outline" size={16} color="white" sx={{ ml: 2 }} />}
                  onPress={() => navigate(SCREENS.createSurvey)}
                  label={t('common.verb.create')}
                  sx={{ ml: '$2' }}
                />
              )}
            </View>
            <View sx={{ mt: '$4', flexDirection: 'row' }}>
              {Object.values(SURVEY_STATE).map((type) => (
                <Chip
                  key={type}
                  label={t('constants.SurveyState', { value: type })}
                  onPress={() => handleChange(type)}
                  active={selectedStates.includes(type)}
                  sx={{ mr: '$2' }}
                />
              ))}
            </View>
          </View>
        </Hidden>
        {hasNewItems && !isFetching && (
          <Pressable
            onPress={refetch}
            sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mb: '$6' }}
          >
            <Ionicons name="reload" size={14} sx={{ mr: '$2', color: '$primary.main' }} />
            <Text variant="subtitle" sx={{ textAlign: 'center', color: '$primary.main' }}>
              <FormattedMessage id="SurveyScreen.newItems" />
            </Text>
          </Pressable>
        )}
        <Feed
          items={isFetching ? [] : items}
          empty={
            isFetching ? (
              <>
                <SurveySkeleton sx={{ mb: '$6' }} />
                <SurveySkeleton sx={{ mb: '$6' }} />
                <SurveySkeleton sx={{ mb: '$6' }} />
              </>
            ) : (
              <Text variant="subtitle" sx={{ textAlign: 'center', lineHeight: 25, p: '$5' }}>
                <FormattedMessage id="SurveysScreen.empty" />
              </Text>
            )
          }
        />
      </View>
      <Hidden only={['xs', 'sm']}>
        <View sx={{ width: 285, ml: '$4', mt: [null, null, '$4'] }}>
          <Hidden only={['xl', 'lg']}>
            <View sx={{ mb: '$4' }}>{widget}</View>
          </Hidden>
          <ContactsWidget />
        </View>
      </Hidden>
    </ScrollView>
  );
}

SurveysScreen.screenName = SCREENS.surveys;

export default SurveysScreen;

        