import { ProgressBar, Text, View } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import CustomPropTypes from '@utils/CustomPropTypes';

function ChoicesResult({ survey }) {
  return (
    <View>
      <Text sx={{ mb: '$2' }} variant="subtitle">
        <FormattedMessage id="ui.Survey.ChoicesResult.title" />
      </Text>
      <View>
        {survey.results?.map((choiceResult) => (
          <ProgressBar
            key={choiceResult.choiceId}
            label={survey.choices.find((choice) => choice.id === choiceResult.choiceId).name}
            value={choiceResult.average}
          />
        ))}
      </View>
    </View>
  );
}

ChoicesResult.propTypes = {
  survey: CustomPropTypes.survey.isRequired,
};

export default ChoicesResult;
