import { useQueryClient } from 'react-query';
import { USER_RELATED_CACHE_KEY } from '@constants/QueryCacheKey';
import useApiMutation from './useApiMutation';

function useUnblockUserMutation(userId, options) {
  const queryClient = useQueryClient();

  return useApiMutation('delete', `/v1/users/${userId}/unblock`, {
    ...options,
    onSuccess: () => {
      USER_RELATED_CACHE_KEY.forEach((key) => queryClient.invalidateQueries(key));
    },
  });
}

export default useUnblockUserMutation;
