import { Text, View } from '@ui/atoms';
import { Rating } from '@ui/molecules';
import { FormattedMessage } from 'react-intl';
import CustomPropTypes from '@utils/CustomPropTypes';

function RatingResult({ survey }) {
  const value = survey.rating ?? 0;

  return (
    <View>
      <Text sx={{ mb: '$2' }} variant="subtitle">
        <FormattedMessage id="ui.Survey.RatingResult.title" />
      </Text>
      <View sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Rating size={40} value={value} />
        <Text variant="subtitle2" sx={{ color: '$primary.main' }}>
          <FormattedMessage id="ui.Survey.RatingResult.rating" values={{ rating: value.toFixed(1) }} />
        </Text>
      </View>
    </View>
  );
}

RatingResult.propTypes = {
  survey: CustomPropTypes.survey.isRequired,
};

export default RatingResult;
