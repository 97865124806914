import { useCallback } from 'react';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import { useQueryClient } from 'react-query';

function useRefetchNotifications() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.setQueryData(QUERY_CACHE_KEY.notifications(), (prev) => {
      if (!prev) {
        return prev;
      }

      return {
        ...prev,
        pageParams: [undefined],
        pages: [prev.pages[0]],
      };
    });

    queryClient.refetchQueries(QUERY_CACHE_KEY.notifications());
  }, [queryClient]);
}

export default useRefetchNotifications;
