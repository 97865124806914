import { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryClient from '@utils/queryClient';
import { QueryClientProvider, focusManager, onlineManager } from 'react-query';
import NetInfo from '@react-native-community/netinfo';
import { AppState, Platform } from 'react-native';

onlineManager.setEventListener((setOnline) => {
  return NetInfo.addEventListener((state) => {
    setOnline(state.isConnected);
  });
});

function onAppStateChange(status) {
  if ('web' !== Platform.OS) {
    focusManager.setFocused('active' === status);
  }
}

function ReactQueryProvider({ children }) {
  useEffect(() => {
    AppState.addEventListener('change', onAppStateChange);

    return () => AppState.removeEventListener('change', onAppStateChange);
  }, []);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

ReactQueryProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default ReactQueryProvider;
