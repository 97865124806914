import { View } from '@ui/atoms';
import { useState } from 'react';
import uniq from 'lodash/uniq';
import VisibilitySensor from 'react-visibility-sensor';
import { FeedDefaultProps, FeedPropTypes } from '@ui/organisms/Feed/FeedPropTypes';
import FeedItem from '@ui/organisms/Feed/FeedItem';

function Feed({ items, header, empty, sx }) {
  const [visible, setVisible] = useState(items.slice(0, 4));

  const handleVisibilityChange = (isVisible, id) => {
    if (isVisible) {
      setVisible((prev) => uniq([...prev, id]));
    }
  };

  return (
    <View sx={sx}>
      {header}
      {0 < items.length
        ? items.map((item) => (
            <VisibilitySensor
              key={item.id}
              onChange={(isVisible) => handleVisibilityChange(isVisible, item.id)}
              partialVisibility
            >
              <FeedItem visible={visible.includes(item.id)} id={item.id} type={item.type} />
            </VisibilitySensor>
          ))
        : empty}
    </View>
  );
}

Feed.propTypes = FeedPropTypes;
Feed.defaultProps = FeedDefaultProps;

export default Feed;
