import { Button, SafeAreaView, Text, View } from '@ui/atoms';
import { Panel, ResidenceSelector } from '@ui/molecules';
import useAuthStore, { logout } from '@src/stores/authStore';
import useDeviceDimensions from '@hooks/useDeviceDimensions';
import useMeQuery from '@hooks/queries/useMeQuery';
import useTranslate from '@hooks/useTranslate';
import { FormattedMessage } from 'react-intl';
import useUserResidencesQuery from '@hooks/queries/useUserResidencesQuery';

function SelectResidenceScreen() {
  const { t } = useTranslate();
  const dimensions = useDeviceDimensions();

  const { setResidenceId } = useAuthStore();

  const { data: user } = useMeQuery();
  const { data: residences } = useUserResidencesQuery();

  const handlePress = (residence) => setResidenceId(residence.id);

  return (
    <SafeAreaView
      sx={{
        height: dimensions.height,
        width: dimensions.width,
        backgroundColor: '$grey.100',
        justifyContent: 'center',
        alignItems: 'center',
        px: '$4',
      }}
    >
      <View sx={{ maxWidth: 450 }}>
        <Text variant="title" sx={{ textAlign: 'center' }}>
          <FormattedMessage id="SelectResidenceScreen.hello" values={{ firstname: user.firstname }} />
        </Text>
        <Panel sx={{ my: '$6', p: 0, maxHeight: 400 }} shadow>
          <View sx={{ m: '$4' }}>
            <Text variant="subtitle">
              <FormattedMessage id="SelectResidenceScreen.select" />
            </Text>
          </View>
          <ResidenceSelector onPress={handlePress} residences={residences} />
        </Panel>
        <Button title={t('SelectResidenceScreen.logout')} color="secondary" onPress={logout} />
      </View>
    </SafeAreaView>
  );
}

export default SelectResidenceScreen;
