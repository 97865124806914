/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import View from './View';
import Text from './Text';
import Pressable from './Pressable';

const SIZES = {
  extraSmall: 22,
  small: 28,
  medium: 36,
};

const VARIANTS = {
  white: {
    borderWidth: 1,
    borderColor: '$common.white',
    bg: '$transparent.white.30',
  },
  whiteActive: {
    bg: '$common.white',
  },
  primary: {
    borderWidth: 1,
    borderColor: '$primary.main',
  },
  primaryActive: {
    bg: '$primary.main',
  },
  secondary: {
    borderWidth: 1,
    borderColor: '$secondary.main',
  },
  secondaryActive: {
    bg: '$secondary.main',
  },
  grey: {
    borderWidth: 1,
    borderColor: '$grey.300',
  },
  greyActive: {
    bg: '$grey.300',
  },
};

const TEXT_VARIANTS = {
  white: {
    color: '$common.white',
  },
  whiteActive: {
    color: '$text.primary',
  },
  primary: {
    color: '$primary.main',
  },
  primaryActive: {
    color: '$common.white',
  },
  secondary: {
    color: '$secondary.main',
  },
  secondaryActive: {
    color: '$common.white',
  },
  grey: {
    color: '$grey.800',
  },
  greyActive: {
    color: '$grey.800',
  },
};

function Chip({ size, variant, label, active, onPress, sx, ...props }) {
  const Component = 'function' === typeof onPress ? Pressable : View;

  return (
    <Component
      sx={{
        height: SIZES[size],
        borderRadius: SIZES[size] / 2,
        px: '$3',
        ...VARIANTS[variant],
        ...(active && VARIANTS[`${variant}Active`]),
        ...sx,
      }}
      onPress={onPress}
      {...props}
    >
      <Text
        sx={{
          lineHeight: SIZES[size] - 2,
          ...TEXT_VARIANTS[variant],
          ...(active && TEXT_VARIANTS[`${variant}Active`]),
          fontSize: 'extraSmall' === size ? 12 : undefined,
        }}
        numberOfLines={1}
      >
        {label}
      </Text>
    </Component>
  );
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['medium', 'small', 'extraSmall']),
  variant: PropTypes.oneOf(['white', 'primary', 'secondary', 'grey']),
  active: PropTypes.bool,
  onPress: PropTypes.func,
  sx: CustomPropTypes.style,
};

Chip.defaultProps = {
  size: 'medium',
  variant: 'primary',
  active: false,
  onPress: undefined,
  sx: undefined,
};

export default Chip;
