import useEventQuery from '@hooks/queries/useEventQuery';
import { Alert } from '@ui/molecules';
import useTranslate from '@hooks/useTranslate';
import EventCard from '@ui/organisms/EventCard';
import CustomPropTypes from '@utils/CustomPropTypes';
import PropTypes from 'prop-types';

function EventEmbed({ id, disabled, available, sx }) {
  const { t } = useTranslate();

  const { isError } = useEventQuery(id, {
    enabled: available,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  if (isError || !available) {
    return <Alert type="error" message={t('ui.PostEmbed.SurveyEmbed.error')} sx={sx} />;
  }

  return <EventCard id={id} disabled={disabled} visible sx={sx} />;
}

EventEmbed.propTypes = {
  id: CustomPropTypes.uuid,
  disabled: PropTypes.bool,
  sx: CustomPropTypes.style,
  available: PropTypes.bool.isRequired,
};

EventEmbed.defaultProps = {
  id: undefined,
  disabled: false,
  sx: undefined,
};

export default EventEmbed;
