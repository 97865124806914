import { Button, Input, Text, View } from '@ui/atoms';
import { FormControl } from '@ui/molecules';
import useTranslate from '@hooks/useTranslate';
import { FormattedMessage } from 'react-intl';
import useSendForgottenCodeMutation from '@hooks/mutations/useSendForgottenCodeMutation';
import { useState } from 'react';
import useToast from '@hooks/useToast';
import PropTypes from 'prop-types';

function EmailStep({ onSuccess }) {
  const { t } = useTranslate();
  const toast = useToast();

  const [value, setValue] = useState('');

  const { mutate, isLoading } = useSendForgottenCodeMutation({
    onSuccess: () => {
      onSuccess({ email: value });
    },
    onError: (err) => {
      if (422 === err.response?.status) {
        toast.error(t('errors.checkData'));
      } else if (429 === err.response?.status) {
        toast.error(t('errors.api.throttle'));
      } else {
        toast.error(t('errors.api.default'));
      }
    },
  });

  const handleSubmit = () => {
    mutate({ email: value });
  };

  return (
    <>
      <Text variant="subtitle2">
        <FormattedMessage id="ForgottenScreen.EmailStep.title" />
      </Text>
      <Text sx={{ mt: '$2' }}>
        <FormattedMessage id="ForgottenScreen.EmailStep.text" />
      </Text>

      <FormControl label={t('ForgottenScreen.EmailStep.email.label')} sx={{ mt: '$5' }} required>
        <Input
          value={value}
          onChangeText={setValue}
          type="email"
          autoComplete="email"
          keyboardType="email-address"
          textContentType="username"
        />
      </FormControl>
      <View sx={{ mt: '$5' }}>
        <Button
          disabled={0 === value.trim().length}
          loading={isLoading}
          onPress={handleSubmit}
          color="secondary"
          title={t('common.verb.continue')}
        />
      </View>
    </>
  );
}

EmailStep.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default EmailStep;
