import { Ionicons, View } from '@ui/atoms';
import { Dropdown } from '@ui/templates';
import useTranslate from '@hooks/useTranslate';
import useConfirmAlert from '@hooks/useConfirmAlert';
import useDeleteSurveyMutation from '@hooks/mutations/useDeleteSurveyMutation';
import CustomPropTypes from '@utils/CustomPropTypes';
import useToast from '@hooks/useToast';
import EMBED_TYPE from '@constants/EmbedType';
import SCREENS from '@constants/Screens';
import { useNavigation } from '@react-navigation/native';

function SurveyActions({ survey }) {
  const { t } = useTranslate();
  const { navigate } = useNavigation();
  const toast = useToast();
  const showConfirmAlert = useConfirmAlert();

  const { mutateAsync } = useDeleteSurveyMutation(survey.id, {
    onSuccess: () => toast.success(t('ui.Survey.SurveyActions.deleteSuccess')),
    onError: () => toast.error(t('errors.api.default')),
  });

  const handleShare = () => {
    navigate(SCREENS.addPost, { embed: { id: survey.id, type: EMBED_TYPE.survey } });
  };

  const handleDelete = () => {
    showConfirmAlert({
      title: t('ui.Survey.SurveyActions.confirmDelete.title'),
      description: t('ui.Survey.SurveyActions.confirmDelete.description'),
      destructive: true,
      confirmLabel: t('ui.Survey.SurveyActions.confirmDelete.confirm'),
      onConfirm: () => mutateAsync(),
    });
  };

  return (
    <Dropdown
      width={200}
      placement="bottom-end"
      options={[
        {
          icon: <Ionicons name="refresh-outline" size={18} />,
          label: t('ui.Survey.SurveyActions.share'),
          visible: survey.permissions.share,
          onPress: handleShare,
        },
        {
          icon: <Ionicons name="close" size={18} sx={{ color: '$red.main' }} />,
          label: t('ui.Survey.SurveyActions.delete'),
          visible: survey.permissions.delete,
          onPress: handleDelete,
          destructive: true,
        },
      ]}
    >
      <View
        sx={{
          height: 40,
          width: 40,
          right: -10,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Ionicons name="ellipsis-vertical-sharp" size={24} sx={{ color: '$text.primary' }} />
      </View>
    </Dropdown>
  );
}

SurveyActions.propTypes = {
  survey: CustomPropTypes.survey.isRequired,
};

export default SurveyActions;
