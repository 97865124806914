import useDialogStore from '@src/stores/dialogStore';
import { useEffect } from 'react';
import { Platform } from 'react-native';

function DialogProvider() {
  const { Component, close, props } = useDialogStore();

  useEffect(() => {
    const listener = (e) => {
      if ('Escape' === e.key && !!useDialogStore.getState().Component) {
        close();
      }
    };

    if ('web' === Platform.OS) {
      window.addEventListener('keydown', listener);
    }

    return () => {
      if ('web' === Platform.OS) {
        window.removeEventListener('keydown', listener);
      }
    };
  }, [close]);

  if (!Component) {
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component onClose={close} {...props} />;
}

export default DialogProvider;
