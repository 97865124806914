import { useQueryClient } from 'react-query';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import useApiMutation from './useApiMutation';

function useCreateSurveyMutation(options) {
  const queryClient = useQueryClient();

  return useApiMutation('post', `/v1/surveys`, {
    ...options,
    onSuccess: (res, ...rest) => {
      queryClient.invalidateQueries(QUERY_CACHE_KEY.feed());

      if ('function' === typeof options?.onSuccess) {
        options.onSuccess(res, ...rest);
      }
    },
  });
}

export default useCreateSurveyMutation;
