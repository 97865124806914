import axios from '@utils/axios';
import Pusher from '@utils/Pusher';
import config from '@src/config';

function pusherClientFactory() {
  return new Pusher(config.pusher.key, {
    key: config.pusher.key,
    wsHost: config.pusher.host,
    wsPort: config.pusher.port,
    enabledTransports: config.pusher.enabledTransports,
    forceTLS: config.pusher.forceTLS,
    encrypted: true,
    enableStats: false,
    authorizer: (channel) => {
      return {
        authorize: (socketId, callback) => {
          axios
            .post('/v1/broadcasting/auth', {
              socket_id: socketId,
              channel_name: channel.name,
            })
            .then((response) => callback(false, response.data))
            .catch((error) => callback(true, error));
        },
      };
    },
  });
}

export default pusherClientFactory;
