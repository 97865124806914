import CustomPropTypes from '@utils/CustomPropTypes';
import PropTypes from 'prop-types';
import EMBED_TYPE from '@constants/EmbedType';
import { Alert } from '@ui/molecules';
import useTranslate from '@hooks/useTranslate';
import AnotherPostEmbed from '@ui/organisms/PostEmbed/AnotherPostEmbed';
import SurveyEmbed from './SurveyEmbed';
import EventEmbed from './EventEmbed';

const COMPONENT = {
  [EMBED_TYPE.survey]: SurveyEmbed,
  [EMBED_TYPE.event]: EventEmbed,
  [EMBED_TYPE.post]: AnotherPostEmbed,
};

function PostEmbed({ embed, disabled, sx }) {
  const { t } = useTranslate();

  const Component = COMPONENT[embed.type];

  if (!Component) {
    return <Alert type="error" message={t('ui.PostEmbed.unknown')} sx={sx} />;
  }

  return <Component id={embed.id} available={embed.available} disabled={disabled} sx={sx} />;
}

PostEmbed.propTypes = {
  embed: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(EMBED_TYPE)),
    id: CustomPropTypes.uuid,
    available: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  sx: CustomPropTypes.style,
};

PostEmbed.defaultProps = {
  disabled: false,
  sx: undefined,
};

export default PostEmbed;
