import SCREENS from '@constants/Screens';
import { getStateFromPath } from '@react-navigation/native';
import Qs from 'qs';
import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import authStore from '@src/stores/authStore';
import AUTH_STATE from '@constants/AuthState';

const ROUTES = {
  config: {
    screens: {
      [SCREENS.login]: '/login',
      [SCREENS.register]: '/signup',
      [SCREENS.forgotten]: '/forgotten',
      [SCREENS.joinResidence]: '/join',
      ...Platform.select({
        web: {
          [SCREENS.home]: {
            screens: {
              [SCREENS.feed]: '/feed',
              [SCREENS.neighbors]: '/neighbors',
              [SCREENS.documents]: '/documents',
              [SCREENS.events]: '/events',
              [SCREENS.event]: '/events/:id',
              [SCREENS.resources]: '/resources',
              [SCREENS.surveys]: '/surveys',
              [SCREENS.editProfile]: '/profile',
              [SCREENS.notifications]: '/notifications',
            },
          },
        },
        default: {
          [SCREENS.home]: {
            screens: {
              [SCREENS.feed]: '/feed',
              [SCREENS.events]: '/events',
              [SCREENS.surveys]: '/surveys',
              [SCREENS.notifications]: '/notifications',
            },
          },
          [SCREENS.event]: '/events/:id',
          [SCREENS.resources]: '/resources',
          [SCREENS.editProfile]: '/profile',
          [SCREENS.documents]: '/documents',
          [SCREENS.neighbors]: '/neighbors',
        },
      }),
    },
  },
  getStateFromPath(fullpath, config) {
    const [path, queryString] = fullpath.split('?');

    const query = Qs.parse(queryString ?? '', { ignoreQueryPrefix: true });

    const { residenceId, modal, params } = query;
    delete query.residenceId;

    if ('web' !== Platform.OS) {
      delete query.modal;
      delete query.params;
    }

    // Automatically switch residence if the link specify it
    const authState = authStore.getState();
    if (authState.state === AUTH_STATE.loggedIn && residenceId && authState.residenceId !== residenceId) {
      authState.setResidenceId(
        residenceId,
        path +
          Qs.stringify(
            {
              ...query,
              modal,
              params,
            },
            { arrayFormat: 'brackets', encodeValuesOnly: true, addQueryPrefix: true }
          )
      );

      return getStateFromPath('/feed', config);
    }

    const state = getStateFromPath(
      path + Qs.stringify(query, { arrayFormat: 'brackets', encodeValuesOnly: true, addQueryPrefix: true }),
      config
    );

    // If not modal open, just use the state determined automatically by React Navigation
    // On web, the ModalProvider will handle the first page load, next actions will be handled by CustomStackRouter
    if (!modal || 'web' === Platform.OS) {
      return state;
    }

    // On mobile, add modal to routes state
    return {
      ...state,
      routes: [
        ...state.routes,
        {
          name: modal,
          params,
        },
      ],
    };
  },
  prefixes: [
    Linking.createURL('/'),
    'http://localhost:19006/',
    'https://app.kunagi.fr',
    'https://app.homemeethome.fr',
    'https://app.staging.kunagi.fr',
  ],
};

export default ROUTES;
