import create from 'zustand';

const emptyProps = Object.freeze({});

const useDialogStore = create((set) => ({
  Component: null,
  props: emptyProps,
  open: (Component, props = emptyProps) => set({ Component, props }),
  close: () => set({ Component: false, props: emptyProps }),
}));

export default useDialogStore;
