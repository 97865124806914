/* eslint-disable no-return-assign */
import { Flex, Hidden, Image, Ionicons, Pressable, Text, UserAvatar, View } from '@ui/atoms';
import { Platform } from 'react-native';
import useThemeColor from '@hooks/useThemeColor';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import useEventQuery from '@hooks/queries/useEventQuery';
import isSameDay from 'date-fns/isSameDay';
import SCREENS from '@constants/Screens';
import ReplyEvent from '@ui/organisms/ReplyEvent';
import useTranslate from '@hooks/useTranslate';
import EventScreenSkeleton from '@ui/organisms/EventScreenSkeleton';
import COMMENTABLE_MODEL from '@constants/CommentableModel';
import AddComment from '@ui/organisms/AddComment';
import Comments from '@ui/organisms/Comments';
import Screen from '@ui/templates/Screen';
import { Alert, Panel } from '@ui/molecules';
import AddToCalendar from '@ui/atoms/AddToCalendar';
import useOpenUserProfile from '@hooks/useOpenUserProfile';
import { Dropdown } from '@ui/templates';
import useEventActions from '@hooks/useEventActions';
import useMeQuery from '@hooks/queries/useMeQuery';

function EventScreen({ route }) {
  const { t, formatDate, formatTime } = useTranslate();
  const greyColor = useThemeColor('$grey.700');
  const openUserProfile = useOpenUserProfile();

  const { id } = route.params;

  const { data: me } = useMeQuery();

  const { data: event, isLoading } = useEventQuery(id, {
    refetchInterval: 30 * 1000,
  });

  const actions = useEventActions(event);

  return (
    <Screen
      title={t('EventScreen.title')}
      presentation={EventScreen.presentation}
      scrollable
      withoutHeader={'web' === Platform.OS}
      sx={{ p: 0, pt: 0, pb: 0, px: [0, 0, '$4'] }}
      actions={actions}
      bgColor={['$common.white', null, '$grey.100']}
    >
      {isLoading ? (
        <EventScreenSkeleton />
      ) : (
        <Panel
          sx={{
            maxWidth: [null, null, 800],
            width: '100%',
            marginVertical: [0, 0, '$6'],
            borderRadius: [0, 0, '$2'],
            borderWidth: [0, 0, 1],
            marginLeft: 'auto',
            marginRight: 'auto',
            p: 0,
            pb: ['$8', null, 0],
          }}
        >
          <Image
            source={{ uri: event?.thumbnail?.small }}
            sx={{
              width: '100%',
              height: [240, null, null, 360],
              borderTopLeftRadius: [0, null, '$1'],
              borderTopRightRadius: [0, null, '$1'],
              resizeMode: 'cover',
              bg: '$grey.300',
            }}
          />
          <View sx={{ p: '$4' }}>
            <Flex sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Text variant="subtitle2" sx={{ fontSize: 18 }}>
                {event.title}
              </Text>
              <View sx={{ flexDirection: 'row' }}>
                {me.id !== event.user.id && (
                  <Hidden only={['xs', 'sm']}>
                    <View sx={{ ml: '$2' }}>
                      <ReplyEvent
                        id={event.id}
                        disabled={!event.permissions.reply}
                        currentReply={event.currentUserReply}
                      />
                    </View>
                  </Hidden>
                )}
                {'web' === Platform.OS && (
                  <Dropdown width={250} placement="bottom-end" options={actions}>
                    <View
                      sx={{
                        height: 40,
                        width: 40,
                        alignItems: 'center',
                        justifyContent: 'center',
                        ml: '$2',
                      }}
                    >
                      <Ionicons name="ellipsis-vertical-sharp" size={24} sx={{ color: '$text.primary' }} />
                    </View>
                  </Dropdown>
                )}
              </View>
            </Flex>
            {event.canceledAt && <Alert type="error" message={t('EventScreen.canceled')} sx={{ mt: '$2' }} />}
            <Flex sx={{ mt: '$4' }}>
              <Ionicons name="calendar-sharp" size={25} color={greyColor} />
              <View sx={{ mt: '$1', ml: '$4' }}>
                {isSameDay(new Date(event.startAt), new Date(event.endAt)) ? (
                  <>
                    <Text>
                      {formatDate(event.startAt, {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      })}
                    </Text>
                    <Text sx={{ fontSize: 11 }}>
                      {formatTime(event.startAt)} - {formatTime(event.endAt)}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text>
                      <FormattedMessage id="EventScreen.from" values={{ startAt: new Date(event.startAt) }} />
                    </Text>
                    <Text>
                      <FormattedMessage id="EventScreen.to" values={{ endAt: new Date(event.endAt) }} />
                    </Text>
                  </>
                )}
                <AddToCalendar
                  title={event.title}
                  startAt={new Date(event.startAt)}
                  endAt={new Date(event.endAt)}
                  timezone={event.timezone}
                  description={event.description}
                  location={event.location}
                />
              </View>
            </Flex>
            <Flex sx={{ mt: '$4' }}>
              <Ionicons name="location-sharp" size={25} color={greyColor} />
              <Text sx={{ flex: 1, flexWrap: 'wrap', mt: '$1', ml: '$4' }}>{event.location}</Text>
            </Flex>
            {me.id !== event.user.id && (
              <Hidden except={['xs', 'sm']}>
                <View sx={{ mt: '$4' }}>
                  <ReplyEvent id={event.id} currentReply={event.currentUserReply} />
                </View>
              </Hidden>
            )}
            <View sx={{ mt: '$6' }}>
              <Text variant="subtitle2">
                <FormattedMessage id="EventScreen.description" />
              </Text>
              <Text sx={{ mt: '$2' }}>{event.description}</Text>
            </View>
            <View sx={{ mt: '$6' }}>
              <Text variant="subtitle2">
                <FormattedMessage id="EventScreen.organizer" />
              </Text>
              <Pressable
                onPress={() => openUserProfile(event.user.id)}
                sx={{ flexDirection: 'row', mt: '$3', alignItems: 'center' }}
              >
                <UserAvatar user={event.user} size="medium" />
                <Text variant="subtitle" sx={{ ml: '$2' }}>
                  {event.user.fullname}
                </Text>
              </Pressable>
            </View>
            <View sx={{ mt: '$6' }}>
              <Text variant="subtitle2">
                <FormattedMessage id="EventScreen.participants" values={{ total: event.participants.length }} />
              </Text>
              {event.participants?.map((participant) => (
                <Pressable
                  key={participant.id}
                  onPress={() => openUserProfile(participant.id)}
                  sx={{ flexDirection: 'row', mt: '$3', alignItems: 'center' }}
                >
                  <UserAvatar user={participant} size="medium" />
                  <Text variant="subtitle" sx={{ ml: '$2' }}>
                    {participant.fullname}
                  </Text>
                </Pressable>
              ))}
            </View>
            <View sx={{ mt: '$6' }}>
              <Text variant="subtitle2">
                <FormattedMessage id="EventScreen.discussions" />
              </Text>
              <AddComment
                model={{
                  type: COMMENTABLE_MODEL.event,
                  id: event.id,
                }}
                sx={{ mt: '$4' }}
              />
              <Comments
                model={{
                  type: COMMENTABLE_MODEL.event,
                  id: event.id,
                }}
                sx={{ mt: '$7', mr: '$7' }}
              />
            </View>
          </View>
        </Panel>
      )}
    </Screen>
  );
}

EventScreen.screenName = SCREENS.event;
EventScreen.presentation = 'card';

EventScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EventScreen;
