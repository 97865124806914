import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import { useQueryClient } from 'react-query';
import useApiMutation from './useApiMutation';

function useUpdatePostMutation(id, options) {
  const queryClient = useQueryClient();

  return useApiMutation('patch', `/v1/posts/${id}`, {
    ...options,
    onSuccess: (res, ...args) => {
      // Provide fast feedback to user
      queryClient.setQueryData(QUERY_CACHE_KEY.post(id), res.data);

      queryClient.invalidateQueries(QUERY_CACHE_KEY.post(id), { exact: true });

      if ('function' === typeof options?.onSuccess) {
        options.onSuccess(res, ...args);
      }
    },
  });
}

export default useUpdatePostMutation;
