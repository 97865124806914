import { useActionSheet } from '@expo/react-native-action-sheet';
import useTranslate from '@hooks/useTranslate';
import { useCallback } from 'react';

function useOptionsActionSheet() {
  const { t } = useTranslate();
  const { showActionSheetWithOptions } = useActionSheet();

  return useCallback(
    (options) => {
      const visibleOptions = options.filter((option) => false !== option.visible);

      if (0 === visibleOptions.length) {
        return;
      }

      showActionSheetWithOptions(
        {
          options: [...visibleOptions.map((i) => i.label), t('common.verb.close')],
          cancelButtonIndex: visibleOptions.length,
          destructiveButtonIndex: visibleOptions.findIndex((option) => option.destructive),
        },
        (index) => visibleOptions[index]?.onPress()
      );
    },
    [showActionSheetWithOptions, t]
  );
}

export default useOptionsActionSheet;
