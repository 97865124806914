/* eslint-disable react/jsx-props-no-spreading */
import { Fragment } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { View, ScrollView, KeyboardShift, KeyboardDismiss, ScaledImage } from '@ui/atoms';
import logo from '@src/assets/logo.png';
import PropTypes from 'prop-types';
import { Platform, useWindowDimensions } from 'react-native';
import useTranslate from '@hooks/useTranslate';
import CustomPropTypes from '@utils/CustomPropTypes';

function GuestLayout({ children, sx = undefined, scrollable = false, withoutLogo = false, ...props }) {
  const { t } = useTranslate();
  const insets = useSafeAreaInsets();
  const dimensions = useWindowDimensions();

  const paddingStyle = Platform.select({
    default: {
      pt: insets.top,
      pb: insets.bottom ? insets.bottom : '$4',
    },
    web: {
      pb: '$4',
    },
  });

  const logoWidth = Math.min(dimensions.width - 40, 300);

  const RootComponent = scrollable ? Fragment : KeyboardDismiss;
  const ViewComponent = scrollable ? ScrollView : View;

  return (
    <RootComponent>
      <KeyboardShift sx={{ flex: 1 }}>
        <ViewComponent
          sx={{
            ...paddingStyle,
            display: 'flex',
            flexDirection: 'column',
            px: '$4',
            flex: 1,
            ...sx,
          }}
          {...props}
        >
          <View
            sx={{
              width: '100%',
              maxWidth: 496,
              alignSelf: 'center',
              flex: 1,
              pt: ['$4', '$8'],
            }}
          >
            {!withoutLogo && (
              <ScaledImage
                source={logo}
                width={logoWidth}
                sx={{ mb: '$8', alignSelf: 'center' }}
                alt={t('common.name')}
              />
            )}
            {children}
          </View>
        </ViewComponent>
      </KeyboardShift>
    </RootComponent>
  );
}

GuestLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sx: CustomPropTypes.style,
  scrollable: PropTypes.bool,
  withoutLogo: PropTypes.bool,
};

export default GuestLayout;
