import { useQuery } from 'react-query';
import QUERY_CACHE_KEY from '@src/constants/QueryCacheKey';

function usePostCategoriesQuery(options) {
  return useQuery(QUERY_CACHE_KEY.postCategories(), {
    staleTime: 60 * 60 * 1000,
    ...options,
  });
}

export default usePostCategoriesQuery;
