import { useLinkTo } from '@react-navigation/native';
import { useCallback } from 'react';
import * as Linking from 'expo-linking';
import useMarkNotificationAsOpenMutation from '@hooks/mutations/useMarkNotificationAsOpenMutation';

function useOpenNotification() {
  const linkTo = useLinkTo();

  const { mutate: markAsOpen } = useMarkNotificationAsOpenMutation();

  return useCallback(
    (id, link) => {
      const queryString = link.split('?')[1] ?? '';
      const { path } = Linking.parse(link);
      linkTo(`/${path}?${queryString}`);
      markAsOpen({ params: { id } });
    },
    [markAsOpen, linkTo]
  );
}

export default useOpenNotification;
