import { useCallback, useMemo } from 'react';
import { Ionicons } from '@ui/atoms';
import useTranslate from '@hooks/useTranslate';
import useConfirmAlert from '@hooks/useConfirmAlert';
import { useNavigation } from '@react-navigation/native';
import SCREENS from '@constants/Screens';
import EMBED_TYPE from '@constants/EmbedType';
import useMeQuery from '@hooks/queries/useMeQuery';
import useBlockUserMutation from '@hooks/mutations/useBlockUserMutation';
import useCancelEventMutation from '@hooks/mutations/useCancelEventMutation';

function useEventActions(event) {
  const { t } = useTranslate();
  const { navigate } = useNavigation();
  const showConfirmAlert = useConfirmAlert();

  const { data: me } = useMeQuery();

  const { mutateAsync: cancelEvent } = useCancelEventMutation(event?.id);

  const { mutateAsync: blockUser } = useBlockUserMutation(event?.user?.id);

  const handleEdit = useCallback(() => navigate(SCREENS.editEvent, { id: event?.id }), [navigate, event]);

  const handleShare = useCallback(
    () => navigate(SCREENS.addPost, { embed: { id: event?.id, type: EMBED_TYPE.event } }),
    [navigate, event]
  );

  const handleBlockUser = useCallback(() => {
    showConfirmAlert({
      title: t('hooks.useEventActions.confirmBlockUser.title', { firstname: event?.user?.firstname }),
      description: t('hooks.useEventActions.confirmBlockUser.description'),
      destructive: true,
      confirmLabel: t('hooks.useEventActions.confirmBlockUser.confirm'),
      onConfirm: () => blockUser(),
    });
  }, [showConfirmAlert, t, event?.user?.firstname, blockUser]);

  const handleCancel = useCallback(() => {
    showConfirmAlert({
      title: t('hooks.useEventActions.confirmCancel.title'),
      description: t('hooks.useEventActions.confirmCancel.description'),
      destructive: true,
      confirmLabel: t('hooks.useEventActions.confirmCancel.confirm'),
      onConfirm: () => cancelEvent(),
    });
  }, [cancelEvent, showConfirmAlert, t]);

  return useMemo(
    () => [
      {
        icon: <Ionicons name="refresh-outline" size={18} />,
        label: t('hooks.useEventActions.share'),
        visible: event?.permissions.share,
        onPress: handleShare,
      },
      {
        icon: <Ionicons name="pencil" size={18} />,
        label: t('hooks.useEventActions.edit'),
        visible: event?.permissions.edit,
        onPress: handleEdit,
      },
      {
        icon: <Ionicons name="eye-off" size={18} />,
        label: t('hooks.useEventActions.block', { firstname: event?.user.firstname }),
        onPress: handleBlockUser,
        visible: me.id !== event?.user.id,
      },
      {
        icon: <Ionicons name="close" size={18} sx={{ color: '$red.main' }} />,
        label: t('hooks.useEventActions.cancel'),
        visible: event?.permissions.cancel,
        onPress: handleCancel,
        destructive: true,
      },
    ],
    [event, handleBlockUser, handleCancel, handleEdit, handleShare, me.id, t]
  );
}

export default useEventActions;
