import ScreenRegistry from '@src/navigation/ScreenRegistry';
import SurveyScreen from '@src/screens/SurveyScreen';
import SurveyAnswersScreen from '@src/screens/SurveyAnswersScreen';
import AddPostScreen from '@src/screens/AddPostScreen';
import CreateSurveyScreen from '@src/screens/CreateSurveyScreen';
import EditPostScreen from '@src/screens/EditPostScreen';
import PostLikesScreen from '@src/screens/PostLikesScreen';
import PostScreen from '@src/screens/PostScreen';
import NotificationsSettingsScreen from '@src/screens/NotificationsSettingsScreen';
import EditProfileScreen from '@src/screens/EditProfileScreen';
import DocumentsScreen from '@src/screens/DocumentsScreen';
import NeighborsScreen from '@src/screens/NeighborsScreen';
import FeedScreen from '@src/screens/FeedScreen';
import EventsScreen from '@src/screens/EventsScreen';
import NotificationsScreen from '@src/screens/NotificationsScreen';
import SurveysScreen from '@src/screens/SurveysScreen';
import LoginScreen from '@src/screens/LoginScreen';
import RegisterScreen from '@src/screens/RegisterScreen';
import ForgottenScreen from '@src/screens/ForgottenScreen';
import CreateEventScreen from '@src/screens/CreateEventScreen';
import EventScreen from '@src/screens/EventScreen';
import EditEventScreen from '@src/screens/EditEventScreen';
import WebScreen from '@src/screens/WebScreen';
import InviteUserScreen from '@src/screens/InviteUserScreen';
import JoinResidenceScreen from '@src/screens/JoinResidenceScreen';
import EditPasswordScreen from '@src/screens/EditPasswordScreen';
import BlockedUsersScreen from '@src/screens/BlockedUsersScreen';
import NeighborScreen from '@src/screens/NeighborScreen';
import ResourcesScreen from '@src/screens/ResourcesScreen';

ScreenRegistry.register([
  LoginScreen,
  RegisterScreen,
  ForgottenScreen,
  DocumentsScreen,
  NeighborsScreen,
  NeighborScreen,
  SurveyScreen,
  SurveyAnswersScreen,
  AddPostScreen,
  EditPostScreen,
  CreateSurveyScreen,
  PostLikesScreen,
  PostScreen,
  NotificationsSettingsScreen,
  EditProfileScreen,
  FeedScreen,
  SurveysScreen,
  EventsScreen,
  NotificationsScreen,
  CreateEventScreen,
  EventScreen,
  EditEventScreen,
  WebScreen,
  InviteUserScreen,
  JoinResidenceScreen,
  EditPasswordScreen,
  BlockedUsersScreen,
  ResourcesScreen,
]);
