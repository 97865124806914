import useMeQuery from '@hooks/queries/useMeQuery';

const select = (user) => user?.residences ?? [];

function useUserResidencesQuery(options) {
  return useMeQuery({
    select,
    ...options,
  });
}

export default useUserResidencesQuery;
