/* eslint-disable global-require */
import Constants from 'expo-constants';

function getEnvVars() {
  const env = Constants.expoConfig?.extra?.CONFIG_ENV;

  if ('staging' === env) {
    return require('./config.staging').default;
  }

  if ('production' === env) {
    return require('./config.production').default;
  }

  return require('./config.dev').default;
}

export default getEnvVars();
