/* eslint-disable react/no-unstable-nested-components,react/jsx-props-no-spreading */
import SCREENS from '@constants/Screens';
import { Platform } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { TabBar, Header } from '@ui/organisms';
import useThemeColor from '@hooks/useThemeColor';
import '@src/screens/register';
import determineScreenPresentation from '@src/navigation/determineScreenPresentation';
import ScreenRegistry from '@src/navigation/ScreenRegistry';
import createCustomStackNavigator from '@src/navigation/createCustomStackNavigator';
import { useCallback, useEffect } from 'react';
import { useLinkTo } from '@react-navigation/native';
import authStore from '@src/stores/authStore';

const screens = ScreenRegistry.all().filter((Screen) => 'guest' !== Screen.visibility);

const Tab = createBottomTabNavigator();
const WebStack = createCustomStackNavigator();

function AppTabs() {
  const backgroundColor = useThemeColor('$grey.100');

  const AppNavigator = 'web' === Platform.OS ? WebStack : Tab;

  return (
    <AppNavigator.Navigator
      initialRouteName={SCREENS.feed}
      tabBar={(props) => <TabBar {...props} />}
      screenOptions={{
        headerShown: false,
        animation: 'none',
        gestureEnabled: false,
        contentStyle: { backgroundColor },
        tabBarItemStyle: {
          flexDirection: 'column',
          justifyContent: 'center',
        },
      }}
    >
      {screens
        .filter((Screen) => {
          const presentation = determineScreenPresentation(Screen.presentation);

          if ('web' === Platform.OS) {
            return 'modal' !== presentation && 'public' !== Screen.visibility;
          }

          return 'tab' === presentation;
        })
        .map((Screen) => {
          const presentation = determineScreenPresentation(Screen.presentation);
          return (
            <AppNavigator.Screen
              key={Screen.screenName}
              name={Screen.screenName}
              component={Screen}
              options={{
                presentation: 'tab' === presentation ? undefined : presentation,
                ...Screen.options,
              }}
            />
          );
        })}
    </AppNavigator.Navigator>
  );
}

const MainStackNavigator = createCustomStackNavigator();
function MainStack() {
  const backgroundColor = useThemeColor('$grey.100');
  const linkTo = useLinkTo();

  useEffect(() => {
    const { redirectTo, clearRedirect } = authStore.getState();
    if (redirectTo) {
      linkTo(redirectTo);
      clearRedirect();
    }
  }, [linkTo]);

  const header = useCallback(() => <Header />, []);

  return (
    <MainStackNavigator.Navigator
      initialRouteName={SCREENS.home}
      screenOptions={{
        header,
        contentStyle: {
          backgroundColor,
        },
      }}
    >
      <MainStackNavigator.Screen name={SCREENS.home} component={AppTabs} />
      <MainStackNavigator.Group
        screenOptions={{
          animation: 'android' === Platform.OS ? 'slide_from_right' : undefined,
          headerShown: false,
        }}
      >
        {screens
          .filter((Screen) => {
            if ('web' === Platform.OS) {
              return 'public' === Screen.visibility;
            }

            return 'tab' !== determineScreenPresentation(Screen.presentation);
          })
          .map((Screen) => {
            const presentation = determineScreenPresentation(Screen.presentation);
            return (
              <MainStackNavigator.Screen
                key={Screen.screenName}
                name={Screen.screenName}
                component={Screen}
                options={{
                  animation: Platform.select({
                    android: 'modal' === presentation ? 'fade_from_bottom' : 'slide_from_right',
                  }),
                  presentation,
                  ...Screen.options,
                }}
              />
            );
          })}
      </MainStackNavigator.Group>
    </MainStackNavigator.Navigator>
  );
}

export default MainStack;
