function deleteEmptyEntries(obj, nullAsEmpty = false) {
  const output = { ...obj };

  Object.keys(output).forEach((key) => {
    if (output[key] === undefined || 0 === output[key]?.length || (nullAsEmpty && null === output[key])) {
      delete output[key];
    }
  });

  return output;
}

export default deleteEmptyEntries;
