import { Hidden, Pressable, ScrollView, Text, View, Ionicons } from '@ui/atoms';
import { ContactsWidget, Feed, PostCardSkeleton } from '@ui/organisms';
import useBreakpointName from '@hooks/useBreakpointName';
import useFeed from '@hooks/useFeed';
import { FormattedMessage } from 'react-intl';
import POST_TYPE from '@constants/PostType';
import FEED_ITEM_TYPE from '@constants/FeedItemType';
import SCREENS from '@constants/Screens';

const STICKY_INDICES = {
  xl: [0],
  lg: [0],
};

const types = [FEED_ITEM_TYPE.post];
const postTypes = [POST_TYPE.document];

function DocumentsScreen() {
  const breakpoint = useBreakpointName();

  const { items, refetch, isFetching, hasNewItems } = useFeed(types, { postTypes });

  return (
    <ScrollView
      stickyHeaderIndices={STICKY_INDICES[breakpoint]}
      contentContainerSx={{
        p: '$4',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        maxWidth: 'container',
        width: '100%',
        mx: 'auto',
      }}
    >
      <Hidden except={['xl', 'lg']}>
        <View sx={{ width: 285, mr: '$4', top: '$4' }} />
      </Hidden>
      <View
        sx={{
          flex: 1,
          width: '100%',
          maxWidth: [null, null, null, 'calc(100vw - 285px - 285px - 90px)'],
          mt: [null, null, '$4'],
        }}
      >
        {hasNewItems && !isFetching && (
          <Pressable
            onPress={refetch}
            sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mb: '$6' }}
          >
            <Ionicons name="reload" size={14} sx={{ mr: '$2', color: '$primary.main' }} />
            <Text variant="subtitle" sx={{ textAlign: 'center', color: '$primary.main' }}>
              <FormattedMessage id="DocumentsScreen.newItems" />
            </Text>
          </Pressable>
        )}
        <Feed
          items={isFetching ? [] : items}
          empty={
            isFetching ? (
              <>
                <PostCardSkeleton variant="document" sx={{ mb: '$6' }} />
                <PostCardSkeleton variant="document" sx={{ mb: '$6' }} />
                <PostCardSkeleton variant="document" sx={{ mb: '$6' }} />
              </>
            ) : (
              <Text variant="subtitle" sx={{ textAlign: 'center', lineHeight: 25, p: '$5' }}>
                <FormattedMessage id="DocumentsScreen.empty" />
              </Text>
            )
          }
        />
      </View>
      <Hidden only={['xs', 'sm']}>
        <View sx={{ width: 285, ml: '$4', mt: [null, null, '$4'] }}>
          <ContactsWidget />
        </View>
      </Hidden>
    </ScrollView>
  );
}

DocumentsScreen.screenName = SCREENS.documents;

export default DocumentsScreen;
