import Text from '@ui/atoms/Text';
import View from '@ui/atoms/View';
import { TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';

function TagButton({ icon, label, onPress, sx }) {
  return (
    <TouchableOpacity onPress={onPress}>
      <View
        sx={{
          pl: '$1',
          pr: '$3',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: 'rgba(26, 115, 116, .15)',
          height: 36,
          borderRadius: 25,
          borderWidth: 2,
          borderColor: '$primary.main',
          ...sx,
        }}
      >
        <View
          sx={{
            backgroundColor: '$primary.main',
            borderRadius: 13,
            width: 26,
            height: 26,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {icon}
        </View>
        <Text variant="body" sx={{ ml: '$2', color: '$primary.main' }}>
          {label}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

TagButton.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  sx: CustomPropTypes.style,
};

TagButton.defaultProps = {
  sx: undefined,
};

export default TagButton;
