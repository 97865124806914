import { Chip, Flex, Pressable, Skeleton, Text, UserAvatar, View } from '@ui/atoms';
import CustomPropTypes from '@utils/CustomPropTypes';
import PropTypes from 'prop-types';
import useSurveyQuery from '@hooks/queries/useSurveyQuery';
import { TouchableOpacity } from 'react-native';
import useOpenUserProfile from '@hooks/useOpenUserProfile';
import SCREENS from '@constants/Screens';
import { useNavigation } from '@react-navigation/native';
import { Alert } from '@ui/molecules';
import useTranslate from '@hooks/useTranslate';

function SurveyEmbed({ id, available, disabled, sx }) {
  const { t } = useTranslate();
  const { navigate } = useNavigation();
  const openUserProfile = useOpenUserProfile();

  const {
    data: survey,
    isLoading,
    isError,
  } = useSurveyQuery(
    id,
    { view: 'embed' },
    {
      enabled: available,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  const Container = disabled ? View : TouchableOpacity;

  if (isError || !available) {
    return <Alert type="error" message={t('ui.PostEmbed.SurveyEmbed.error')} sx={sx} />;
  }

  if (isLoading) {
    return (
      <View
        sx={{
          borderWidth: 1,
          borderColor: '$grey.300',
          borderRadius: '$1',
          height: 'auto',
          p: '$4',
          ...sx,
        }}
      >
        <Skeleton width={70} height={26} radius={13} sx={{ mr: '$2', mb: '$2' }} />
        <View sx={{ width: '100%', mb: '$2' }}>
          <Skeleton width={200} height={18} />
          <Skeleton width="100%" height={40} sx={{ mt: '$2', mb: '$5' }} />
        </View>
        <Flex alignItems="center">
          <Skeleton height={16} width={16} radius={8} sx={{ mr: '$2' }} />
          <Skeleton width={140} height={8} />
        </Flex>
      </View>
    );
  }

  const handleProfileView = () => openUserProfile(survey.user.id);
  const handleOpen = () => navigate(SCREENS.survey, { id });

  return (
    <Container onPress={disabled ? undefined : handleOpen}>
      <View
        sx={{
          borderWidth: 1,
          borderColor: '$grey.300',
          borderRadius: '$1',
          height: 'auto',
          p: '$4',
          ...sx,
        }}
      >
        <View sx={{ alignItems: 'flex-start' }}>
          <Chip label="Sondage" size="extraSmall" variant="grey" sx={{ mb: '$2' }} active />
          <Text variant="subtitle">{survey.title}</Text>
          <Text sx={{ mt: '$2', mb: '$5' }} numberOfLines={3}>
            {survey.content}
          </Text>
          <Pressable onPress={handleProfileView} sx={{ alignItems: 'center', flexDirection: 'row' }}>
            <UserAvatar sx={{ mr: '$2' }} size="small" user={survey.user} />
            <Text variant="subtitle" sx={{ color: '$primary.main' }}>
              {survey.user.fullname}
            </Text>
          </Pressable>
        </View>
      </View>
    </Container>
  );
}

SurveyEmbed.propTypes = {
  id: CustomPropTypes.uuid,
  available: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  sx: CustomPropTypes.style,
};

SurveyEmbed.defaultProps = {
  id: undefined,
  disabled: false,
  sx: undefined,
};

export default SurveyEmbed;
