import { Flex, Ionicons, Pressable, Text, View } from '@ui/atoms';
import { ResidenceSelector } from '@ui/molecules';
import { Dialog } from '@ui/templates';
import useAuthStore from '@src/stores/authStore';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import useUserResidencesQuery from '@hooks/queries/useUserResidencesQuery';

function SelectResidenceDialog({ open, onClose }) {
  const { residenceId, setResidenceId } = useAuthStore();

  const { data: residences } = useUserResidencesQuery();

  const handlePress = (residence) => {
    if (residenceId === residence.id) {
      onClose();
      return;
    }

    setResidenceId(residence.id);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} size="small">
      <View sx={{ p: '$4' }}>
        <Flex justifyContent="space-between">
          <Text variant="subtitle2" sx={{ color: '$primary.main' }}>
            <FormattedMessage id="ui.SelectResidenceDialog.title" />
          </Text>
          <Pressable
            sx={{
              width: 24,
              height: 24,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={onClose}
          >
            <Ionicons name="close" size={24} />
          </Pressable>
        </Flex>
        <View sx={{ my: '$3', mx: '$-5', mb: '$-5', p: 0, maxHeight: 400 }}>
          <ResidenceSelector onPress={handlePress} residences={residences} />
        </View>
      </View>
    </Dialog>
  );
}

SelectResidenceDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

SelectResidenceDialog.defaultProps = {
  open: false,
};

export default SelectResidenceDialog;
