/* eslint-disable no-fallthrough, default-case */
import { nanoid } from 'nanoid/non-secure';
import { StackRouter } from '@react-navigation/native';
import modalStore from '@src/stores/modalStore';
import { Platform } from 'react-native';
import ScreenRegistry from '@src/navigation/ScreenRegistry';
import determineScreenPresentation from '@src/navigation/determineScreenPresentation';
import Qs from 'qs';

export default function CustomStackRouter(options) {
  const base = StackRouter(options);

  return {
    ...base,
    getStateForAction(state, action, opts) {
      if ('web' === Platform.OS) {
        const modalState = modalStore.getState();

        switch (action.type) {
          /*
           * When go back or pop screens, if a non react-navigation modal is open, close it and cancel action
           */
          case 'GO_BACK':
            if (0 < modalState.modals.length) {
              modalState.close();
              return state;
            }
          case 'POP':
            for (let i = 0; i < action.payload.count; i += 1) {
              if (0 < modalState.modals.length) {
                modalState.close();
              } else {
                return base.getStateForAction(state, action, opts);
              }
            }

            return state;
          case 'POP_TO_TOP':
            modalState.closeAll();
            break;

          /*
           * Add some navigate behaviors
           */
          case 'NAVIGATE':
          case 'PUSH':
          case 'REPLACE':
            // Open a non react-navigation modal on web
            if (
              ScreenRegistry.has(action.payload.name) &&
              'modal' === determineScreenPresentation(ScreenRegistry.get(action.payload.name).presentation)
            ) {
              modalStore.getState().open(action.payload.name, action.payload.params);
              return state;
            }

            // When navigate to a screen, make sure there is no open modal
            if (0 !== modalStore.getState().modals.length) {
              modalState.closeAll();
            }

            // If payload containing path, check if we need to open a modal
            // payload with path, is from useLinkTo()
            if (action.payload.params?.path) {
              const query = Qs.parse(action.payload.params.path.split('?')?.[1] ?? '', { ignoreQueryPrefix: true });
              if (
                ScreenRegistry.has(query.modal) &&
                'modal' === determineScreenPresentation(ScreenRegistry.get(query.modal).presentation)
              ) {
                modalStore.getState().open(query.modal, query.params);
              }
            }

            /*
             * Make sure that all screen have a unique ID on web to avoid history bug
             * See https://github.com/react-navigation/react-navigation/issues/10263
             */
            if (!opts.routeGetIdList[action.payload.name]) {
              // eslint-disable-next-line no-param-reassign
              opts = {
                ...opts,
                routeGetIdList: {
                  ...opts.routeGetIdList,
                  [action.payload.name]: () => nanoid(),
                },
              };
            }

            break;
        }
      }

      return base.getStateForAction(state, action, opts);
    },
  };
}
