import { Text, View } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import CustomPropTypes from '@utils/CustomPropTypes';

function RatingResult({ survey }) {
  return (
    <View>
      <Text sx={{ mb: '$2' }} variant="subtitle">
        <FormattedMessage id="ui.Survey.ChoicesWait.title" />
      </Text>
      <Text sx={{ color: '$primary.main' }}>
        <FormattedMessage id="ui.Survey.ChoicesWait.content" values={{ date: new Date(survey.expiresAt) }} />
      </Text>
    </View>
  );
}

RatingResult.propTypes = {
  survey: CustomPropTypes.survey.isRequired,
};

export default RatingResult;
