import { useCallback } from 'react';
import Toast from 'react-native-toast-message';

function useToast() {
  const show = useCallback((title, subtitle, options) => {
    Toast.show({ text1: title, text2: subtitle, ...options });
  }, []);

  const success = useCallback((title, subtitle, options) => {
    Toast.show({ text1: title, text2: subtitle, type: 'success', ...options });
  }, []);

  const error = useCallback((title, subtitle, options) => {
    Toast.show({ text1: title, text2: subtitle, type: 'error', ...options });
  }, []);

  const info = useCallback((title, subtitle, options) => {
    Toast.show({ text1: title, text2: subtitle, type: 'info', ...options });
  }, []);

  return { show, success, error, info };
}

export default useToast;
