import { useInfiniteQuery } from 'react-query';
import QUERY_CACHE_KEY from '@src/constants/QueryCacheKey';

function useNotificationsQuery(options) {
  return useInfiniteQuery(QUERY_CACHE_KEY.notifications(), {
    ...options,
    getNextPageParam: (lastPage) => lastPage.meta.nextCursor,
  });
}

export default useNotificationsQuery;
