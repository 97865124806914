
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({
  "replaceAttrValues": {
    "#000": "{props.color}"
  }
});
        import { Hidden, Pressable, ScrollView, Text, View, Ionicons, Flex, Checkbox } from '@ui/atoms';
import { StartNewPost, ContactsWidget, Feed, PostCardSkeleton } from '@ui/organisms';
import useBreakpointName from '@hooks/useBreakpointName';
import useFeed from '@hooks/useFeed';
import { FormattedMessage } from 'react-intl';
import usePermissions from '@hooks/usePermissions';

  const TagIcon = __ReactNativeSvgLoader(import('@src/assets/icons/tag.svg'));
  ;
import { getVisiblePostTypes } from '@constants/PostType';
import { Panel } from '@ui/molecules';
import useThemeColor from '@hooks/useThemeColor';
import { useEffect, useState } from 'react';
import FEED_ITEM_TYPE from '@constants/FeedItemType';
import FeedMobileFilters from '@ui/organisms/FeedMobileFilters';
import SCREENS from '@constants/Screens';
import useCurrentResidenceQuery from '@hooks/queries/useCurrentResidenceQuery';

const STICKY_INDICES = {
  xl: [0],
  lg: [0],
};

function FeedScreen() {
  const breakpoint = useBreakpointName();
  const primaryColor = useThemeColor('$primary.main');

  const { data: residence } = useCurrentResidenceQuery();

  const [selectedTypes, setSelectedTypes] = useState(Object.values(FEED_ITEM_TYPE));
  const [selectedPostTypes, setSelectedPostTypes] = useState(getVisiblePostTypes(residence.type));

  const permissions = usePermissions();

  const { items, refetch, isFetching, hasNewItems } = useFeed(selectedTypes, {
    postTypes: selectedPostTypes,
  });

  useEffect(() => {
    if (0 === selectedPostTypes.length) {
      setSelectedTypes((prev) => prev.filter((type) => type !== FEED_ITEM_TYPE.post));
    } else {
      setSelectedTypes((prev) => {
        if (!prev.some((type) => type === FEED_ITEM_TYPE.post)) {
          return [...prev, FEED_ITEM_TYPE.post];
        }

        return prev;
      });
    }
  }, [selectedPostTypes]);

  const handleChange = (value, filterType) => {
    const setter = 'post_type' === filterType ? setSelectedPostTypes : setSelectedTypes;

    setter((prev) => {
      const alreadySelected = prev.includes(value);
      if (alreadySelected) {
        return prev.filter((state) => state !== value);
      }

      return [...prev, value];
    });
  };

  const widget = (
    <Panel shadow>
      <Flex alignItems="center">
        <TagIcon height={20} width={20} color={primaryColor} />
        <Text variant="subtitle2" sx={{ color: '$primary.main', ml: '$2' }}>
          <FormattedMessage id="FeedScreen.widgetTitle" />
        </Text>
      </Flex>
      <View sx={{ pl: 28, mt: '$3' }}>
        {getVisiblePostTypes(residence.type).map((type) => (
          <Checkbox
            key={type}
            onChange={() => handleChange(type, 'post_type')}
            checked={selectedPostTypes.includes(type)}
            sx={{ mb: '$2' }}
            label={
              <Text sx={{ color: '$primary.main' }}>
                <FormattedMessage id="constants.PostType" values={{ value: type }} />
              </Text>
            }
          />
        ))}
        {Object.values(FEED_ITEM_TYPE)
          .filter((type) => type !== FEED_ITEM_TYPE.post)
          .map((type) => (
            <Checkbox
              key={type}
              onChange={() => handleChange(type, 'item_type')}
              checked={selectedTypes.includes(type)}
              sx={{ mb: '$2' }}
              label={
                <Text sx={{ color: '$primary.main' }}>
                  <FormattedMessage id="constants.FeedItemType" values={{ value: type }} />
                </Text>
              }
            />
          ))}
      </View>
    </Panel>
  );

  return (
    <ScrollView
      stickyHeaderIndices={STICKY_INDICES[breakpoint]}
      contentContainerSx={{
        p: '$4',
        flexDirection: 'row',
        alignItems: 'flex-start',
        maxWidth: 'container',
        width: '100%',
        mx: 'auto',
      }}
    >
      <Hidden except={['xl', 'lg']}>
        <View sx={{ width: 285, mr: '$4', top: '$4' }}>{widget}</View>
      </Hidden>
      <View
        sx={{
          flex: 1,
          width: '100%',
          maxWidth: [null, null, null, 'calc(100vw - 285px - 285px - 90px)'],
          mt: [null, null, '$4'],
        }}
      >
        {permissions.publish && (
          <View sx={{ mb: '$6' }}>
            <StartNewPost />
          </View>
        )}
        <Hidden except={['xs', 'sm']}>
          <FeedMobileFilters
            selectedPostTypes={selectedPostTypes}
            setSelectedTypes={setSelectedTypes}
            setSelectedPostTypes={setSelectedPostTypes}
            selectedTypes={selectedTypes}
            sx={{ mb: '$6' }}
          />
        </Hidden>
        {hasNewItems && !isFetching && (
          <Pressable
            onPress={refetch}
            sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mb: '$6' }}
          >
            <Ionicons name="reload" size={14} sx={{ mr: '$2', color: '$primary.main' }} />
            <Text variant="subtitle" sx={{ textAlign: 'center', color: '$primary.main' }}>
              <FormattedMessage id="FeedScreen.newItems" />
            </Text>
          </Pressable>
        )}
        <Feed
          items={isFetching ? [] : items}
          empty={
            isFetching ? (
              <>
                <PostCardSkeleton sx={{ mb: '$6' }} />
                <PostCardSkeleton sx={{ mb: '$6' }} />
                <PostCardSkeleton sx={{ mb: '$6' }} />
              </>
            ) : (
              <Text variant="subtitle" sx={{ textAlign: 'center', lineHeight: 25, p: '$5' }}>
                <FormattedMessage id="FeedScreen.empty" />
              </Text>
            )
          }
        />
      </View>
      <Hidden only={['xs', 'sm']}>
        <View sx={{ width: 285, ml: '$4', mt: [null, null, '$4'] }}>
          <Hidden only={['xl', 'lg']}>
            <View sx={{ mb: '$4' }}>{widget}</View>
          </Hidden>
          <ContactsWidget />
        </View>
      </Hidden>
    </ScrollView>
  );
}

FeedScreen.screenName = SCREENS.feed;

export default FeedScreen;

        