import {
  Poppins_100Thin as PoppinsThin,
  Poppins_100Thin_Italic as PoppinsThinItalic,
  Poppins_200ExtraLight as PoppinsExtraLight,
  Poppins_200ExtraLight_Italic as PoppinsExtraLightItalic,
  Poppins_300Light as PoppinsLight,
  Poppins_300Light_Italic as PoppinsLightItalic,
  Poppins_400Regular as PoppinsRegular,
  Poppins_400Regular_Italic as PoppinsRegularItalic,
  Poppins_500Medium as PoppinsMedium,
  Poppins_500Medium_Italic as PoppinsMediumItalic,
  Poppins_600SemiBold as PoppinsSemiBold,
  Poppins_600SemiBold_Italic as PoppinsSemiBoldItalic,
  Poppins_700Bold as PoppinsBold,
  Poppins_700Bold_Italic as PoppinsBoldItalic,
  Poppins_800ExtraBold as PoppinsExtraBold,
  Poppins_800ExtraBold_Italic as PoppinsExtraBoldItalic,
  Poppins_900Black as PoppinsBlack,
  Poppins_900Black_Italic as PoppinsBlackItalic,
} from '@expo-google-fonts/poppins';

const FONTS = {
  PoppinsThin,
  PoppinsThinItalic,
  PoppinsExtraLight,
  PoppinsExtraLightItalic,
  PoppinsLight,
  PoppinsLightItalic,
  PoppinsRegular,
  PoppinsRegularItalic,
  PoppinsMedium,
  PoppinsMediumItalic,
  PoppinsSemiBold,
  PoppinsSemiBoldItalic,
  PoppinsBold,
  PoppinsBoldItalic,
  PoppinsExtraBold,
  PoppinsExtraBoldItalic,
  PoppinsBlack,
  PoppinsBlackItalic,
};

export default FONTS;
