
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({
  "replaceAttrValues": {
    "#000": "{props.color}"
  }
});
        import { useMemo } from 'react';
import { Flex, Hidden, Pressable, ScaledImage, Text, View } from '@ui/atoms';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

  const LogoIcon = __ReactNativeSvgLoader(import('@src/assets/logo-icon.svg'));
  ;

  const HomeIcon = __ReactNativeSvgLoader(import('@src/assets/icons/home.svg'));
  ;

  const DocumentsIcon = __ReactNativeSvgLoader(import('@src/assets/icons/documents.svg'));
  ;

  const LinkIcon = __ReactNativeSvgLoader(import('@src/assets/icons/link.svg'));
  ;

  const MenuIcon = __ReactNativeSvgLoader(import('@src/assets/icons/menu.svg'));
  ;

  const SurveyIcon = __ReactNativeSvgLoader(import('@src/assets/icons/survey.svg'));
  ;

  const CalendarIcon = __ReactNativeSvgLoader(import('@src/assets/icons/calendar.svg'));
  ;
import useDrawerStore from '@src/stores/drawerStore';
import { Platform } from 'react-native';
import useBreakpointName from '@hooks/useBreakpointName';
import { FormattedMessage } from 'react-intl';
import useThemeColor from '@hooks/useThemeColor';
import { useNavigation, useRoute } from '@react-navigation/native';
import SCREENS from '@constants/Screens';
import useCurrentResidenceQuery from '@hooks/queries/useCurrentResidenceQuery';
import NotificationsPopover from '@ui/organisms/NotificationsPopover';
import { Dropdown } from '@ui/templates';
import SelectResidenceDialog from '@ui/organisms/SelectResidenceDialog';
import { logout } from '@src/stores/authStore';
import useDialog from '@hooks/useDialog';
import useUserResidencesQuery from '@hooks/queries/useUserResidencesQuery';
import useTranslate from '@hooks/useTranslate';
import useWebView from '@hooks/useWebView';

const ITEMS = [
  {
    id: 'home',
    Icon: HomeIcon,
    label: 'ui.Header.items.home',
    screen: SCREENS.feed,
  },
  {
    id: 'documents',
    Icon: DocumentsIcon,
    label: 'ui.Header.items.documents',
    screen: SCREENS.documents,
  },
  {
    id: 'surveys',
    Icon: SurveyIcon,
    label: 'ui.Header.items.surveys',
    screen: SCREENS.surveys,
  },
  {
    id: 'events',
    Icon: CalendarIcon,
    label: 'ui.Header.items.events',
    screen: SCREENS.events,
  },
  {
    id: 'resources',
    Icon: LinkIcon,
    label: 'ui.Header.items.resources',
    screen: SCREENS.resources,
  },
];

function Header() {
  const { t } = useTranslate();
  const { open: openDrawer } = useDrawerStore();
  const primaryColor = useThemeColor('$primary.main');
  const insets = useSafeAreaInsets();
  const breakpoint = useBreakpointName();
  const { navigate } = useNavigation();
  const route = useRoute();
  const { open: openDialog } = useDialog();
  const openWebView = useWebView();

  const currentRoute = route?.state?.routes?.[route?.state?.index ?? 0]?.name ?? route.params?.screen;

  const { data: residences } = useUserResidencesQuery();
  const { data: residence } = useCurrentResidenceQuery();

  const dropdownOptions = useMemo(() => {
    return [
      { label: t('ui.Header.dropdown.profile'), onPress: () => navigate(SCREENS.editProfile) },
      { label: t('ui.Header.dropdown.inviteUser'), onPress: () => navigate(SCREENS.inviteUser) },
      1 < residences.length && {
        label: t('ui.Header.dropdown.switch'),
        onPress: () => openDialog(SelectResidenceDialog),
      },
      {
        label: t('ui.Header.feedback.name'),
        onPress: () => openWebView(t('ui.Header.feedback.name'), t('ui.Header.feedback.url')),
      },
      { label: t('ui.Header.dropdown.logout'), onPress: () => logout() },
    ].filter((v) => v);
  }, [t, residences.length, navigate, openDialog]);

  return (
    <View
      sx={{
        flexDirection: 'row',
        height: 56 + insets.top,
        bg: '$grey.100',
        borderBottomColor: '$grey.300',
        borderBottomWidth: 1,
      }}
    >
      <Flex
        sx={{
          height: 56 + insets.top,
          px: ['$4', '$6'],
          pt: insets.top,
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: 'container',
          mx: 'auto',
          flex: 1,
        }}
      >
        <Flex alignItems="center" sx={{ flex: 1 }}>
          <Pressable
            onPress={() => navigate(SCREENS.feed)}
            sx={{
              pr: '$7',
              flexDirection: 'row',
              alignItems: 'center',
              ...Platform.select({
                web: {
                  flex: [1, null, null, 'none'],
                  width: ['auto', null, '292px'],
                },
              }),
            }}
          >
            {residence.logo ? (
              <ScaledImage source={{ uri: residence.logo.small }} height={35} />
            ) : (
              <LogoIcon height={35} width={35} />
            )}
            <Text sx={{ ml: '$4', fontWeight: 'medium', flex: 1, fontSize: [14, null, 16] }} numberOfLines={1}>
              {residence.name}
            </Text>
          </Pressable>
          <Hidden except={['lg', 'xl']}>
            {ITEMS.map(({ Icon, ...item }) => (
              <Pressable
                key={item.id}
                onPress={() => navigate(item.screen)}
                sx={{
                  alignItems: 'center',
                  cursor: 'pointer',
                  py: '$1',
                  px: '$2',
                  borderRadius: '$1',
                  mr: '$3',
                  ...(currentRoute === item.screen && {
                    bg: '$grey.300',
                  }),
                }}
              >
                <Icon color={primaryColor} height={24} width={24} />
                <Text sx={{ fontSize: 12, color: '$primary.main' }}>
                  <FormattedMessage id={item.label} />
                </Text>
              </Pressable>
            ))}
          </Hidden>
        </Flex>

        <Flex>
          {'web' === Platform.OS ? (
            <>
              <NotificationsPopover />
              {['lg', 'xl'].includes(breakpoint) ? (
                <Dropdown width={250} placement="bottom-end" offset={20} options={dropdownOptions} rtl>
                  <View sx={{ alignItems: 'center', ml: '$4' }}>
                    <MenuIcon color={primaryColor} size={24} />
                  </View>
                </Dropdown>
              ) : (
                <Pressable onPress={() => openDrawer()} sx={{ alignItems: 'center', ml: '$4' }}>
                  <MenuIcon color={primaryColor} size={24} />
                </Pressable>
              )}
            </>
          ) : (
            <Pressable onPress={openDrawer} sx={{ alignItems: 'center' }}>
              <MenuIcon color={primaryColor} size={24} />
            </Pressable>
          )}
        </Flex>
      </Flex>
    </View>
  );
}

export default Header;

        