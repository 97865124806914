import { Ionicons, Pressable, Text, View } from '@ui/atoms';
import { Popover } from '@ui/templates';
import useThemeColor from '@hooks/useThemeColor';
import { useCallback, useState } from 'react';
import useNotificationsStore, { countSelector } from '@src/stores/notificationsStore';
import NotificationsList from '@ui/organisms/NotificationsList';
import useBreakpointName from '@hooks/useBreakpointName';
import { useNavigation } from '@react-navigation/native';
import SCREENS from '@constants/Screens';

function NotificationsPopover() {
  const { navigate } = useNavigation();
  const breakpoint = useBreakpointName();
  const primaryColor = useThemeColor('$primary.main');
  const count = useNotificationsStore(countSelector);

  const [open, setOpen] = useState(false);

  const handleOpenNotification = useCallback(() => setOpen(false), [setOpen]);

  const icon = (
    <View sx={{ position: 'relative' }}>
      <Ionicons name="notifications-outline" size={24} color={primaryColor} />
      {0 < count ? (
        <View
          sx={{
            position: 'absolute',
            bg: '$red.notifications',
            height: 16,
            width: 16,
            top: -4,
            right: -4,
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text variant="small" sx={{ textAlign: 'center', fontWeight: 'medium', color: '$common.white' }}>
            {count}
          </Text>
        </View>
      ) : null}
    </View>
  );

  if (['xs', 'sm'].includes(breakpoint)) {
    return <Pressable onPress={() => navigate(SCREENS.notifications)}>{icon}</Pressable>;
  }

  return (
    <Popover
      open={open}
      setOpen={setOpen}
      placement="bottom-end"
      trigger={icon}
      unmount={false}
      offset={20}
      width={400}
      sx={{
        bg: '$common.white',
        borderWidth: 1,
        borderColor: '$grey.300',
        boxShadow: 'md',
        borderRadius: '$1',
        height: 300,
        overflow: 'scroll',
      }}
    >
      <NotificationsList visible={open} onOpenNotification={handleOpenNotification} width={400} />
    </Popover>
  );
}

export default NotificationsPopover;
