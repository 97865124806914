import { Platform } from 'react-native';
import { ActivityIndicator, View } from '@ui/atoms';
import useDeviceDimensions from '@hooks/useDeviceDimensions';
import useThemeColor from '@hooks/useThemeColor';
import useAuthStore, { authStateSelector } from '@src/stores/authStore';
import SCREENS from '@constants/Screens';
import AUTH_STATE from '@constants/AuthState';
import { useNavigation } from '@react-navigation/native';
import PropTypes from 'prop-types';
import useJoinResidenceMutation from '@hooks/mutations/useJoinResidenceMutation';
import useAsyncEffect from '@hooks/useAsyncEffect';
import useToast from '@hooks/useToast';
import useTranslate from '@hooks/useTranslate';

function JoinResidenceScreen({ route: { params } }) {
  const { t } = useTranslate();
  const { navigate } = useNavigation();
  const toast = useToast();
  const dimensions = useDeviceDimensions();
  const primaryColor = useThemeColor('$primary.main');

  const authState = useAuthStore(authStateSelector);
  const setResidenceId = useAuthStore((state) => state.setResidenceId);

  const { mutateAsync } = useJoinResidenceMutation(params.code);

  useAsyncEffect(async () => {
    if (authState === AUTH_STATE.loggedIn) {
      try {
        const res = await mutateAsync();
        setResidenceId(res.data.residenceId);
      } catch (err) {
        toast.error(t('JoinResidenceScreen.error'));
      } finally {
        navigate(SCREENS.home);
      }
    }
  }, [authState]);

  if (authState === AUTH_STATE.loggedOut) {
    navigate(SCREENS.register, params);
    return null;
  }

  return (
    <View
      sx={{
        height: Platform.select({ default: dimensions.height, web: '100vh' }),
        width: Platform.select({ default: dimensions.width, web: '100vw' }),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ActivityIndicator size="large" color={primaryColor} />
    </View>
  );
}

JoinResidenceScreen.visibility = 'public';
JoinResidenceScreen.screenName = SCREENS.joinResidence;

JoinResidenceScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
};

export default JoinResidenceScreen;
