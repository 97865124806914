import useAuthStore, { residenceIdSelector } from '@src/stores/authStore';
import AUTH_STATE from '@constants/AuthState';
import { useEffect } from 'react';
import useSocketStore from '@src/stores/socketStore';
import Echo from 'laravel-echo';
import pusherClientFactory from '@utils/pusherClientFactory';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import { useQueryClient } from 'react-query';
import useMeQuery from '@hooks/queries/useMeQuery';
import useRefetchNotifications from '@hooks/useRefetchNotifications';

function SocketProvider() {
  const authState = useAuthStore((state) => state.state);
  const queryClient = useQueryClient();
  const refetchNotifications = useRefetchNotifications();

  const { setSocketId, setEcho, clear, echo } = useSocketStore();
  const residenceId = useAuthStore(residenceIdSelector);
  const { data: userId } = useMeQuery({
    select: (data) => data.id,
  });

  useEffect(() => {
    if (authState !== AUTH_STATE.loggedIn) {
      return;
    }

    const instance = new Echo({
      broadcaster: 'pusher',
      client: pusherClientFactory(),
    });

    instance.connector.pusher.connection.bind('connected', () => setSocketId(instance.socketId()));

    instance.connector.pusher.connection.bind('disconnected', () => clear());

    setEcho(instance);

    // eslint-disable-next-line consistent-return
    return () => {
      instance.disconnect();
      setEcho(null);
    };
  }, [authState, clear, setEcho, setSocketId]);

  useEffect(() => {
    if (!residenceId || !echo) {
      return;
    }

    const channelName = `Residence.${residenceId}`;
    const channel = echo.private(channelName);

    const invalidateComments = (type, id) => queryClient.invalidateQueries(QUERY_CACHE_KEY.comments(type, id));
    const invalidatePost = (id) => queryClient.invalidateQueries(QUERY_CACHE_KEY.post(id));
    const invalidateFeed = () => {
      queryClient.invalidateQueries(QUERY_CACHE_KEY.feed(), {
        // Disable refetch of active requests to avoid scroll flickering,
        // there is a button "new posts available" in this case.
        refetchActive: false,
      });
    };

    channel.listen('.CommentAdded', (data) => invalidateComments(data.model_type, data.model_id));
    channel.listen('.CommentDeleted', (data) => invalidateComments(data.model_type, data.model_id));

    channel.listen('.PostPublished', () => invalidateFeed());
    channel.listen('.PostLiked', (data) => invalidatePost(data.post_id));
    channel.listen('.PostUnliked', (data) => invalidatePost(data.post_id));
    channel.listen('.PostUpdated', (data) => invalidatePost(data.post_id));

    channel.listen('.SurveyPublished', () => invalidateFeed());

    channel.listen('.EventPublished', () => invalidateFeed());
    channel.listen('.EventUpdated', () => invalidateFeed());
    channel.listen('.EventCanceled', (data) => {
      invalidateFeed();
      queryClient.invalidateQueries(QUERY_CACHE_KEY.event(data.event_id));
    });

    // eslint-disable-next-line consistent-return
    return () => echo.leave(channelName);
  }, [echo, queryClient, residenceId]);

  useEffect(() => {
    if (!userId || !echo) {
      return;
    }

    const channel = `User.${userId}`;

    echo.private(channel).listen('.Notification', () => refetchNotifications());

    // eslint-disable-next-line consistent-return
    return () => echo.leave(channel);
  }, [echo, queryClient, refetchNotifications, userId]);

  return null;
}

export default SocketProvider;
