import useModalStore from '@src/stores/modalStore';
import { Dialog } from '@ui/templates';
import { useEffect, useRef } from 'react';
import Qs from 'qs';
import ScreenRegistry from '@src/navigation/ScreenRegistry';
import deleteEmptyEntries from '@utils/deleteEmptyEntries';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'fast-deep-equal';

function ModalProvider() {
  const { modals, close, open } = useModalStore();
  const initUpdateRef = useRef(false);
  const initOpenRef = useRef(false);

  useEffect(() => {
    const listener = (e) => {
      if ('Escape' === e.key && 0 < useModalStore.getState().modals.length) {
        close();
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [close]);

  /*
   * Automatically open modal based on URL
   */
  useEffect(() => {
    const listener = () => {
      // Fake timeout to get search value on next tick
      setTimeout(() => {
        const query = Qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (query.modal && ScreenRegistry.has(query.modal)) {
          open(query.modal, query.params);
        }
      }, 0);
    };

    window.addEventListener('popstate', listener);

    // Run effect once when mount the component
    if (!initOpenRef.current) {
      listener();
      initOpenRef.current = true;
    }

    return () => window.removeEventListener('popstate', listener);
  }, [open]);

  /*
   * Update URL state when modal is open/closed
   */
  useEffect(() => {
    if (!initUpdateRef.current) {
      initUpdateRef.current = true;
      return;
    }

    const currentModal = modals[modals.length - 1];

    const query = Qs.parse(window.location.search, { ignoreQueryPrefix: true });

    // Init empty params to have default state that will be same as currentModal
    if (!query.params) {
      query.params = {};
    }

    const originalQuery = cloneDeep(query);

    if (!currentModal) {
      if (!query.modal) {
        return;
      }

      query.modal = undefined;
      query.params = undefined;
    } else if (currentModal.name) {
      query.modal = currentModal.name;
      query.params = currentModal.props;
    }

    if (isEqual(query, originalQuery)) {
      return;
    }

    const url = new URL(window.location);
    url.search = Qs.stringify(deleteEmptyEntries(query), {
      arrayFormat: 'brackets',
      encodeValuesOnly: true,
    });

    window.history.pushState({}, '', url);
  }, [modals]);

  if (0 === modals.length) {
    return null;
  }

  return (
    <>
      {modals.map(({ id, name, props }, index) => {
        const Component = ScreenRegistry.get(name);

        return (
          <Dialog key={id} onClose={close} size={Component.modalSize} open={index === modals.length - 1}>
            <Component route={{ params: props }} />
          </Dialog>
        );
      })}
    </>
  );
}

export default ModalProvider;
