import { Platform } from 'react-native';
import useAuthStore from '@src/stores/authStore';
import useAsyncEffect from '@hooks/useAsyncEffect';
import AUTH_STATE from '@constants/AuthState';
import { ActivityIndicator, View } from '@ui/atoms';
import useDeviceDimensions from '@hooks/useDeviceDimensions';
import useMeQuery from '@hooks/queries/useMeQuery';
import { useQueryClient } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import useProfileQuery from '@hooks/queries/useProfileQuery';
import useThemeColor from '@hooks/useThemeColor';
import PropTypes from 'prop-types';
import SelectResidenceScreen from '@src/screens/SelectResidenceScreen';
import NoAccessScreen from '@src/screens/NoAccessScreen';

function AuthProvider({ children }) {
  const dimensions = useDeviceDimensions();
  const queryClient = useQueryClient();
  const primaryColor = useThemeColor('$primary.main');

  const { init, state, setState, residenceId, setResidenceId } = useAuthStore();

  // Used to avoid missing data bug when switch residence
  const [switching, setSwitching] = useState(false);

  const { isLoading: isUserLoading, data: user } = useMeQuery({
    enabled: state === AUTH_STATE.loggedIn,
    onSuccess: (data) => {
      // Automatically switch to first residence if the user has only one
      if (state === AUTH_STATE.loggedIn && !residenceId && 1 === data.residences.length) {
        setResidenceId(data.residences[0].id);
      }
    },
    onSettled: () => {
      setSwitching(false);
    },
  });

  const { isLoading: isProfileLoading } = useProfileQuery({
    enabled: !!residenceId && state === AUTH_STATE.loggedIn,
  });

  /*
   * This effect initializes the authentication state at startup
   */
  useAsyncEffect(async () => {
    if (AUTH_STATE.uninitialized !== state) {
      return;
    }

    const { token } = await init();

    if (token) {
      setState(AUTH_STATE.loggedIn);
      return;
    }

    setState(AUTH_STATE.loggedOut);
  }, [state]);

  /*
   * Clear the React Query store when the user logs out
   */
  useEffect(() => {
    if (state === AUTH_STATE.loggedOut) {
      setResidenceId(null);
      queryClient.clear();
    }
  }, [setResidenceId, queryClient, state]);

  /*
   * Clear the non residence queries when selected residence changes
   */
  const previousResidenceId = useRef(residenceId);
  useEffect(() => {
    const previousValue = previousResidenceId.current;
    previousResidenceId.current = residenceId;
    if (null === previousValue) {
      return;
    }

    // Ignore if user is logged out, don't use state variable to avoid effect deps
    if (useAuthStore.getState().state === AUTH_STATE.loggedOut) {
      return;
    }

    setSwitching(true);
    queryClient.clear();
  }, [residenceId, queryClient]);

  // Check if user has access to a residence that match with current residence id
  const hasToCheckResidence = !isUserLoading && residenceId;
  const residenceFound = user?.residences?.some((residence) => residence.id === residenceId);

  if (
    AUTH_STATE.uninitialized === state ||
    isUserLoading ||
    isProfileLoading ||
    switching ||
    (hasToCheckResidence && !residenceFound)
  ) {
    return (
      <View
        sx={{
          height: Platform.select({ default: dimensions.height, web: '100vh' }),
          width: Platform.select({ default: dimensions.width, web: '100vw' }),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator size="large" color={primaryColor} />
      </View>
    );
  }

  if (AUTH_STATE.loggedIn === state && 0 === user.residences.length) {
    return <NoAccessScreen />;
  }

  if (AUTH_STATE.loggedIn === state && !residenceId) {
    return <SelectResidenceScreen />;
  }

  return children;
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default AuthProvider;
