import PropTypes from 'prop-types';
import { Pressable, Avatar, Text } from '@ui/atoms';
import residencePlaceholder from '@src/assets/residence-placeholder.jpg';
import CustomPropTypes from '@utils/CustomPropTypes';

function ResidenceSelectorRow({ residence, onPress, sx }) {
  return (
    <Pressable
      onPress={() => onPress(residence)}
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...sx }}
    >
      <Avatar
        source={residence.logo?.small ?? residencePlaceholder}
        alt={residence.name}
        size="large"
        sx={{ mr: '$4' }}
      />
      <Text variant="subtitle2">{residence.name}</Text>
    </Pressable>
  );
}

ResidenceSelectorRow.propTypes = {
  residence: PropTypes.shape({
    id: CustomPropTypes.uuid,
    name: PropTypes.string,
    logo: PropTypes.shape({
      original: PropTypes.string,
      small: PropTypes.string,
    }),
  }).isRequired,
  onPress: PropTypes.func.isRequired,
  sx: CustomPropTypes.style,
};

ResidenceSelectorRow.defaultProps = {
  sx: undefined,
};

export default ResidenceSelectorRow;
