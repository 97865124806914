import useNotificationsStore, { setCountSelector } from '@src/stores/notificationsStore';
import { useQueryClient } from 'react-query';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import useApiMutation from './useApiMutation';

function useMarkNotificationsAsReadMutation(options) {
  const queryClient = useQueryClient();
  const setCount = useNotificationsStore(setCountSelector);

  return useApiMutation('post', `/v1/notifications/mark-as-read`, {
    ...options,
    onMutate: () => {
      queryClient.cancelQueries(QUERY_CACHE_KEY.notifications());
      setCount(0);
    },
  });
}

export default useMarkNotificationsAsReadMutation;
