function unflatten(obj) {
  const result = {};

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split('.');

    // Create sub-objects along path as needed
    let target = result;
    while (1 < parts.length) {
      const part = parts.shift();
      // eslint-disable-next-line no-multi-assign
      target = target[part] = target[part] || {};
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath];
  }

  return result;
}

export default unflatten;
