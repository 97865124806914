import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef } from 'react';
import { Input } from '@ui/atoms';
import isSameDay from 'date-fns/isSameDay';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import fr from 'date-fns/locale/fr';
import useTranslate from '@hooks/useTranslate';

registerLocale('fr', fr);

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <Input ref={ref} value={value} readonly onFocus={onClick} />
));

CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

CustomInput.defaultProps = {
  value: undefined,
  onClick: undefined,
};

function Datepicker({ value, onChange, minDate, maxDate, withTime }) {
  const { t } = useTranslate();
  const format = withTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';

  const minTime = minDate && isSameDay(value, minDate) ? minDate : startOfDay(new Date());
  const maxTime = maxDate && isSameDay(value, maxDate) ? maxDate : endOfDay(new Date());

  return (
    <DatePicker
      minDate={minDate}
      selected={value}
      onChange={onChange}
      showTimeSelect={withTime}
      timeFormat="HH:mm"
      dateFormat={format}
      timeIntervals={15}
      timeCaption={t('common.hour')}
      locale="fr"
      portalId="datepicker-portal"
      customInput={<CustomInput />}
      minTime={minTime}
      maxTime={maxTime}
    />
  );
}

Datepicker.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  withTime: PropTypes.bool,
};

Datepicker.defaultProps = {
  minDate: undefined,
  maxDate: undefined,
  withTime: false,
};

export default Datepicker;
