import DefaultToast, { SuccessToast, ErrorToast, InfoToast } from 'react-native-toast-message';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform } from 'react-native';

const config = {
  // eslint-disable-next-line react/jsx-props-no-spreading
  success: (props) => <SuccessToast text1NumberOfLines={2} text2NumberOfLines={2} {...props} />,
  // eslint-disable-next-line react/jsx-props-no-spreading
  error: (props) => <ErrorToast text1NumberOfLines={2} text2NumberOfLines={2} {...props} />,
  // eslint-disable-next-line react/jsx-props-no-spreading
  info: (props) => <InfoToast text1NumberOfLines={2} text2NumberOfLines={2} {...props} />,
};

function Toast() {
  const insets = useSafeAreaInsets();
  return (
    <DefaultToast config={config} topOffset={Platform.select({ web: insets.top + 20, default: insets.top + 10 })} />
  );
}

export default Toast;
