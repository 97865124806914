import useMeQuery from '@hooks/queries/useMeQuery';
import useAuthStore from '@src/stores/authStore';

function useCurrentResidenceQuery(options) {
  const { residenceId } = useAuthStore();

  return useMeQuery({
    select: (user) => user?.residences?.find((residence) => residence.id === residenceId),
    ...options,
  });
}

export default useCurrentResidenceQuery;
