import { useMutation } from 'react-query';
import axios from '@utils/axios';

function useUploadMutation(options = {}) {
  const { mutationFnOptions, ...queryOptions } = options;

  return useMutation((file) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.request({
      method: 'post',
      url: '/v1/upload',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      transformRequest: () => {
        return formData; // Make axios FormData works on RN > 0.25 android
      },
      ...mutationFnOptions,
    });
  }, queryOptions);
}

export default useUploadMutation;
