function mapObject(obj, callback) {
  const output = {};

  Object.keys(obj).forEach((k) => {
    const [key, value] = callback(k, obj[k]);
    output[key] = value;
  });

  return output;
}

export default mapObject;
