// eslint-disable-next-line no-restricted-imports
import { Text as DefaultText } from 'dripsy';
import { forwardRef } from 'react';
import CustomPropTypes from '@utils/CustomPropTypes';

const Text = forwardRef(function Text({ sx, ...props }, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DefaultText ref={ref} sx={{ color: '$text.primary', ...sx }} {...props} />;
});

Text.propTypes = {
  sx: CustomPropTypes.style,
};

Text.defaultProps = {
  sx: undefined,
};

export default Text;
