import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import SCREENS from '@constants/Screens';

export default function useWebView() {
  const { navigate } = useNavigation();

  return useCallback(
    (title, uri) => {
      if ('web' === Platform.OS) {
        window.open(uri);
        return;
      }

      navigate(SCREENS.web, { title, uri });
    },
    [navigate]
  );
}
