import { Pressable, Text, UserAvatar } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import { Panel } from '@ui/molecules';
import useProfileQuery from '@hooks/queries/useProfileQuery';
import SCREENS from '@constants/Screens';
import { useNavigation } from '@react-navigation/native';

function StartNewPost() {
  const { navigate } = useNavigation();

  const { data: profile } = useProfileQuery();

  const handlePress = () => navigate(SCREENS.addPost);

  return (
    <Panel sx={{ alignItems: 'center', flexDirection: 'row', height: 75 }} shadow>
      <UserAvatar onPress={() => navigate(SCREENS.editProfile)} sx={{ mr: '$4' }} size="medium" user={profile} />
      <Pressable
        onPress={handlePress}
        sx={{
          height: 45,
          flex: 1,
          bg: '$grey.300',
          borderRadius: '$1',
        }}
      >
        <Text sx={{ color: '$grey.700', lineHeight: 45, px: '$5' }} numberOfLines={1}>
          <FormattedMessage id="ui.StartNewPost.share" />
        </Text>
      </Pressable>
    </Panel>
  );
}

export default StartNewPost;
