/* eslint-disable react/no-unstable-nested-components */
import { Link, useNavigation } from '@react-navigation/native';
import { Button, Ionicons, Input, Text, View, Hidden } from '@ui/atoms';
import { FormControl } from '@ui/molecules';
import { GuestLayout } from '@ui/layouts';
import SCREENS from '@constants/Screens';
import useTranslate from '@hooks/useTranslate';
import { FormattedMessage } from 'react-intl';
import useAuthStore from '@src/stores/authStore';
import { useFormik } from 'formik';
import { object, string } from '@utils/yup';
import useLoginMutation from '@hooks/mutations/useLoginMutation';
import { deviceName } from 'expo-device';
import { useState } from 'react';
import apiErrorsToFormik from '@utils/apiErrorsToFormik';
import useToast from '@hooks/useToast';
import config from '@src/config';
import { Platform } from 'react-native';
import useOpenGtu from '@hooks/useOpenGtu';
import useOpenPrivacyPolicy from '@hooks/useOpenPrivacyPolicy';

function LoginScreen() {
  const { navigate } = useNavigation();
  const { t } = useTranslate();
  const toast = useToast();
  const openGtu = useOpenGtu();
  const openPrivacy = useOpenPrivacyPolicy();

  const { mutateAsync } = useLoginMutation();

  const [loading, setLoading] = useState(false);

  const { setToken } = useAuthStore();

  const form = useFormik({
    initialValues: {
      email: config.login.email || '',
      password: config.login.password || '',
    },
    validationSchema: object({
      email: string().email().required(),
      password: string().required(),
    }),
    onSubmit: async (data, { setErrors }) => {
      try {
        setLoading(true);

        const res = await mutateAsync({
          ...data,
          deviceName: deviceName || navigator.userAgent,
        });

        await setToken(res.data.token);
      } catch (err) {
        if (422 === err.response?.status) {
          apiErrorsToFormik(err.response.data.errors, setErrors);
          toast.error(t('errors.checkData'));
        } else if (429 === err.response?.status) {
          toast.error(t('errors.api.throttle'));
        } else {
          toast.error(t('errors.api.default'));
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const onSubmitEditing = Platform.select({ web: form.submitForm, default: undefined });

  const renderText = (chunk, onPress) => (
    <Text
      variant="small"
      onPress={() => onPress()}
      sx={{
        color: '$primary.main',
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
      }}
    >
      {chunk}
    </Text>
  );

  return (
    <GuestLayout>
      <View sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: ['space-between', 'flex-start'] }}>
        <View>
          <FormControl label={t('LoginScreen.email.label')} error={form.touched.email && form.errors.email} required>
            <Input
              type="email"
              autoComplete="email"
              keyboardType="email-address"
              autoCapitalize="none"
              textContentType="username"
              value={form.values.email}
              onChangeText={form.handleChange('email')}
              onBlur={form.handleBlur('email')}
              onSubmitEditing={onSubmitEditing}
            />
          </FormControl>
          <FormControl
            label={t('LoginScreen.password.label')}
            sx={{ mt: '$5' }}
            error={form.touched.password && form.errors.password}
            required
          >
            <Input
              type="password"
              autoComplete="password"
              textContentType="password"
              value={form.values.password}
              onChangeText={form.handleChange('password')}
              onBlur={form.handleBlur('password')}
              onSubmitEditing={onSubmitEditing}
              secureTextEntry
            />
          </FormControl>
          <View sx={{ mt: '$2' }}>
            <Text
              as={Link}
              to={{ screen: SCREENS.forgotten }}
              sx={{
                textDecorationLine: 'underline',
                textDecorationStyle: 'solid',
              }}
            >
              <FormattedMessage id="LoginScreen.forgotten" />
            </Text>
          </View>
        </View>
        <View sx={{ pt: '$8' }}>
          <Button
            color="secondary"
            title={t('LoginScreen.submit')}
            startIcon={<Ionicons name="log-in-outline" size={24} sx={{ color: '$common.white' }} />}
            onPress={form.submitForm}
            loading={loading}
          />
          <View sx={{ mt: ['$1', '$6'] }}>
            <Hidden only={['xs']}>
              <Text>
                <FormattedMessage id="LoginScreen.noAccount" />
              </Text>
            </Hidden>
            <View sx={{ mt: '$2' }}>
              <Button
                title={t('LoginScreen.signup')}
                startIcon={<Ionicons name="person-outline" size={20} sx={{ color: '$text.primary' }} />}
                onPress={() => navigate(SCREENS.register)}
              />
            </View>
            <Text variant="small" sx={{ mt: '$5' }}>
              <FormattedMessage
                id="LoginScreen.acceptTerms"
                values={{
                  gtuLink: (chunk) => renderText(chunk, openGtu),
                  privacyLink: (chunk) => renderText(chunk, openPrivacy),
                }}
              />
            </Text>
          </View>
        </View>
      </View>
    </GuestLayout>
  );
}

LoginScreen.screenName = SCREENS.login;
LoginScreen.visibility = 'guest';

export default LoginScreen;
