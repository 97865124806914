import guestBackground from '@src/assets/guest-background.jpg';
import { Image } from '@ui/atoms';
import SCREENS from '@constants/Screens';
import { Platform } from 'react-native';
import useDeviceDimensions from '@hooks/useDeviceDimensions';
import '@src/screens/register';
import createCustomStackNavigator from '@src/navigation/createCustomStackNavigator';
import ScreenRegistry from '@src/navigation/ScreenRegistry';
import determineScreenPresentation from '@src/navigation/determineScreenPresentation';

const screens = ScreenRegistry.all()
  .filter((Screen) => ['public', 'guest'].includes(Screen.visibility))
  .filter((Screen) => {
    const presentation = determineScreenPresentation(Screen.presentation);
    if ('modal' === presentation && 'web' === Platform.OS) {
      return false;
    }

    return 'tab' !== presentation;
  });

const StackNavigator = createCustomStackNavigator();

function GuestStack() {
  const dimensions = useDeviceDimensions();

  return (
    <>
      <Image
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          flex: 1,
          height: dimensions.height,
          position: Platform.select({
            default: 'absolute',
            web: 'fixed',
          }),
          width: '100%',
        }}
        source={guestBackground}
      />
      <StackNavigator.Navigator
        initialRouteName={SCREENS.login}
        screenOptions={{
          animation: 'none',
          gestureEnabled: false,
          headerShown: false,
          contentStyle: {
            backgroundColor: 'transparent',
          },
        }}
      >
        {screens.map((Screen) => (
          <StackNavigator.Screen
            key={Screen.screenName}
            name={Screen.screenName}
            component={Screen}
            options={{
              presentation: determineScreenPresentation(Screen.presentation),
              ...Screen.options,
            }}
          />
        ))}
      </StackNavigator.Navigator>
    </>
  );
}

export default GuestStack;
