import { useBreakpointIndex } from 'dripsy';

const BREAKPOINTS_NAME = ['xs', 'sm', 'md', 'lg', 'xl'];

function useBreakpointName() {
  const breakpointSize = useBreakpointIndex();
  return BREAKPOINTS_NAME[breakpointSize];
}

export default useBreakpointName;
