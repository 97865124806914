import { Platform } from 'react-native';

const webFont = (font) => {
  return Platform.select({
    web: `${font}, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, Inter-serif`,
    default: font,
  });
};

export default {
  customFonts: {
    poppins: {
      default: webFont('PoppinsRegular'),
      bold: webFont('PoppinsBold'),
      normal: webFont('PoppinsRegular'),
      100: webFont('PoppinsThin'),
      200: webFont('PoppinsExtraLight'),
      300: webFont('PoppinsLight'),
      400: webFont('PoppinsRegular'),
      500: webFont('PoppinsMedium'),
      600: webFont('PoppinsSemiBold'),
      700: webFont('PoppinsBold'),
      800: webFont('PoppinsExtraBold'),
      900: webFont('PoppinsBlack'),
    },
  },
  fonts: {
    root: 'poppins',
  },
  fontWeights: {
    thin: '100',
    extralight: '200',
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
};
