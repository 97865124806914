import { Button, SafeAreaView, Text, View } from '@ui/atoms';
import { logout } from '@src/stores/authStore';
import useDeviceDimensions from '@hooks/useDeviceDimensions';
import useMeQuery from '@hooks/queries/useMeQuery';
import useTranslate from '@hooks/useTranslate';
import { FormattedMessage } from 'react-intl';
import ModalProvider from '@src/components/ModalProvider';
import useToast from '@hooks/useToast';
import useConfirmAlert from '@hooks/useConfirmAlert';
import useDeleteMeMutation from '@hooks/mutations/useDeleteMeMutation';

function NoAccessScreen() {
  const { t } = useTranslate();
  const toast = useToast();
  const dimensions = useDeviceDimensions();
  const showConfirmAlert = useConfirmAlert();

  const { data: user } = useMeQuery();

  const { mutateAsync } = useDeleteMeMutation({
    onError: () => toast.error(t('errors.api.default')),
    onSuccess: () => {
      logout();
      toast.success(t('NoAccessScreen.confirmDelete.success'));
    },
  });

  const handleDeleteAccount = () => {
    showConfirmAlert({
      title: t('NoAccessScreen.confirmDelete.title'),
      description: t('NoAccessScreen.confirmDelete.description'),
      destructive: true,
      confirmLabel: t('NoAccessScreen.confirmDelete.confirm'),
      onConfirm: () => mutateAsync(),
    });
  };

  return (
    <>
      <SafeAreaView
        sx={{
          height: dimensions.height,
          width: dimensions.width,
          backgroundColor: '$grey.100',
          justifyContent: 'center',
          alignItems: 'center',
          px: '$4',
        }}
      >
        <View sx={{ maxWidth: 450 }}>
          <Text variant="title" sx={{ textAlign: 'center' }}>
            <FormattedMessage id="NoAccessScreen.hello" values={{ firstname: user.firstname }} />
          </Text>
          <Text variant="body2" sx={{ textAlign: 'center', my: '$5' }}>
            <FormattedMessage id="NoAccessScreen.title" />
          </Text>
          <Button title={t('NoAccessScreen.logout')} color="secondary" onPress={logout} />
          <Text variant="body" sx={{ mt: '$5', textAlign: 'center', color: '$red.main' }} onPress={handleDeleteAccount}>
            <FormattedMessage id="NoAccessScreen.deleteAccount" />
          </Text>
        </View>
      </SafeAreaView>
      <ModalProvider />
    </>
  );
}

export default NoAccessScreen;
