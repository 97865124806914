import Survey from '@ui/organisms/Survey';
import PostCard from '@ui/organisms/PostCard';
import EventCard from '@ui/organisms/EventCard';

const FEED_ITEM_TYPE = {
  post: 'post',
  survey: 'survey',
  event: 'event',
};

export const FEED_ITEM_COMPONENT = {
  [FEED_ITEM_TYPE.survey]: Survey,
  [FEED_ITEM_TYPE.post]: PostCard,
  [FEED_ITEM_TYPE.event]: EventCard,
};

export default FEED_ITEM_TYPE;
