import { Dimensions, Platform, useWindowDimensions } from 'react-native';
import { useEffect, useState } from 'react';

const dimensionsRef = 'android' === Platform.OS ? 'screen' : 'window';

function useDeviceDimensions() {
  const window = useWindowDimensions();
  const [width, setWidth] = useState(Dimensions.get(dimensionsRef).width);
  const [height, setHeight] = useState(Dimensions.get(dimensionsRef).width);

  useEffect(() => {
    setWidth(Dimensions.get(dimensionsRef).width);
    setHeight(Dimensions.get(dimensionsRef).height);
  }, [window.width, window.height]);

  return { width, height };
}

export default useDeviceDimensions;
