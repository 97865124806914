import { ActivityIndicator, View } from '@ui/atoms';
import useSurveyQuery from '@hooks/queries/useSurveyQuery';
import useTranslate from '@hooks/useTranslate';
import { SurveyAnswers } from '@ui/organisms';
import useThemeColor from '@hooks/useThemeColor';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import SCREENS from '@constants/Screens';
import Screen from '@ui/templates/Screen';

function SurveyAnswersScreen({ route }) {
  const { t } = useTranslate();

  const primaryColor = useThemeColor('$primary.main');

  const { data: survey, isLoading } = useSurveyQuery(route.params.id);

  return (
    <Screen title={t('SurveyAnswersScreen.title')} presentation={SurveyAnswersScreen.presentation} scrollable>
      {isLoading ? (
        <View
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: '$8',
          }}
        >
          <ActivityIndicator size="large" color={primaryColor} />
        </View>
      ) : (
        <SurveyAnswers survey={survey} />
      )}
    </Screen>
  );
}

SurveyAnswersScreen.presentation = { default: 'card', web: 'modal' };
SurveyAnswersScreen.screenName = SCREENS.surveyAnswers;

SurveyAnswersScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      id: CustomPropTypes.uuid.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SurveyAnswersScreen;
