import Drawer from './Drawer.json';
import Header from './Header.json';
import StartNewPost from './StartNewPost.json';
import PostForm from './PostForm.json';
import Post from './Post.json';
import AddComment from './AddComment.json';
import ContactsWidget from './ContactsWidget.json';
import RegisterForm from './RegisterForm.json';
import SelectResidenceDialog from './SelectResidenceDialog.json';
import Comments from './Comments.json';
import FilePicker from './FilePicker.json';
import BlockedUserRow from './BlockedUserRow.json';
import ReplyEvent from './ReplyEvent.json';
import Survey from './Survey.json';
import SurveyAnswers from './SurveyAnswers.json';
import SurveyForm from './SurveyForm.json';
import PostEmbed from './PostEmbed.json';
import LightboxDialog from './LightboxDialog.json';
import NotificationsList from './NotificationsList.json';
import ProfileForm from './ProfileForm.json';
import UserSettings from './UserSettings.json';
import EventForm from './EventForm.json';
import AddToCalendar from './AddToCalendar.json';
import EventCard from './EventCard.json';

export default {
  Drawer,
  Header,
  StartNewPost,
  PostForm,
  Post,
  AddComment,
  ContactsWidget,
  RegisterForm,
  SelectResidenceDialog,
  Comments,
  FilePicker,
  BlockedUserRow,
  ReplyEvent,
  Survey,
  SurveyAnswers,
  SurveyForm,
  PostEmbed,
  LightboxDialog,
  NotificationsList,
  ProfileForm,
  UserSettings,
  EventForm,
  AddToCalendar,
  EventCard,
};
