class ScreenRegistry {
  static screens = [];

  static register(screens) {
    // eslint-disable-next-line no-param-reassign
    screens = Array.isArray(screens) ? screens : [screens];

    const missingNameScreen = screens.find((screen) => !screen.screenName);
    if (missingNameScreen) {
      throw new Error(`Screen ${missingNameScreen.name} must have a screenName property`);
    }

    ScreenRegistry.screens = [...ScreenRegistry.screens, ...screens];
  }

  static get(name) {
    if (!ScreenRegistry.has(name)) {
      throw new Error('Screen not found, have you registered this screen ?');
    }

    return ScreenRegistry.screens.find((Screen) => Screen.screenName === name);
  }

  static has(name) {
    return ScreenRegistry.screens.some((Screen) => Screen.screenName === name);
  }

  static all() {
    return ScreenRegistry.screens;
  }
}

export default ScreenRegistry;
