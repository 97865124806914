import create from 'zustand';
import AUTH_STATE from '@constants/AuthState';
import AsyncStorage from '@react-native-async-storage/async-storage';

const TOKEN_STORAGE = 'kng-token';
const RESIDENCE_ID_STORAGE = 'kng-residence-id';

/**
 * @deprecated Will be removed in next release
 */
async function backwardsCompatibility() {
  const token = await AsyncStorage.getItem('hmh-token');
  const residenceId = await AsyncStorage.getItem('hmh-residence-id');

  if (token) {
    await AsyncStorage.setItem(TOKEN_STORAGE, token);
    await AsyncStorage.setItem(RESIDENCE_ID_STORAGE, residenceId);
  }
}

const useAuthStore = create((set) => ({
  state: AUTH_STATE.uninitialized,
  residenceId: null,
  token: null,
  redirectTo: null,
  init: async () => {
    await backwardsCompatibility();

    const token = await AsyncStorage.getItem(TOKEN_STORAGE);
    const residenceId = await AsyncStorage.getItem(RESIDENCE_ID_STORAGE);

    set({ residenceId, token });

    return { token, residenceId };
  },
  setState: (state) => set({ state }),
  setResidenceId: (residenceId, redirectTo = null) => {
    set({ residenceId, redirectTo });

    if (!residenceId) {
      AsyncStorage.removeItem(RESIDENCE_ID_STORAGE);
      return;
    }

    // Sync storage
    AsyncStorage.setItem(RESIDENCE_ID_STORAGE, residenceId);
  },
  setToken: async (token) => {
    set({ state: AUTH_STATE.loggedIn, token });

    // Sync storage
    AsyncStorage.setItem(TOKEN_STORAGE, token);
  },
  clearRedirect: () => set({ redirectTo: null }),
}));

export const residenceIdSelector = (state) => state.residenceId;

export const authStateSelector = (state) => state.state;

export async function logout() {
  await Promise.all([AsyncStorage.removeItem(TOKEN_STORAGE), AsyncStorage.removeItem(RESIDENCE_ID_STORAGE)]);
  useAuthStore.setState((prev) => ({ ...prev, state: AUTH_STATE.loggedOut }));
}

export default useAuthStore;
