import create from 'zustand';
import * as Notifications from 'expo-notifications';

const useNotificationsStore = create((set) => ({
  count: 0,
  setCount: (count) => {
    set({ count });

    Notifications.setBadgeCountAsync(count, {
      web: {
        method: 'Title',
      },
    });
  },
}));

export const countSelector = (state) => state.count;
export const setCountSelector = (state) => state.setCount;

export default useNotificationsStore;
