import Screen from '@ui/templates/Screen';
import useTranslate from '@hooks/useTranslate';
import SCREENS from '@constants/Screens';
import { Panel } from '@ui/molecules';
import { ActivityIndicator, Button, Text, View } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import { AppState, Platform, Switch } from 'react-native';
import useThemeColor from '@hooks/useThemeColor';
import useNotificationsSettingsQuery from '@hooks/queries/useNotificationsSettingsQuery';
import useUpdateNotificationsSettingsMutation from '@hooks/mutations/useUpdateNotificationsSettingsMutation';
import useToast from '@hooks/useToast';
import { useCallback, useEffect, useState } from 'react';
import * as Notifications from 'expo-notifications';
import { useFocusEffect } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { useSubscribe } from '@src/components/PushNotificationProvider/PushNotificationProvider';

function NotificationsSettingsScreen() {
  const { t } = useTranslate();
  const toast = useToast();
  const primaryColor = useThemeColor('$primary.main');
  const greyColor = useThemeColor('$grey.200');

  const [status, setStatus] = useState('granted');

  const subscribe = useSubscribe();

  const { data: settings, isLoading } = useNotificationsSettingsQuery();

  const { mutate } = useUpdateNotificationsSettingsMutation({
    onError: () => toast.error(t('errors.api.default')),
  });

  const refreshStatus = useCallback(() => {
    (async () => {
      if ('web' === Platform.OS) {
        return;
      }

      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      setStatus(existingStatus);

      if ('granted' === existingStatus) {
        subscribe();
      }
    })();
  }, [subscribe]);

  useFocusEffect(refreshStatus);

  useEffect(() => {
    const listener = AppState.addEventListener('change', refreshStatus);
    return () => listener.remove();
  }, [refreshStatus]);

  const handleActivate = async () => {
    const result = await Notifications.requestPermissionsAsync();

    if ('granted' !== result.status) {
      await Linking.openSettings();
    }

    refreshStatus();
  };

  const Container = Platform.select({ web: View, default: Panel });

  const handleChange = (type, data) => {
    const currentData = settings.items.find((item) => item.type === type);
    mutate({
      settings: [
        {
          type,
          email: data.email ?? currentData.email,
          push: data.push ?? currentData.push,
        },
      ],
    });
  };

  return (
    <Screen
      title={t('NotificationsSettingsScreen.title')}
      presentation={NotificationsSettingsScreen.presentation}
      scrollable
    >
      {isLoading ? (
        <View
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: '$8',
          }}
        >
          <ActivityIndicator size="large" color={primaryColor} />
        </View>
      ) : (
        <>
          <Container>
            <Text variant="subtitle2" sx={{ mb: '$4' }}>
              <FormattedMessage id="NotificationsSettingsScreen.mobile" />
            </Text>
            <View>
              {'granted' === status ? (
                settings.items
                  .filter((item) => item.supportPush)
                  .map((item, index) => (
                    <View
                      key={item.type}
                      sx={{
                        mb: index === settings.items.length - 1 ? 0 : '$4',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text>{item.label}</Text>
                      <Switch
                        trackColor={{ true: primaryColor, false: greyColor }}
                        value={item.push}
                        onValueChange={() => handleChange(item.type, { push: !item.push })}
                      />
                    </View>
                  ))
              ) : (
                <Button title={t('NotificationsSettingsScreen.activate')} color="primary" onPress={handleActivate} />
              )}
            </View>
          </Container>
          <Container sx={{ mt: Platform.select({ web: '$6', default: '$4' }) }}>
            <Text variant="subtitle2" sx={{ mb: '$4' }}>
              <FormattedMessage id="NotificationsSettingsScreen.email" />
            </Text>
            <View>
              {settings.items
                .filter((item) => item.supportEmail)
                .map((item, index) => (
                  <View
                    key={item.type}
                    sx={{
                      mb: index === settings.items.length - 1 ? 0 : '$4',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text>{item.label}</Text>
                    <Switch
                      trackColor={{ true: primaryColor, false: greyColor }}
                      value={item.email}
                      onValueChange={() => handleChange(item.type, { email: !item.email })}
                    />
                  </View>
                ))}
            </View>
          </Container>
        </>
      )}
    </Screen>
  );
}

NotificationsSettingsScreen.presentation = {
  web: 'modal',
  default: 'card',
};

NotificationsSettingsScreen.screenName = SCREENS.notificationsSettings;

export default NotificationsSettingsScreen;
