import { useMemo } from 'react';
import CustomPropTypes from '@utils/CustomPropTypes';
import PropTypes from 'prop-types';
import PostHeader from '@ui/organisms/Post/PostHeader';
import PostContent from '@ui/organisms/Post/PostContent';
import PostToolbar from '@ui/organisms/Post/PostToolbar';
import PostContext from '@ui/organisms/Post/postContext';

function Post({ post, children }) {
  const value = useMemo(() => ({ post }), [post]);
  return <PostContext.Provider value={value}>{children}</PostContext.Provider>;
}

Post.propTypes = {
  post: CustomPropTypes.post.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Post.Header = PostHeader;
Post.Content = PostContent;
Post.Toolbar = PostToolbar;

export default Post;
