import PropTypes from 'prop-types';
import useBreakpointName from '@hooks/useBreakpointName';
import View from '@ui/atoms/View';
import { Platform } from 'react-native';

function Hidden({ children, only = undefined, except = undefined, css = false }) {
  const breakpoint = useBreakpointName();

  if (only !== undefined) {
    only = Array.isArray(only) ? only : [only];
  }

  if (except !== undefined) {
    except = Array.isArray(except) ? except : [except];
  }

  const isHidden =
    (only !== undefined && only.includes(breakpoint)) || (except !== undefined && !except.includes(breakpoint));

  if (isHidden && !css) {
    return null;
  }

  if (isHidden && css) {
    return (
      <View
        sx={Platform.select({
          default: { display: 'none' },
          web: { visibility: 'hidden' },
        })}
      >
        {children}
      </View>
    );
  }

  return children;
}

Hidden.propTypes = {
  children: PropTypes.node.isRequired,
  only: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  except: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  css: PropTypes.bool,
};

export default Hidden;
