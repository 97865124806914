import { Text, View } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import SURVEY_TYPE from '@constants/SurveyType';
import CustomPropTypes from '@utils/CustomPropTypes';
import ChoicesAnswers from './ChoicesAnswers';
import RatingAnswers from './RatingAnswers';

const TYPES = {
  [SURVEY_TYPE.rating]: RatingAnswers,
  [SURVEY_TYPE.choices]: ChoicesAnswers,
};

function SurveyAnswers({ survey }) {
  const Answers = TYPES[survey.type];

  return (
    <View>
      <Text variant="subtile" sx={{ fontSize: 12, color: '$primary.main', mb: '$4' }}>
        <FormattedMessage id="ui.SurveyAnswers.visibility" />
      </Text>
      <Answers survey={survey} />
    </View>
  );
}

SurveyAnswers.propTypes = {
  survey: CustomPropTypes.survey.isRequired,
};

export default SurveyAnswers;
