import { useCallback } from 'react';
import SCREENS from '@constants/Screens';
import { useNavigation } from '@react-navigation/native';
import useMeQuery from '@hooks/queries/useMeQuery';

export default function useOpenUserProfile() {
  const { navigate } = useNavigation();
  const { data: currentUser } = useMeQuery();

  return useCallback(
    (id) => {
      if (currentUser.id === id) {
        navigate(SCREENS.editProfile);
      } else {
        navigate(SCREENS.neighbor, { id });
      }
    },
    [navigate, currentUser]
  );
}
