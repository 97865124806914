import CustomPropTypes from '@utils/CustomPropTypes';
import PropTypes from 'prop-types';
import { Alert } from '@ui/molecules';
import useTranslate from '@hooks/useTranslate';
import { useNavigation } from '@react-navigation/native';
import useOpenUserProfile from '@hooks/useOpenUserProfile';
import { Chip, Flex, Pressable, Skeleton, Text, UserAvatar, View } from '@ui/atoms';
import { TouchableOpacity } from 'react-native';
import SCREENS from '@constants/Screens';
import usePostQuery from '@hooks/queries/usePostQuery';

/*
 * Strange name but PostEmbed refer to an embed entity attached to a Post
 * Here, AnotherPostEmbed refer to a Post type embed attached to a Post
 */
function AnotherPostEmbed({ id, available, disabled, sx }) {
  const { t } = useTranslate();
  const { navigate } = useNavigation();
  const openUserProfile = useOpenUserProfile();

  const { data: post, isLoading, isError } = usePostQuery(id, { enabled: available });

  const Container = disabled ? View : TouchableOpacity;

  if (isError || !available) {
    return <Alert type="error" message={t('ui.PostEmbed.AnotherPostEmbed.error')} sx={sx} />;
  }

  if (isLoading) {
    return (
      <View
        sx={{
          borderWidth: 1,
          borderColor: '$grey.300',
          borderRadius: '$1',
          height: 'auto',
          p: '$4',
          ...sx,
        }}
      >
        <Skeleton width={70} height={26} radius={13} sx={{ mr: '$2', mb: '$2' }} />
        <View sx={{ width: '100%', mb: '$2' }}>
          <Skeleton width={200} height={18} />
          <Skeleton width="100%" height={40} sx={{ mt: '$2', mb: '$5' }} />
        </View>
        <Flex alignItems="center">
          <Skeleton height={16} width={16} radius={8} sx={{ mr: '$2' }} />
          <Skeleton width={140} height={8} />
        </Flex>
      </View>
    );
  }

  const handleProfileView = () => openUserProfile(post.user.id);
  const handleOpen = () => navigate(SCREENS.post, { id });

  return (
    <Container onPress={disabled ? undefined : handleOpen}>
      <View
        sx={{
          borderWidth: 1,
          borderColor: '$grey.300',
          borderRadius: '$1',
          height: 'auto',
          p: '$4',
          ...sx,
        }}
      >
        <View sx={{ alignItems: 'flex-start' }}>
          <Chip
            label={t('constants.PostType', { value: post.type })}
            size="extraSmall"
            variant="primary"
            sx={{ mb: '$2' }}
            active
          />
          <Text variant="subtitle">{post.title}</Text>
          <Text sx={{ mt: '$2', mb: '$5' }} numberOfLines={3}>
            {post.content}
          </Text>
          <Pressable onPress={handleProfileView} sx={{ alignItems: 'center', flexDirection: 'row' }}>
            <UserAvatar sx={{ mr: '$2' }} size="small" user={post.user} />
            <Text variant="subtitle" sx={{ color: '$primary.main' }}>
              {post.user.fullname}
            </Text>
          </Pressable>
        </View>
      </View>
    </Container>
  );
}

AnotherPostEmbed.propTypes = {
  id: CustomPropTypes.uuid,
  available: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  sx: CustomPropTypes.style,
};

AnotherPostEmbed.defaultProps = {
  id: undefined,
  disabled: false,
  sx: undefined,
};

export default AnotherPostEmbed;
