/* eslint-disable react/no-array-index-key */
import { FlatList, Text, View, ScrollView } from '@ui/atoms';
import useNeighborsQuery from '@hooks/queries/useNeighborsQuery';
import { Neighbor } from '@ui/molecules';
import { useSx } from 'dripsy';
import { Platform } from 'react-native';
import { FormattedMessage } from 'react-intl';
import NeighborSkeleton from '@ui/molecules/NeighborSkeleton';
import useTranslate from '@hooks/useTranslate';
import { useRef } from 'react';
import SCREENS from '@constants/Screens';
import Screen from '@ui/templates/Screen';

const emptyArray = [];

function NeighborsScreen() {
  const sx = useSx();
  const { t } = useTranslate();
  const flatListRef = useRef();

  const { data: neighbors, isLoading } = useNeighborsQuery({
    select: (data) => data?.items ?? emptyArray,
  });

  if ('web' === Platform.OS) {
    const emptyCell = isLoading
      ? null
      : Array(3 - (neighbors.length % 3))
          .fill('')
          .map((_, index) => <View key={index} sx={{ mt: '$3', width: 'calc((100% / 3) - 8px)' }} />);

    return (
      <ScrollView
        contentContainerSx={{
          px: '$5',
          py: ['$5', null, null, '$6'],
          maxWidth: 'container',
          width: '100%',
          mx: 'auto',
        }}
      >
        <Text variant="title" sx={{ color: '$primary.main' }}>
          <FormattedMessage id="NeighborsScreen.title" />
        </Text>
        <View
          sx={{
            mt: [null, null, null, '$4'],
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'stretch',
            justifyContent: 'space-between',
          }}
        >
          {isLoading
            ? Array(6)
                .fill('')
                .map((_, index) => (
                  <NeighborSkeleton
                    key={index}
                    sx={{
                      mt: '$3',
                      width: ['100%', 'calc((100% / 2) - 8px)', 'calc((100% / 2) - 8px)', 'calc((100% / 3) - 8px)'],
                    }}
                    expanded
                  />
                ))
            : neighbors.map((neighbor) => (
                <Neighbor
                  key={neighbor.id}
                  contact={neighbor}
                  sx={{
                    mt: '$3',
                    width: ['100%', 'calc((100% / 2) - 8px)', 'calc((100% / 2) - 8px)', 'calc((100% / 3) - 8px)'],
                  }}
                  expanded
                />
              ))}
          {emptyCell}
        </View>
      </ScrollView>
    );
  }

  return (
    <Screen title={t('NeighborsScreen.title')} presentation={NeighborsScreen.presentation} sx={{ p: 0, pt: 0 }}>
      {isLoading ? (
        <View sx={{ py: 50, px: '$4' }}>
          {Array(6)
            .fill('')
            .map((_, index) => (
              <NeighborSkeleton key={index} sx={{ mt: '$3' }} expanded />
            ))}
        </View>
      ) : (
        <FlatList
          ref={flatListRef}
          data={neighbors}
          sx={{ flexGrow: 0 }}
          contentContainerStyle={sx({ p: '$4' })}
          renderItem={({ item }) => <Neighbor key={item.id} contact={item} sx={{ mt: '$3' }} expanded />}
          keyExtractor={(item) => item.id}
          alwaysBounceVertical={false}
        />
      )}
    </Screen>
  );
}

NeighborsScreen.presentation = 'card';
NeighborsScreen.screenName = SCREENS.neighbors;

export default NeighborsScreen;
