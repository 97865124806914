import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useEcho } from '@src/stores/socketStore';
import useAuthStore, { residenceIdSelector } from '@src/stores/authStore';
import useFeedQuery from '@hooks/queries/useFeedQuery';
import FEED_ITEM_TYPE from '@constants/FeedItemType';
import useRefreshOnFocus from '@hooks/useRefreshOnFocus';

function useFeed(types, { postTypes, surveyStates, eventStates }) {
  const queryClient = useQueryClient();
  const echo = useEcho();

  const residenceId = useAuthStore(residenceIdSelector);

  const [hasNewItems, setHasNewItems] = useState(false);

  useEffect(() => setHasNewItems(false), [types, postTypes, surveyStates, eventStates]);

  useEffect(() => {
    if (!echo) {
      return;
    }

    const postListener = (data) => {
      if (postTypes?.includes(data.post_type)) {
        setHasNewItems(true);
      }
    };

    const surveyListener = () => {
      if (types.includes(FEED_ITEM_TYPE.survey)) {
        setHasNewItems(true);
      }
    };

    const eventListener = () => {
      if (types.includes(FEED_ITEM_TYPE.event)) {
        setHasNewItems(true);
      }
    };

    echo.private(`Residence.${residenceId}`).listen('.PostPublished', postListener);
    echo.private(`Residence.${residenceId}`).listen('.SurveyPublished', surveyListener);
    echo.private(`Residence.${residenceId}`).listen('.EventPublished', eventListener);

    // eslint-disable-next-line consistent-return
    return () => {
      echo.private(`Residence.${residenceId}`).stopListening('.PostPublished', postListener);
      echo.private(`Residence.${residenceId}`).stopListening('.SurveyPublished', surveyListener);
      echo.private(`Residence.${residenceId}`).stopListening('.EventPublished', eventListener);
    };
  }, [echo, queryClient, types, residenceId, setHasNewItems, postTypes]);

  const { data, isStale, refetch, isFetching } = useFeedQuery(
    { type: types, postType: postTypes, surveyState: surveyStates, eventState: eventStates },
    {
      // Disable refetch of active requests to avoid scroll flickering,
      // there is a button "new posts available" in this case.
      refetchOnWindowFocus: false,

      onSuccess: () => setHasNewItems(false),
    }
  );

  // Refresh on focus only when query is stale in order
  // to avoid useless loading when close mobile modal
  useRefreshOnFocus(refetch, isStale);

  return { items: data?.items ?? [], isStale, refetch, isFetching, hasNewItems };
}

export default useFeed;
