export default {
  apiBaseUrl: 'http://192.168.1.101:8083',
  gtu: 'https://www.kunagi.fr/cgu-mentions-legales-dpa',
  privacy: 'https://www.kunagi.fr/politique-de-confidentialite-app',
  notifications: {
    experienceId: '@yieldstudio/homemeethome-dev',
  },
  login: {
    email: 'user@kunagi.fr',
    password: '0000',
  },
  pusher: {
    host: '127.0.0.1',
    port: 6001,
    key: 'app-key',
    enabledTransports: ['ws'],
    forceTLS: false,
  },
};
