import { makeTheme } from 'dripsy';
import shadows from './shadows';
import colors from './colors';
import fonts from './fonts';
import variants from './variants';

const theme = makeTheme({
  types: {
    onlyAllowThemeValues: true,
  },
  breakpoints: [576, 768, 1280, 1400],
  sizes: {
    container: 1400,
  },
  space: {
    $1: 4,
    $2: 8,
    $3: 12,
    $4: 16,
    $5: 20,
    $6: 24,
    $7: 32,
    $8: 48,
    $9: 64,
    $10: 128,
    '$-1': -4,
    '$-2': -8,
    '$-3': -12,
    '$-4': -16,
    '$-5': -20,
    '$-6': -24,
    '$-7': -32,
    '$-8': -48,
    '$-9': -64,
    '$-10': -128,
  },
  radii: {
    $1: 5,
    $2: 10,
  },
  colors,
  shadows,
  ...fonts,
  ...variants,
});

export default theme;
