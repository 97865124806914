/* eslint-disable react/jsx-props-no-spreading */
import { Container, Ionicons, Text, View } from '@ui/atoms';
import PropTypes from 'prop-types';
import { Platform } from 'react-native';
import CustomPropTypes from '@utils/CustomPropTypes';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import determineScreenPresentation from '@src/navigation/determineScreenPresentation';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSx } from 'dripsy';
import HEADER_HEIGHT from '@constants/HeaderHeight';
import Dropdown from '@ui/templates/Dropdown';
import { forwardRef } from 'react';
import { DropdownOptionType } from '@ui/templates/Dropdown/DropdownPropTypes';
import ScreenHeader from './ScreenHeader';

const Screen = forwardRef(function Screen(
  { presentation: givenPresentation, children, title, actions, scrollable, withoutHeader, sx, bgColor },
  ref
) {
  const sxify = useSx();
  const insets = useSafeAreaInsets();
  const presentation = determineScreenPresentation(givenPresentation);

  const isStack = 'card' === presentation && 'web' !== Platform.OS;
  const hasHeader = 'tab' !== presentation && !withoutHeader;
  const isBasicHeader = 'card' === presentation && 'web' === Platform.OS;
  const webHeaderPadding = 'web' === Platform.OS && hasHeader && !isBasicHeader ? [HEADER_HEIGHT + 16, '$4'] : '$4';

  if (hasHeader && !title) {
    // eslint-disable-next-line no-param-reassign
    title = 'Missing title';
    // eslint-disable-next-line no-console
    console.warn('Missing screen title');
  }

  const Component = scrollable ? KeyboardAwareScrollView : View;
  const componentProps = scrollable
    ? {
        style: sxify({ backgroundColor: bgColor }),
        contentContainerStyle: sxify({
          p: '$4',
          pb: '$6',
          pt: webHeaderPadding,
          ...sx,
        }),
      }
    : {
        sx: {
          ...Platform.select({
            web: { pt: webHeaderPadding },
            default: { flex: 1 },
          }),
          backgroundColor: bgColor,
          p: '$4',
          ...sx,
        },
      };

  return (
    <>
      {hasHeader && !isBasicHeader && (
        <ScreenHeader
          title={title}
          actions={actions}
          backIcon={isStack ? 'chevron-back-outline' : 'close'}
          insetTop={Platform.select({ android: insets.top, ios: isStack ? insets.top : 0, default: 0 })}
        />
      )}
      <Component ref={ref} {...componentProps}>
        {hasHeader && isBasicHeader && (
          <Container>
            <View
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                py: '$3',
              }}
            >
              <Text variant="title" sx={{ color: '$primary.main', mr: '$2' }}>
                {title}
              </Text>
              <Dropdown options={actions} width={200}>
                <Ionicons name="ellipsis-vertical-sharp" size={24} />
              </Dropdown>
            </View>
          </Container>
        )}
        {children}
      </Component>
    </>
  );
});

const PresentationType = PropTypes.oneOf(['tab', 'card', 'modal']);

Screen.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  presentation: PropTypes.oneOfType([
    PresentationType,
    PropTypes.shape({
      ios: PresentationType,
      android: PresentationType,
      web: PresentationType,
      default: PresentationType,
    }),
  ]).isRequired,
  actions: PropTypes.arrayOf(DropdownOptionType),
  scrollable: PropTypes.bool,
  sx: CustomPropTypes.style,
  withoutHeader: PropTypes.bool,
  bgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

Screen.defaultProps = {
  title: undefined,
  actions: [],
  scrollable: false,
  sx: undefined,
  withoutHeader: false,
  bgColor: undefined,
};

export default Screen;
