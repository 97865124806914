
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({
  "replaceAttrValues": {
    "#000": "{props.color}"
  }
});
        import { Flex, Pressable, Text } from '@ui/atoms';
import CustomPropTypes from '@utils/CustomPropTypes';

  const LikeIcon = __ReactNativeSvgLoader(import('@src/assets/icons/like.svg'));
  ;

  const LikeOutlinedIcon = __ReactNativeSvgLoader(import('@src/assets/icons/likeOutlined.svg'));
  ;

  const CommentIcon = __ReactNativeSvgLoader(import('@src/assets/icons/comment.svg'));
  ;
import useThemeColor from '@hooks/useThemeColor';
import { FormattedMessage } from 'react-intl';
import useLikePostMutation from '@hooks/mutations/useLikePostMutation';
import useUnlikePostMutation from '@hooks/mutations/useUnlikePostMutation';
import PropTypes from 'prop-types';
import { usePost } from '@ui/organisms/Post/postContext';

function PostToolbar({ onPressComment, sx }) {
  const primaryColor = useThemeColor('$primary.main');

  const post = usePost();

  const { mutate: like } = useLikePostMutation(post.id);
  const { mutate: unlike } = useUnlikePostMutation(post.id);

  const liked = post.currentUserLikes;

  const handlePressLike = () => {
    if (liked) {
      unlike();
    } else {
      like();
    }
  };

  return (
    <Flex sx={sx}>
      <Pressable onPress={handlePressLike} sx={{ flexDirection: 'row', alignItems: 'center', mr: '$4' }}>
        {liked ? (
          <LikeIcon height={16} width={17} color={primaryColor} />
        ) : (
          <LikeOutlinedIcon height={16} width={17} color={primaryColor} />
        )}
        <Text
          variant={liked ? 'subtitle' : undefined}
          sx={{ ml: '$2', color: liked ? '$primary.main' : '$text.primary' }}
        >
          <FormattedMessage id="ui.Post.PostToolbar.like" />
        </Text>
      </Pressable>
      <Pressable onPress={onPressComment} sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <CommentIcon height={20} width={17} color={primaryColor} />
        <Text sx={{ ml: '$2' }}>
          <FormattedMessage id="ui.Post.PostToolbar.comment" />
        </Text>
      </Pressable>
    </Flex>
  );
}

PostToolbar.propTypes = {
  onPressComment: PropTypes.func.isRequired,
  sx: CustomPropTypes.style,
};

PostToolbar.defaultProps = {
  sx: undefined,
};

export default PostToolbar;

        