import PropTypes from 'prop-types';

const uuid = PropTypes.string;

const apiFile = PropTypes.shape({
  id: uuid,
  name: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.string,
});

const neighbor = PropTypes.shape({
  id: uuid,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  fullname: PropTypes.string,
  description: PropTypes.string,
  flatNumber: PropTypes.string,
  role: PropTypes.string,
  avatar: PropTypes.shape({
    original: PropTypes.string,
    small: PropTypes.string,
  }),
});

// React native file shape for FormData
const nativeFile = PropTypes.shape({
  name: PropTypes.string,
  uri: PropTypes.string,
  type: PropTypes.string,
});

const CustomPropTypes = {
  uuid,
  nativeFile,
  apiFile,
  neighbor,
  style: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
    PropTypes.number,
  ]),
  file: PropTypes.oneOfType([apiFile, nativeFile, PropTypes.instanceOf(File)]),
  // eslint-disable-next-line react/forbid-prop-types
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  // eslint-disable-next-line react/forbid-prop-types
  formTouched: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.objectOf(PropTypes.any),
  post: PropTypes.shape({
    id: uuid,
    type: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    category: PropTypes.shape({
      id: uuid,
      name: PropTypes.string,
    }),
    user: neighbor,
    likesCount: PropTypes.number,
    currentUserLikes: PropTypes.bool,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: uuid,
        original: PropTypes.string,
        small: PropTypes.string,
        large: PropTypes.string,
      })
    ),
    file: apiFile,
    embed: PropTypes.shape({
      type: PropTypes.string,
      id: uuid,
      available: PropTypes.bool,
    }),
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    permissions: PropTypes.shape({
      edit: PropTypes.bool,
      delete: PropTypes.bool,
      share: PropTypes.bool,
    }),
  }),
  event: PropTypes.shape({
    id: uuid,
    title: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    canceledAt: PropTypes.string,
    timezone: PropTypes.string,
    currentUserReply: PropTypes.bool,
    participants: PropTypes.arrayOf(neighbor),
    thumbnail: PropTypes.shape({
      id: uuid,
      isDefault: PropTypes.bool,
      large: PropTypes.string,
      original: PropTypes.string,
      small: PropTypes.string,
    }),
    user: neighbor,
    permissions: PropTypes.shape({
      edit: PropTypes.bool,
      cancel: PropTypes.bool,
      reply: PropTypes.bool,
      share: PropTypes.bool,
    }),
  }),
  comment: PropTypes.shape({
    id: PropTypes.string,
    comment: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    model: PropTypes.shape({
      type: PropTypes.string,
      id: uuid,
    }),
    user: neighbor,
    permissions: PropTypes.shape({
      delete: PropTypes.bool,
    }),
  }),
  survey: PropTypes.shape({
    id: uuid,
    title: PropTypes.string,
    content: PropTypes.string,
    type: PropTypes.string,
    expiresAt: PropTypes.string,
    answerEditable: PropTypes.bool,
    realtimeResults: PropTypes.bool,
    singleChoice: PropTypes.bool,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        id: uuid,
        name: PropTypes.string,
        image: PropTypes.shape({
          id: uuid,
          original: PropTypes.string,
          small: PropTypes.string,
          large: PropTypes.string,
        }),
      })
    ),
    results: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          choiceId: uuid,
          count: PropTypes.number,
          average: PropTypes.number,
        })
      ),
      PropTypes.number,
    ]),
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        rating: PropTypes.number,
        choices: PropTypes.arrayOf(uuid),
        user: neighbor,
      })
    ),
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    permissions: PropTypes.shape({
      share: PropTypes.bool,
      answer: PropTypes.bool,
      seeAnswers: PropTypes.bool,
      seeResults: PropTypes.bool,
      delete: PropTypes.bool,
    }),
  }),
  notification: PropTypes.shape({
    id: uuid,
    type: PropTypes.string,
    message: PropTypes.string,
    sender: neighbor,
    link: PropTypes.string,
    readAt: PropTypes.string,
    openAt: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

export default CustomPropTypes;
