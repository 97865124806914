import { Flex, View, Skeleton } from '@ui/atoms';
import { Panel } from '@ui/molecules';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';

function PostCardSkeleton({ variant, sx, borderless }) {
  const Root = borderless ? View : Panel;

  return (
    <Root sx={sx}>
      {'document' === variant ? (
        <Skeleton width="80%" height={18} sx={{ mb: '$2' }} />
      ) : (
        <View sx={{ mb: '$5' }}>
          <Flex alignItems="center" wrap sx={{ mb: '$2' }}>
            <Skeleton width={70} height={26} radius={13} sx={{ mr: '$2', mb: '$2' }} />
            <Skeleton width={90} height={26} radius={13} sx={{ mb: '$2' }} />
          </Flex>
          <Flex alignItems="center">
            <Skeleton height={50} width={50} radius={25} />
            <View sx={{ ml: '$2' }}>
              <Skeleton width={140} height={14} sx={{ mb: '$1' }} />
              <Skeleton width={90} height={14} />
            </View>
          </Flex>
        </View>
      )}
      <View sx={{ width: '100%' }}>
        <Skeleton width={200} height={18} />
        <Skeleton width="100%" height={70} sx={{ mt: '$2', mb: '$5' }} />
      </View>
      <Flex>
        <Skeleton width={50} height={30} sx={{ mr: '$2' }} />
        <Skeleton width={50} height={30} />
      </Flex>
      <View sx={{ width: '100%', height: 1, bg: '$primary.main', opacity: 0.5, my: '$3' }} />
      <Flex wrap>
        <Skeleton width={100} height={30} sx={{ mr: '$2' }} />
        <Skeleton width={100} height={30} />
      </Flex>
      <Flex alignItems="center" sx={{ mt: '$3' }}>
        <Skeleton height={32} width={32} radius={16} sx={{ mr: '$4' }} />
        <View sx={{ flex: 1 }}>
          <Skeleton height={48} width="100%" />
        </View>
      </Flex>
    </Root>
  );
}

PostCardSkeleton.propTypes = {
  variant: PropTypes.oneOf(['classic', 'document']),
  sx: CustomPropTypes.style,
  borderless: PropTypes.bool,
};

PostCardSkeleton.defaultProps = {
  variant: 'classic',
  sx: undefined,
  borderless: false,
};

export default PostCardSkeleton;
