import { useSx } from 'dripsy';
import NativeIonicons from '@expo/vector-icons/Ionicons';
import CustomPropTypes from '@utils/CustomPropTypes';

function Ionicons({ sx = undefined, ...props }) {
  const sxFactory = useSx();

  return <NativeIonicons {...props} style={sx ? sxFactory(sx) : undefined} />;
}

Ionicons.propTypes = {
  sx: CustomPropTypes.style,
};

export default Ionicons;
