import useTranslate from '@hooks/useTranslate';
import Survey from '@ui/organisms/Survey';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import SCREENS from '@constants/Screens';
import Screen from '@ui/templates/Screen';
import { useNavigation } from '@react-navigation/native';

function SurveyScreen({ route }) {
  const { t } = useTranslate();
  const { goBack } = useNavigation();

  return (
    <Screen title={t('SurveyScreen.title')} presentation={SurveyScreen.presentation}>
      <Survey id={route.params.id} onNotFoundError={() => goBack()} visible borderless />
    </Screen>
  );
}

SurveyScreen.presentation = { default: 'card', web: 'modal' };
SurveyScreen.screenName = SCREENS.survey;

SurveyScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      id: CustomPropTypes.uuid.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SurveyScreen;
