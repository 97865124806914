import create from 'zustand';

const useSocketStore = create((set) => ({
  socketId: undefined,
  setSocketId: (socketId) => set({ socketId }),

  echo: undefined,
  setEcho: (instance) => set({ echo: instance }),

  clear: () => set({ socketId: undefined }),
}));

export const useSocketId = () => useSocketStore((state) => state.socketId);

export const useEcho = () => useSocketStore((state) => state.echo);

export default useSocketStore;
