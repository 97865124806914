import { Flex, Text } from '@ui/atoms';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useThemeColor from '@hooks/useThemeColor';
import PropTypes from 'prop-types';
import { Platform, TouchableOpacity } from 'react-native';
import { FormattedMessage } from 'react-intl';

function TabBar({ state, descriptors, navigation }) {
  const insets = useSafeAreaInsets();
  const activeColor = useThemeColor('$primary.main');
  const inactiveColor = useThemeColor('$grey.600');

  return (
    <Flex
      sx={{
        backgroundColor: '$grey.100',
        height: 'android' === Platform.OS ? 60 + insets.bottom : 70 + insets.bottom,
        pt: '$3',
        pb: insets.bottom || '$4',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'sm',
        shadowOffset: {
          width: 0,
          height: -2,
        },
      }}
    >
      {state.routes
        .filter((route) => !descriptors[route.key]?.options?.tabBarHidden)
        .map((route, index) => {
          const { options } = descriptors[route.key];

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name);
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };

          const Icon = options.tabBarIcon;
          const isFocused = state.index === index;

          return (
            <TouchableOpacity
              key={route.key}
              accessibilityRole="button"
              accessibilityStates={isFocused ? ['selected'] : []}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={{ flex: 1, alignItems: 'center' }}
            >
              <Icon width={28} height={28} color={isFocused ? activeColor : inactiveColor} />
              <Text style={{ color: isFocused ? activeColor : inactiveColor, fontSize: 9 }}>
                <FormattedMessage id={options.tabBarLabel} />
              </Text>
            </TouchableOpacity>
          );
        })}
    </Flex>
  );
}

TabBar.propTypes = {
  state: PropTypes.shape({
    index: PropTypes.number,
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  descriptors: PropTypes.object.isRequired,
  navigation: PropTypes.shape({
    emit: PropTypes.func,
    navigate: PropTypes.func,
  }).isRequired,
};

export default TabBar;
