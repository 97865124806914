import useTranslate from '@hooks/useTranslate';
import useEventQuery from '@hooks/queries/useEventQuery';
import useUpdateEventMutation from '@hooks/mutations/useUpdateEventMutation';
import { useNavigation } from '@react-navigation/native';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import Screen from '@ui/templates/Screen';
import EventForm from '@ui/organisms/EventForm';
import SCREENS from '@constants/Screens';
import { ActivityIndicator, View } from '@ui/atoms';
import useThemeColor from '@hooks/useThemeColor';

function EditEventScreen({ route }) {
  const { t } = useTranslate();
  const { id } = route.params;
  const { goBack } = useNavigation();
  const primaryColor = useThemeColor('$primary.main');

  const { data: event, isLoading } = useEventQuery(id);

  const { mutateAsync: updateEvent } = useUpdateEventMutation(id);

  const handleSubmit = async (data) => {
    await updateEvent(data);
    goBack();
  };

  return (
    <Screen title={t('EditEventScreen.title')} presentation={EditEventScreen.presentation} scrollable>
      {isLoading ? (
        <View
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: '$8',
          }}
        >
          <ActivityIndicator size="large" color={primaryColor} />
        </View>
      ) : (
        <EventForm event={event} onSubmit={handleSubmit} />
      )}
    </Screen>
  );
}

EditEventScreen.presentation = 'modal';
EditEventScreen.screenName = SCREENS.editEvent;

EditEventScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      id: CustomPropTypes.uuid.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EditEventScreen;
