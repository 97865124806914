import { useMemo } from 'react';
import { Pressable, Text, UserAvatar, View } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import { groupBy } from 'lodash';
import { Rating } from '@ui/molecules';
import CustomPropTypes from '@utils/CustomPropTypes';
import useOpenUserProfile from '@hooks/useOpenUserProfile';

function RatingAnswers({ survey }) {
  const openUserProfile = useOpenUserProfile();

  const groupedByRating = useMemo(() => groupBy(survey.answers, 'rating'), [survey.answers]);

  return (
    <View>
      <View sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Rating size={40} value={survey.rating} />
        <Text variant="subtitle2" sx={{ color: '$primary.main' }}>
          <FormattedMessage id="ui.SurveyAnswers.RatingAnswers.rating" values={{ rating: survey.rating.toFixed(1) }} />
        </Text>
      </View>
      <View sx={{ mt: '$2' }}>
        {Object.values(groupedByRating).map((answers) => {
          return (
            <View key={answers[0].rating} sx={{ mt: '$4' }}>
              <Text sx={{ mb: '$2' }} variant="subtitle2">
                <FormattedMessage
                  id="ui.SurveyAnswers.RatingAnswers.groupTitle"
                  values={{ rating: survey.rating.toFixed(1), count: answers.length }}
                />
              </Text>
              {answers.map((answer) => (
                <Pressable
                  onPress={() => openUserProfile(answer.user.id)}
                  sx={{ flexDirection: 'row', alignItems: 'center', my: '$1' }}
                  key={answer.user.id}
                >
                  <UserAvatar user={answer.user} size="medium" />
                  <Text sx={{ ml: '$4' }} variant="subtitle">
                    {answer.user.fullname}
                  </Text>
                </Pressable>
              ))}
            </View>
          );
        })}
      </View>
    </View>
  );
}

RatingAnswers.propTypes = {
  survey: CustomPropTypes.survey.isRequired,
};

export default RatingAnswers;
