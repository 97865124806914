
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({
  "replaceAttrValues": {
    "#000": "{props.color}"
  }
});
        import { ActivityIndicator, AutoHeightInput, Flex, Pressable, UserAvatar } from '@ui/atoms';
import useTranslate from '@hooks/useTranslate';
import { useState } from 'react';

  const PaperPlane = __ReactNativeSvgLoader(import('@src/assets/icons/paperPlaneRight.svg'));
  ;
import useThemeColor from '@hooks/useThemeColor';
import CustomPropTypes from '@utils/CustomPropTypes';
import useCommentMutation from '@hooks/mutations/useCommentMutation';
import useToast from '@hooks/useToast';
import { Keyboard, Platform } from 'react-native';
import PropTypes from 'prop-types';
import useMeQuery from '@hooks/queries/useMeQuery';
import COMMENTABLE_MODEL from '@constants/CommentableModel';
import MAX_LENGTH from '@constants/MaxLength';

function AddComment({ model, inputRef, onFocus, sx }) {
  const { t } = useTranslate();
  const primaryColor = useThemeColor('$primary.main');
  const toast = useToast();

  const { data: me } = useMeQuery();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [height, setHeight] = useState('auto');

  const { mutateAsync } = useCommentMutation();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await mutateAsync({ comment: value, model });

      if ('ios' === Platform.OS) {
        // Autocorrect on IOS will re-set the value if we clear before keyboard closed.
        setTimeout(() => setValue(''), 300);
      } else {
        setValue('');
      }
    } catch (err) {
      toast.error(t('errors.api.default'));
    } finally {
      setLoading(false);
      Keyboard.dismiss();
    }
  };

  return (
    <Flex alignItems="center" sx={sx}>
      <UserAvatar sx={{ mr: '$4' }} size="medium" user={me} />
      <AutoHeightInput
        onFocus={onFocus}
        color="grey"
        maxHeight={100}
        value={value}
        onChangeText={(val) => setValue(val)}
        placeholder={t('ui.AddComment.comment')}
        viewProps={{ sx: { flex: 1 } }}
        ref={inputRef}
        onHeightChange={setHeight}
        maxLength={MAX_LENGTH.comment}
        endIcon={loading ? <ActivityIndicator color={primaryColor} /> : null}
        withCharCounter
      />
      <Pressable
        onPress={handleSubmit}
        disabled={0 === value.length}
        sx={{ height, justifyContent: 'center', mr: '$1', ml: '$1' }}
      >
        <PaperPlane color={primaryColor} height={24} width={24} />
      </Pressable>
    </Flex>
  );
}

AddComment.propTypes = {
  model: PropTypes.exact({
    type: PropTypes.oneOf(Object.values(COMMENTABLE_MODEL)),
    id: CustomPropTypes.uuid,
  }).isRequired,
  inputRef: CustomPropTypes.ref,
  onFocus: PropTypes.func,
  sx: CustomPropTypes.style,
};

AddComment.defaultProps = {
  onFocus: undefined,
  inputRef: undefined,
  sx: undefined,
};

export default AddComment;

        