import PropTypes from 'prop-types';
import ResidenceSelectorRow from '@ui/molecules/ResidenceSelector/ResidenceSelectorRow';
import { FlatList } from '@ui/atoms';
import CustomPropTypes from '@utils/CustomPropTypes';

function ResidenceSelector({ residences, onPress }) {
  return (
    <FlatList
      data={residences}
      sx={{ flexGrow: 0 }}
      renderItem={({ item, index }) => (
        <ResidenceSelectorRow
          onPress={onPress}
          key={item.id}
          residence={item}
          sx={{
            p: '$4',
            borderBottomWidth: index === residences.length - 1 ? 0 : 1,
            borderBottomColor: '$grey.200',
          }}
        />
      )}
      keyExtractor={(item) => item.id}
    />
  );
}

ResidenceSelector.propTypes = {
  residences: PropTypes.arrayOf(
    PropTypes.shape({
      id: CustomPropTypes.uuid,
      name: PropTypes.string,
      logo: PropTypes.shape({
        original: PropTypes.string,
        small: PropTypes.string,
      }),
    })
  ).isRequired,
  onPress: PropTypes.func.isRequired,
};

export default ResidenceSelector;
