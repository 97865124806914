/* eslint-disable react-hooks/exhaustive-deps */
import { Ionicons, Text, View } from '@ui/atoms';
import useTranslate from '@hooks/useTranslate';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import useReplyEventMutation from '@hooks/mutations/useReplyEventMutation';
import { Dropdown } from '@ui/templates';
import CustomPropTypes from '@utils/CustomPropTypes';

function ReplyEvent({ id, currentReply, disabled, sx }) {
  const { t } = useTranslate();

  const { mutate } = useReplyEventMutation(id);

  const selected = useMemo(() => {
    if (true === currentReply) {
      return {
        backgroundColor: '$primary.main',
        color: '$common.white',
        title: t('ui.ReplyEvent.accept'),
        icon: <Ionicons name="checkmark-sharp" size={18} sx={{ color: '$common.white' }} />,
      };
    }

    if (false === currentReply) {
      return {
        backgroundColor: '$red.dark',
        color: '$common.white',
        title: t('ui.ReplyEvent.refuse'),
        icon: <Ionicons name="close" size={18} sx={{ color: '$common.white' }} />,
      };
    }

    return { backgroundColor: '$grey.200', color: '$grey.600', title: t('common.verb.answer') };
  }, [currentReply]);

  const handleAccept = () => mutate({ reply: true });

  const handleRefuse = () => mutate({ reply: false });

  return (
    <Dropdown
      width={250}
      placement="bottom-end"
      offset={10}
      disabled={disabled}
      options={[
        {
          icon: <Ionicons name="checkmark-sharp" size={18} />,
          label: t('ui.ReplyEvent.accept'),
          onPress: handleAccept,
        },
        {
          icon: <Ionicons name="close" size={18} />,
          label: t('ui.ReplyEvent.refuse'),
          onPress: handleRefuse,
        },
      ]}
    >
      <View
        sx={{
          px: '$2',
          backgroundColor: selected.backgroundColor,
          py: '$2',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 6,
          ...sx,
        }}
      >
        {selected.icon}
        <Text sx={{ ml: '$1', color: selected.color, fontSize: 12 }}>{selected.title}</Text>
        {disabled || <Ionicons name="chevron-down-outline" size={14} sx={{ ml: '$4', color: selected.color }} />}
      </View>
    </Dropdown>
  );
}

ReplyEvent.propTypes = {
  id: PropTypes.string.isRequired,
  currentReply: PropTypes.bool,
  disabled: PropTypes.bool,
  sx: CustomPropTypes.style,
};

ReplyEvent.defaultProps = {
  currentReply: undefined,
  disabled: false,
  sx: undefined,
};

export default ReplyEvent;
