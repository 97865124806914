/* eslint-disable react-native/no-raw-text */
import { Button, Image, Text, View } from '@ui/atoms';
import { Linking, Platform, StyleSheet } from 'react-native';
import Constants from 'expo-constants';
import newIdentityBackground from '@src/assets/hmh-kunagi-background.jpg';
import newIdentityLogo from '@src/assets/hmh-kunagi.png';
import PropTypes from 'prop-types';

function NewIdentity({ children }) {
  const [majorVersion] = Constants.expoConfig.version.split('.');

  if ('2' !== majorVersion && 'web' !== Platform.OS) {
    const openAppStore = () => {
      const link = Platform.select({
        ios: 'itms-apps://apps.apple.com/tr/app/home-meet-home/id1626866830',
        android: 'market://details?id=id=fr.homemeethome.app',
        web: 'https://app.kunagi.fr',
      });

      if ('web' === Platform.OS) {
        window.location.href = link;
      } else {
        Linking.openURL(link);
      }
    };

    return (
      <View sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Image
          style={{
            ...StyleSheet.absoluteFill,
            height: '100%',
            width: '100%',
            resizeMode: 'stretch',
          }}
          source={newIdentityBackground}
        />
        <View sx={{ position: 'relative' }}>
          <Image source={newIdentityLogo} sx={{ mb: '$4', width: 300, color: '#263237', height: 138, ml: '$4' }} />
          <Text sx={{ fontSize: 26, textAlign: 'center' }}>
            Home Meet Home devient{'\n'}
            <Text sx={{ fontSize: 48, fontWeight: 'semibold', color: '#263237' }}>Kunagi</Text>
          </Text>
          <Button onPress={openAppStore} color="primary" sx={{ mt: '$8' }} title="Mettre à jour l'application" />
        </View>
      </View>
    );
  }

  return children;
}

NewIdentity.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default NewIdentity;
