import useApiMutation from './useApiMutation';

function useUpdatePasswordMutation(options) {
  return useApiMutation('put', `/v1/me/password`, {
    ...options,
    onSuccess: (res, ...rest) => {
      if ('function' === typeof options?.onSuccess) {
        options.onSuccess(res, ...rest);
      }
    },
  });
}

export default useUpdatePasswordMutation;
