
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({
  "replaceAttrValues": {
    "#000": "{props.color}"
  }
});
        import { AutoHeightInput, Button, Input, Ionicons, Text, View } from '@ui/atoms';
import AvatarPicker from '@ui/organisms/AvatarPicker';
import userPlaceholder from '@src/assets/user-placeholder.jpg';
import { FormControl, Panel } from '@ui/molecules';
import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from '@utils/yup';
import apiErrorsToFormik from '@utils/apiErrorsToFormik';
import useMeQuery from '@hooks/queries/useMeQuery';
import useProfileQuery from '@hooks/queries/useProfileQuery';
import useUpdateMeMutation from '@hooks/mutations/useUpdateMeMutation';
import useUpdateProfileMutation from '@hooks/mutations/useUpdateProfileMutation';
import useUploadMutation from '@hooks/mutations/useUploadMutation';
import useToast from '@hooks/useToast';
import useTranslate from '@hooks/useTranslate';

  const PencilIcon = __ReactNativeSvgLoader(import('@src/assets/icons/pencil.svg'));
  ;
import useThemeColor from '@hooks/useThemeColor';

function ProfileForm() {
  const toast = useToast();
  const { t } = useTranslate();
  const whiteColor = useThemeColor('$common.white');

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const { data: user } = useMeQuery();
  const { data: profile } = useProfileQuery();

  const { mutateAsync: updateMeAsync } = useUpdateMeMutation();
  const { mutateAsync: updateProfileAsync } = useUpdateProfileMutation();
  const { mutateAsync: uploadAsync } = useUploadMutation();

  const form = useFormik({
    initialValues: {
      flatNumber: profile.flatNumber || '',
      description: profile.description || '',
    },
    validationSchema: object({
      flatNumber: string().nullable(),
      description: string().nullable().max(240),
    }),
    onSubmit: async (data, { setErrors }) => {
      try {
        setLoading(true);

        await updateProfileAsync({
          description: data.description,
          flatNumber: data.flatNumber,
        });

        toast.success(t('ui.ProfileForm.success'));
      } catch (err) {
        if (422 === err.response?.status) {
          apiErrorsToFormik(err.response.data.errors, setErrors);
          toast.error(t('errors.checkData'));
        } else {
          toast.error(t('errors.api.default'));
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const handleUpload = useCallback(
    async (file) => {
      try {
        setUploading(true);

        // eslint-disable-next-line no-param-reassign
        file.name = 'avatar.jpg';

        const { data } = await uploadAsync(file);
        await updateMeAsync({
          avatar: data.id,
        });
      } catch (err) {
        if (422 === err.response?.status) {
          toast.error(err.response.data.errors?.file[0]);
        } else {
          toast.error(t('errors.api.default'));
        }
      } finally {
        setUploading(false);
      }
    },
    [uploadAsync, updateMeAsync, toast, t]
  );

  return (
    <Panel
      sx={{
        p: ['$4', null, '$6'],
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '$2',
      }}
    >
      <AvatarPicker
        onChange={handleUpload}
        avatar={user.avatar?.small || userPlaceholder}
        loading={uploading}
        color="black"
        size={90}
        icon={<PencilIcon width={11} height={11} color={whiteColor} />}
      />
      <View sx={{ width: '100%' }}>
        <View sx={{ mt: '$5', justifyContent: 'center' }}>
          <Text variant="subtitle" sx={{ textAlign: 'center' }}>{`${user.firstname} ${user.lastname}`}</Text>
          <Text variant="small" sx={{ textAlign: 'center' }}>
            {user.email}
          </Text>
        </View>
        <FormControl
          label={t('ui.ProfileForm.flatNumber')}
          sx={{ mt: '$5' }}
          error={form.touched.flatNumber && form.errors.flatNumber}
        >
          <Input
            value={form.values.flatNumber}
            onChangeText={form.handleChange('flatNumber')}
            onBlur={form.handleBlur('flatNumber')}
          />
        </FormControl>
        <FormControl
          label={t('ui.ProfileForm.description')}
          sx={{ mt: '$5' }}
          error={form.touched.description && form.errors.description}
        >
          <AutoHeightInput
            minHeight={100}
            maxLength={240}
            value={form.values.description}
            onChangeText={form.handleChange('description')}
            onBlur={form.handleBlur('description')}
          />
        </FormControl>
        <View sx={{ mt: '$5' }}>
          <Button
            color="primary"
            onPress={form.submitForm}
            loading={loading}
            title={t('common.verb.save')}
            startIcon={<Ionicons name="checkmark" size={24} sx={{ color: '$common.white' }} />}
          />
        </View>
      </View>
    </Panel>
  );
}

export default ProfileForm;

        