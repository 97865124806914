import { useCallback, useState } from 'react';
import { View } from '@ui/atoms';
import { GuestLayout } from '@ui/layouts';
import SCREENS from '@constants/Screens';
import useTranslate from '@hooks/useTranslate';
import { useFormik } from 'formik';
import { password, string, ref, object } from '@utils/yup';
import apiErrorsToFormik from '@utils/apiErrorsToFormik';
import useToast from '@hooks/useToast';
import useRegisterMutation from '@hooks/mutations/useRegisterMutation';
import { RegisterForm } from '@ui/organisms';
import mapObject from '@utils/mapObject';
import useUploadMutation from '@hooks/mutations/useUploadMutation';
import useLoginMutation from '@hooks/mutations/useLoginMutation';
import useAuthStore from '@src/stores/authStore';
import { deviceName } from 'expo-device';
import PropTypes from 'prop-types';

function RegisterScreen({ route: { params } }) {
  const { t } = useTranslate();
  const toast = useToast();

  const { setToken } = useAuthStore();

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const { mutateAsync } = useRegisterMutation();
  const { mutateAsync: loginAsync } = useLoginMutation();
  const { mutateAsync: uploadAsync } = useUploadMutation();

  const form = useFormik({
    initialValues: {
      joinCode: params?.code || '',
      firstname: params?.firstname || '',
      lastname: params?.lastname || '',
      email: params?.email || '',
      password: '',
      passwordConfirmation: '',
      avatar: undefined,
    },
    validationSchema: object({
      joinCode: string().required(),
      firstname: string().min(2).required(),
      lastname: string().min(2).required(),
      email: string().email().required(),
      password: password().required(),
      passwordConfirmation: string()
        .required({ key: 'errors.validation.passwordConfirmation' })
        .oneOf([ref('password'), null], { key: 'errors.validation.passwordConfirmation' }),
    }),
    onSubmit: async (data, { setErrors }) => {
      try {
        setLoading(true);

        await mutateAsync({
          ...data,
          avatar: data.avatar?.id,
        });

        const res = await loginAsync({
          ...data,
          deviceName: deviceName || navigator.userAgent,
        });

        await setToken(res.data.token);
      } catch (err) {
        if (422 === err.response?.status) {
          apiErrorsToFormik(err.response.data.errors, setErrors);
          if (err.response.data.errors.joinCode) {
            toast.error(t('RegisterScreen.invalidCode'));
          } else {
            toast.error(t('errors.checkData'));
          }
        } else if (429 === err.response?.status) {
          toast.error(t('errors.api.throttle'));
        } else {
          toast.error(t('errors.api.default'));
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const { setFieldValue } = form;
  const handleUpload = useCallback(
    async (file, name) => {
      try {
        setUploading(true);

        // eslint-disable-next-line no-param-reassign
        file.name = 'avatar.jpg';

        const { data } = await uploadAsync(file);
        setFieldValue(name, data);
      } catch (err) {
        if (422 === err.response?.status) {
          toast.error(err.response.data.errors?.file[0]);
        } else {
          toast.error(t('errors.api.default'));
        }
      } finally {
        setUploading(false);
      }
    },
    [uploadAsync, setFieldValue, toast, t]
  );

  const handleSubmit = () => {
    // Touch all field to show errors
    form.setTouched(mapObject(form.values, (key) => [key, true]));

    if (0 < Object.keys(form.errors).length) {
      toast.error(t('errors.checkData'));
    } else {
      form.submitForm();
    }
  };

  return (
    <GuestLayout scrollable>
      <View sx={{ pb: '$10' }}>
        <RegisterForm
          form={form}
          onSubmit={handleSubmit}
          loading={loading}
          uploading={uploading}
          onFileChange={handleUpload}
          withoutJoinCode={!!params?.code}
        />
      </View>
    </GuestLayout>
  );
}

RegisterScreen.screenName = SCREENS.register;
RegisterScreen.visibility = 'guest';

RegisterScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
};

export default RegisterScreen;
