/* eslint-disable no-bitwise, no-nested-ternary */
function lighten(color, percentage) {
  const n = parseInt(color.slice(1), 16);
  const a = Math.round(2.55 * percentage);
  const r = (n >> 16) + a;
  const b = ((n >> 8) & 0x00ff) + a;
  const g = (n & 0x0000ff) + a;

  return `#${(
    0x1000000 +
    (255 > r ? (1 > r ? 0 : r) : 255) * 0x10000 +
    (255 > b ? (1 > b ? 0 : b) : 255) * 0x100 +
    (255 > g ? (1 > g ? 0 : g) : 255)
  )
    .toString(16)
    .slice(1)}`;
}

export default lighten;
