import useTranslate from '@hooks/useTranslate';
import { SurveyForm } from '@ui/organisms';
import SCREENS from '@constants/Screens';
import Screen from '@ui/templates/Screen';
import { useNavigation } from '@react-navigation/native';
import useToast from '@hooks/useToast';

function CreateSurveyScreen() {
  const toast = useToast();
  const { t } = useTranslate();
  const { navigate, goBack } = useNavigation();

  const handleSuccess = (survey) => {
    toast.success(t('CreateSurveyScreen.success'));
    goBack();
    navigate(SCREENS.survey, { id: survey.id });
  };

  return (
    <Screen title={t('CreateSurveyScreen.title')} presentation={CreateSurveyScreen.presentation} scrollable>
      <SurveyForm onSuccess={handleSuccess} />
    </Screen>
  );
}

CreateSurveyScreen.presentation = 'modal';
CreateSurveyScreen.screenName = SCREENS.createSurvey;

export default CreateSurveyScreen;
