/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextInput as NativeInput } from 'react-native';
import { styled } from 'dripsy';
import PropTypes from 'prop-types';
import useThemeColor from '@hooks/useThemeColor';
import View from '@ui/atoms/View';
import CustomPropTypes from '@utils/CustomPropTypes';
import Text from '@ui/atoms/Text';

const DripsyInput = styled(NativeInput, {
  themeKey: 'input',
  defaultVariants: ['white'],
})();

export const SIZES = {
  small: 32,
  medium: 48,
};

const Input = React.forwardRef(
  (
    {
      variant,
      color,
      size,
      error,
      multiline,
      maxLength,
      value,
      startIcon,
      endIcon,
      viewProps,
      sx,
      withCharCounter,
      ...props
    },
    ref
  ) => {
    const placeholderTextColor = useThemeColor('$grey.700');
    const { sx: viewPropsSx, ...restViewProps } = viewProps;

    return (
      <View sx={{ position: 'relative', ...viewPropsSx }} {...restViewProps}>
        <DripsyInput
          variants={['base', variant, color, error && 'error', multiline && 'multiline'].filter((v) => v)}
          sx={{
            minHeight: SIZES[size],
            ...(startIcon && { pl: SIZES[size] }),
            ...(endIcon && { pr: SIZES[size] }),
            ...sx,
          }}
          multiline={multiline}
          textAlignVertical={multiline ? 'top' : undefined}
          placeholderTextColor={placeholderTextColor}
          ref={ref}
          value={value}
          maxLength={maxLength}
          {...props}
        />
        {startIcon && (
          <View
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              minHeight: SIZES[size],
              width: SIZES[size],
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {startIcon}
          </View>
        )}
        {endIcon && (
          <View
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              minHeight: SIZES[size],
              width: SIZES[size],
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {endIcon}
          </View>
        )}
        {withCharCounter && (
          <Text sx={{ color: '$grey.600', fontSize: 10, right: 5, position: 'absolute', bottom: -20 }}>
            {maxLength ? `${value.length}/${maxLength}` : value.length}
          </Text>
        )}
      </View>
    );
  }
);

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  variant: PropTypes.oneOf(['contained']),
  color: PropTypes.oneOf(['white', 'grey']),
  size: PropTypes.oneOf(['medium', 'small']),
  multiline: PropTypes.bool,
  error: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  viewProps: PropTypes.object,
  sx: CustomPropTypes.style,
  withCharCounter: PropTypes.bool,
  maxLength: PropTypes.number,
};

Input.defaultProps = {
  variant: 'contained',
  color: 'white',
  size: 'medium',
  multiline: false,
  error: false,
  viewProps: Object.freeze({}),
  startIcon: undefined,
  endIcon: undefined,
  sx: undefined,
  maxLength: undefined,
  withCharCounter: false,
};

export default Input;
