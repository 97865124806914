import { useQuery } from 'react-query';
import QUERY_CACHE_KEY from '@src/constants/QueryCacheKey';

function useNeighborQuery(userId, options) {
  return useQuery(QUERY_CACHE_KEY.neighbor(userId), {
    ...options,
  });
}

export default useNeighborQuery;
