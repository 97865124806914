import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';

export const FeedPropTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: CustomPropTypes.uuid,
    })
  ).isRequired,
  empty: PropTypes.node.isRequired,
  header: PropTypes.node,
  sx: CustomPropTypes.style,

  // Not supported on web
  contentContainerSx: CustomPropTypes.style,
  refreshing: PropTypes.bool,
  onRefresh: PropTypes.func,
};

export const FeedDefaultProps = {
  header: null,
  sx: undefined,
  contentContainerSx: undefined,
  refreshing: false,
  onRefresh: undefined,
};
