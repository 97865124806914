import flatten from 'flat';
import common from './common.json';
import ui from './ui';
import constants from './constants.json';
import errors from './errors.json';
import screens from './screens';
import hooks from './hooks.json';

export default flatten({
  common,
  constants,
  ui,
  errors,
  hooks,
  ...screens,
});
