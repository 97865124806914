import get from 'lodash/get';
import { useDripsyTheme } from 'dripsy';

function useThemeColor(key) {
  const { theme } = useDripsyTheme();

  return get(theme, `colors.${key}`, key);
}

export default useThemeColor;
