import File from '@ui/atoms/File';
import * as ExpoDocumentPicker from 'expo-document-picker';
import PropTypes from 'prop-types';
import useTranslate from '@hooks/useTranslate';
import CustomPropTypes from '@utils/CustomPropTypes';
import Pressable from '@ui/atoms/Pressable';
import Ionicons from '@ui/atoms/Ionicons';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import mime from 'mime';

function FilePicker({ file, onChange, loading, error, mimeTypes, color, onDelete, disabled }) {
  const { t } = useTranslate();

  const handlePress = async () => {
    const result = await ExpoDocumentPicker.getDocumentAsync({
      type: mimeTypes,
    });

    if ('success' === result.type) {
      onChange(result.file || { uri: result.uri, type: mime.getType(result.uri), name: result.name });
    }
  };

  return (
    <>
      <File
        label={loading ? t('common.loading') : file?.name ?? t('ui.FilePicker.import')}
        onPress={disabled ? undefined : handlePress}
        error={error}
        color={color}
      />
      {'function' === typeof onDelete && file && !disabled && (
        <Pressable onPress={() => onDelete(file)} sx={{ flexDirection: 'row', mt: '$2', pl: '$1' }}>
          <Ionicons name="trash" size={20} sx={{ color: '$red.main' }} />
          <Text variant="body2" sx={{ flex: 1, ml: '$2', color: '$red.main' }} numberOfLines={1}>
            <FormattedMessage id="ui.FilePicker.delete" />
          </Text>
        </Pressable>
      )}
    </>
  );
}

FilePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  mimeTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  file: CustomPropTypes.file,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  onDelete: PropTypes.func,
};

FilePicker.defaultProps = {
  mimeTypes: undefined,
  file: undefined,
  loading: false,
  error: false,
  disabled: false,
  color: undefined,
  onDelete: undefined,
};

export default FilePicker;
