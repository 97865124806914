import { Skeleton, Text, View } from '@ui/atoms';
import useResourcesQuery from '@hooks/queries/useResourcesQuery';
import SCREENS from '@constants/Screens';
import { Screen } from '@ui/templates';
import useTranslate from '@hooks/useTranslate';
import { Panel } from '@ui/molecules';
import useWebView from '@hooks/useWebView';
import { FormattedMessage } from 'react-intl';
import { LinkPreview } from '@ui/organisms';

function ResourcesScreen() {
  const { t } = useTranslate();
  const openWebView = useWebView();

  const { data, isLoading } = useResourcesQuery();

  return (
    <Screen
      title={t('ResourcesScreen.title')}
      presentation={ResourcesScreen.presentation}
      sx={{ maxWidth: 900, mx: 'auto', width: '100%' }}
      scrollable
    >
      <View>
        {isLoading ? (
          <Panel sx={{ width: '100%', mb: '$6' }}>
            <Skeleton width={320} height={14} />
            <View sx={{ my: '$2' }}>
              <Skeleton width="100%" height={14} sx={{ mb: '$1' }} />
              <Skeleton width="80%" height={14} />
            </View>
            <Skeleton width={200} height={14} />
            <View
              sx={{
                maxWidth: 500,
                borderRadius: '$2',
                bg: '$grey.100',
                borderColor: '$grey.300',
                overflow: 'hidden',
                borderWidth: 1,
                mt: '$4',
              }}
            >
              <Skeleton width="100%" height={240} radius={0} />
              <View sx={{ p: '$4' }}>
                <Skeleton width={200} height={14} />
                <Skeleton width={100} height={14} sx={{ mt: '$1' }} />
              </View>
            </View>
          </Panel>
        ) : (
          <View>
            {0 < data.items.length ? (
              data.items.map((resource) => (
                <Panel key={resource.id} sx={{ width: '100%', mb: '$6' }}>
                  <Text variant="subtitle" sx={{ color: '$primary.main' }}>
                    <FormattedMessage
                      id="ResourcesScreen.item.date"
                      values={{ createdAt: new Date(resource.createdAt) }}
                    />
                  </Text>
                  <Text sx={{ my: '$2' }}>{resource.description}</Text>
                  <Text
                    onPress={() => openWebView(resource.title, resource.url)}
                    sx={{ color: '$primary.main', textDecorationLine: 'underline', textDecorationStyle: 'solid' }}
                  >
                    {resource.url}
                  </Text>
                  {resource.metadata && (
                    <LinkPreview metadata={resource.metadata} url={resource.url} sx={{ mt: '$4' }} />
                  )}
                </Panel>
              ))
            ) : (
              <Text variant="subtitle" sx={{ textAlign: 'center', lineHeight: 25, p: '$5' }}>
                <FormattedMessage id="ResourcesScreen.empty" />
              </Text>
            )}
          </View>
        )}
      </View>
    </Screen>
  );
}

ResourcesScreen.presentation = 'card';
ResourcesScreen.screenName = SCREENS.resources;

export default ResourcesScreen;
