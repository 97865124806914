import { Button, Input, Text, View } from '@ui/atoms';
import { FormControl } from '@ui/molecules';
import useTranslate from '@hooks/useTranslate';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { object, password, ref, string } from '@utils/yup';
import { deviceName } from 'expo-device';
import apiErrorsToFormik from '@utils/apiErrorsToFormik';
import useResetPasswordMutation from '@hooks/mutations/useResetPasswordMutation';
import useLoginMutation from '@hooks/mutations/useLoginMutation';
import useAuthStore from '@src/stores/authStore';
import useToast from '@hooks/useToast';
import { Platform } from 'react-native';
import { useState } from 'react';

function ResetStep({ email, code }) {
  const { t } = useTranslate();
  const toast = useToast();

  const { setToken } = useAuthStore();

  const [loading, setLoading] = useState(false);

  const { mutateAsync: loginAsync } = useLoginMutation();
  const { mutateAsync } = useResetPasswordMutation();

  const form = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: object({
      password: password().required(),
      passwordConfirmation: string()
        .required({ key: 'errors.validation.passwordConfirmation' })
        .oneOf([ref('password'), null], { key: 'errors.validation.passwordConfirmation' }),
    }),
    onSubmit: async (data, { setErrors }) => {
      try {
        setLoading(true);

        await mutateAsync({
          ...data,
          email,
          code,
        });

        const res = await loginAsync({
          email,
          password: data.password,
          deviceName: deviceName || navigator.userAgent,
        });

        await setToken(res.data.token);

        setTimeout(() => toast.success(t('ForgottenScreen.ResetStep.success')), 1000);
      } catch (err) {
        if (422 === err.response?.status) {
          apiErrorsToFormik(err.response.data.errors, setErrors);
          toast.error(t('errors.checkData'));
        } else {
          toast.error(t('errors.api.default'));
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const onSubmitEditing = Platform.select({ web: form.submitForm, default: undefined });

  return (
    <>
      <Text variant="subtitle2">
        <FormattedMessage id="ForgottenScreen.ResetStep.title" />
      </Text>
      <Text sx={{ mt: '$2' }}>
        <FormattedMessage id="ForgottenScreen.ResetStep.text" />
      </Text>

      <FormControl
        label={t('ForgottenScreen.ResetStep.password.label')}
        sx={{ mt: '$5' }}
        error={form.touched.password && form.errors.password}
        required
      >
        <Input
          autoComplete="password-new"
          textContentType="newPassword"
          passwordRules="minlength: 8; required: lower; required: upper; required: digit;"
          value={form.values.password}
          onChangeText={form.handleChange('password')}
          onBlur={form.handleBlur('password')}
          onSubmitEditing={onSubmitEditing}
          secureTextEntry
        />
      </FormControl>
      <FormControl
        label={t('ForgottenScreen.ResetStep.passwordConfirmation.label')}
        sx={{ mt: '$5' }}
        error={form.touched.passwordConfirmation && form.errors.passwordConfirmation}
        required
      >
        <Input
          autoComplete="password-new"
          textContentType="newPassword"
          passwordRules="minlength: 8; required: lower; required: upper; required: digit;"
          value={form.values.passwordConfirmation}
          onChangeText={form.handleChange('passwordConfirmation')}
          onBlur={form.handleBlur('passwordConfirmation')}
          onSubmitEditing={onSubmitEditing}
          secureTextEntry
        />
      </FormControl>
      <View sx={{ mt: '$5' }}>
        <Button
          onPress={() => form.submitForm()}
          loading={loading}
          color="secondary"
          title={t('common.verb.validate')}
        />
      </View>
    </>
  );
}

ResetStep.propTypes = {
  email: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export default ResetStep;
