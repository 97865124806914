import CustomPropTypes from '@utils/CustomPropTypes';
import SCREENS from '@constants/Screens';
import PropTypes from 'prop-types';
import { PostCard } from '@ui/organisms';
import Screen from '@ui/templates/Screen';
import useTranslate from '@hooks/useTranslate';
import { useNavigation } from '@react-navigation/native';

function PostScreen({ route }) {
  const { t } = useTranslate();
  const { id } = route.params;
  const { goBack } = useNavigation();

  return (
    <Screen title={t('PostScreen.title')} presentation={PostScreen.presentation}>
      <PostCard id={id} visible sx={{ width: '100%' }} full borderless onNotFoundError={() => goBack()} />
    </Screen>
  );
}

PostScreen.presentation = 'modal';
PostScreen.modalSize = 'large';
PostScreen.screenName = SCREENS.post;

PostScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      id: CustomPropTypes.uuid.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PostScreen;
