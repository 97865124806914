import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import Qs from 'qs';
import deleteEmptyEntries from '@src/utils/deleteEmptyEntries';
import config from '@src/config';
import useAuthStore, { logout } from '@src/stores/authStore';
import useSocketStore from '@src/stores/socketStore';
import { Platform } from 'react-native';
import { createIntl } from 'react-intl';
import locales from '@src/translations';
import Constants from 'expo-constants';

const instance = applyCaseMiddleware(
  axios.create({
    baseURL: config.apiBaseUrl,
  }),
  {
    preservedKeys: (input) => {
      return ['g-recaptcha-response', '_method'].includes(input) || input.includes('.') || input.includes('-');
    },
    ignoreHeaders: true,
  }
);

// Necessary see https://github.com/axios/axios/issues/738
instance.interceptors.request.use((reqConfig) => {
  // eslint-disable-next-line no-param-reassign
  reqConfig.paramsSerializer = (params) => {
    // Qs is already included in the Axios package
    return Qs.stringify(deleteEmptyEntries(params), {
      arrayFormat: 'brackets',
      encode: false,
    });
  };

  return reqConfig;
});

instance.interceptors.request.use(async (reqConfig) => {
  const { token, residenceId } = useAuthStore.getState();
  const { socketId } = useSocketStore.getState();

  return {
    ...reqConfig,
    headers: deleteEmptyEntries(
      {
        ...reqConfig.headers,
        Authorization: token ? `Bearer ${token}` : null,
        'X-Residence-ID': residenceId,
        'X-Version': Constants.expoConfig.version,
        'X-Socket-ID': socketId,
      },
      true
    ),
  };
});

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const status = err.response?.status;
    if ([401, 426].includes(status)) {
      const { token } = useAuthStore.getState();
      if (token) {
        await logout();
      }

      if (426 === status) {
        const intl = createIntl({ locale: 'fr', messages: locales.fr });

        if ('web' === Platform.OS) {
          const parser = new URL(window.location);

          // Check upgrade query string to avoid infinite refresh
          if (!parser.searchParams.has('upgrade')) {
            parser.searchParams.set('upgrade', true);
            window.location = parser.href;
            return;
          }
        }

        // eslint-disable-next-line no-alert
        alert(intl.formatMessage({ id: 'errors.upgradeApp' }));
      }
    } else if (403 === status && 'RESIDENCE_NOT_FOUND' === err.response?.data?.code) {
      useAuthStore.getState().setResidenceId(null);
    }

    throw err;
  }
);

export default instance;
