import { ActivityIndicator, FlatList, Flex, Text, UserAvatar, View } from '@ui/atoms';
import PropTypes from 'prop-types';
import usePostLikesQuery from '@hooks/queries/usePostLikesQuery';
import CustomPropTypes from '@utils/CustomPropTypes';
import SCREENS from '@constants/Screens';
import Screen from '@ui/templates/Screen';
import useTranslate from '@hooks/useTranslate';
import useOpenUserProfile from '@hooks/useOpenUserProfile';
import useThemeColor from '@hooks/useThemeColor';

function PostLikesScreen({ route }) {
  const { t } = useTranslate();
  const openUserProfile = useOpenUserProfile();
  const primaryColor = useThemeColor('$primary.main');

  const { data, isLoading } = usePostLikesQuery(route.params.postId);

  const handleOpen = (user) => openUserProfile(user.id);

  return (
    <Screen title={t('PostLikesScreen.title')} presentation={PostLikesScreen.presentation} scrollable>
      {isLoading ? (
        <View
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: '$8',
          }}
        >
          <ActivityIndicator size="large" color={primaryColor} />
        </View>
      ) : (
        <FlatList
          data={data.items}
          sx={{ flexGrow: 0 }}
          keyExtractor={(item) => item.id}
          renderItem={({ item, index }) => (
            <Flex
              alignItems="center"
              sx={{
                borderBottomWidth: index === data.items.length - 1 ? 0 : 1,
                borderBottomColor: '$grey.200',
              }}
            >
              <UserAvatar onPress={() => handleOpen(item)} sx={{ mr: '$3' }} size="large" user={item} />
              <View>
                <Text variant="subtitle" sx={{ color: '$primary.main' }}>
                  {item.fullname}
                </Text>
                {item.flatNumber && <Text>{item.flatNumber}</Text>}
              </View>
            </Flex>
          )}
        />
      )}
    </Screen>
  );
}

PostLikesScreen.presentation = 'modal';
PostLikesScreen.screenName = SCREENS.postLikes;

PostLikesScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      postId: CustomPropTypes.uuid.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PostLikesScreen;
