import { createContext, useContext } from 'react';

const PostContext = createContext(null);

export function usePost() {
  const context = useContext(PostContext);
  if (!context) {
    throw new Error(`Post compound components cannot be rendered outside the Post component`);
  }

  return context.post;
}

export default PostContext;
