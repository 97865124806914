import Ionicons from '@ui/atoms/Ionicons';
import Pressable from '@ui/atoms/Pressable';
import Text from '@ui/atoms/Text';
import PropTypes from 'prop-types';
import View from '@ui/atoms/View';
import useThemeColor from '@hooks/useThemeColor';

function File({ label, onPress, error, color: passedColor }) {
  const color = useThemeColor(passedColor);

  const Component = onPress === undefined ? View : Pressable;

  return (
    <Component onPress={onPress} sx={{ flexDirection: 'row', alignItems: 'center' }}>
      <Ionicons name="attach-outline" size={28} sx={{ color: error ? '$red.main' : color }} />
      <Text
        sx={{
          color: error ? '$red.main' : color,
          ml: '$2',
          flex: 1,
          textDecorationLine: 'underline',
          textDecorationStyle: 'solid',
        }}
        numberOfLines={1}
      >
        {label}
      </Text>
    </Component>
  );
}

File.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  error: PropTypes.bool,
  color: PropTypes.string,
};

File.defaultProps = {
  onPress: undefined,
  error: false,
  color: '$text.primary',
};

export default File;
