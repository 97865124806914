import create from 'zustand';
import isEqual from 'fast-deep-equal';
import { v4 as uuid } from 'uuid';

const emptyArray = Object.freeze([]);
const emptyProps = Object.freeze({});

const useModalStore = create((set) => ({
  modals: emptyArray,
  open: (name, props = emptyProps) => {
    set((state) => {
      const history = state.modals;
      const last = history[history.length - 1];

      // If no changes, no update
      if (last && last.name === name && isEqual(last.props, props)) {
        return state;
      }

      // Push new modal
      return { modals: [...history, { id: uuid(), name, props }] };
    });
  },
  // Close modal one by one
  close: () => set((state) => ({ modals: state.modals.slice(0, -1) })),
  closeAll: () => set({ modals: emptyArray }),
}));

export default useModalStore;
