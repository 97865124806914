import { Platform, Keyboard, TouchableWithoutFeedback } from 'react-native';
import PropTypes from 'prop-types';
import View from './View';

function KeyboardDismiss({ children }) {
  if ('web' === Platform.OS) {
    return <View sx={{ flex: 1 }}>{children}</View>;
  }

  return <TouchableWithoutFeedback onPress={Keyboard.dismiss}>{children}</TouchableWithoutFeedback>;
}

KeyboardDismiss.propTypes = {
  children: PropTypes.node.isRequired,
};

export default KeyboardDismiss;
