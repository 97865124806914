export default {
  text: {
    small: {
      fontWeight: 'regular',
      fontSize: 12,
    },
    body: {
      fontWeight: 'regular',
      fontSize: 14,
    },
    body2: {
      fontWeight: 'regular',
      fontSize: 16,
    },
    subtitle: {
      fontWeight: 'medium',
      fontSize: 14,
    },
    subtitle2: {
      fontWeight: 'medium',
      fontSize: 16,
    },
    title: {
      fontSize: 24,
      fontWeight: 'medium',
    },
    button: {
      fontWeight: 'medium',
      color: '$text.primary',
    },
    labelLight: { color: '$common.white' },
  },
  button: {
    contained: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '$1',
      px: '$5',
    },
    outlined: {
      bg: 'transparent',
      borderWidth: 2,
    },
  },
  input: {
    base: {
      width: '100%',
      fontWeight: 'regular',
      borderRadius: 6,
      px: '$3',
    },
    white: {
      bg: '$common.white',
      borderWidth: 1,
      borderColor: '$grey.300',
    },
    grey: {
      bg: '$grey.200',
    },
    multiline: {
      pt: '$3',
      pb: '$3',
      minHeight: 100,
    },
    error: {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: '$red.main',
    },
  },
};
