import { Ionicons, View } from '@ui/atoms';
import { Dropdown } from '@ui/templates';
import useTranslate from '@hooks/useTranslate';
import useConfirmAlert from '@hooks/useConfirmAlert';
import useDeletePostMutation from '@hooks/mutations/useDeletePostMutation';
import { useNavigation } from '@react-navigation/native';
import SCREENS from '@constants/Screens';
import EMBED_TYPE from '@constants/EmbedType';
import useReportPostMutation from '@hooks/mutations/useReportPostMutation';
import useBlockUserMutation from '@hooks/mutations/useBlockUserMutation';
import useMeQuery from '@hooks/queries/useMeQuery';
import { usePost } from '@ui/organisms/Post/postContext';

function PostActions() {
  const { t } = useTranslate();
  const { navigate } = useNavigation();
  const showConfirmAlert = useConfirmAlert();

  const post = usePost();

  const { data: me } = useMeQuery();

  const { mutateAsync: reportPost } = useReportPostMutation(post.id);
  const { mutateAsync: deletePost } = useDeletePostMutation(post.id);
  const { mutateAsync: blockUser } = useBlockUserMutation(post.user.id);

  const handleEdit = () => navigate(SCREENS.editPost, { id: post.id });

  const handleShare = () => navigate(SCREENS.addPost, { embed: { id: post.id, type: EMBED_TYPE.post } });

  const handleBlockUser = () => {
    showConfirmAlert({
      title: t('ui.Post.PostActions.confirmBlockUser.title', { firstname: post.user.firstname }),
      description: t('ui.Post.PostActions.confirmBlockUser.description'),
      destructive: true,
      confirmLabel: t('ui.Post.PostActions.confirmBlockUser.confirm'),
      onConfirm: () => blockUser(),
    });
  };

  const handleReport = () => {
    showConfirmAlert({
      title: t('ui.Post.PostActions.confirmReport.title'),
      description: t('ui.Post.PostActions.confirmReport.description'),
      destructive: true,
      confirmLabel: t('ui.Post.PostActions.confirmReport.confirm'),
      onConfirm: () => reportPost(),
    });
  };

  const handleDelete = () => {
    showConfirmAlert({
      title: t('ui.Post.PostActions.confirmDelete.title'),
      description: t('ui.Post.PostActions.confirmDelete.description'),
      destructive: true,
      confirmLabel: t('ui.Post.PostActions.confirmDelete.confirm'),
      onConfirm: () => deletePost(),
    });
  };

  return (
    <Dropdown
      width={250}
      placement="bottom-end"
      options={[
        {
          icon: <Ionicons name="refresh-outline" size={18} />,
          label: t('ui.Post.PostActions.share'),
          onPress: handleShare,
          visible: post.permissions.share,
        },
        {
          icon: <Ionicons name="pencil" size={18} />,
          label: t('ui.Post.PostActions.edit'),
          visible: post.permissions.edit,
          onPress: handleEdit,
        },
        {
          icon: <Ionicons name="eye-off" size={18} />,
          label: t('ui.Post.PostActions.block', { firstname: post.user.firstname }),
          onPress: handleBlockUser,
          visible: me.id !== post.user.id,
        },
        {
          icon: <Ionicons name="flag" size={18} />,
          label: t('ui.Post.PostActions.report'),
          onPress: handleReport,
          visible: me.id !== post.user.id,
        },
        {
          icon: <Ionicons name="trash" size={18} sx={{ color: '$red.main' }} />,
          label: t('ui.Post.PostActions.delete'),
          visible: post.permissions.delete,
          onPress: handleDelete,
          destructive: true,
        },
      ]}
    >
      <View
        sx={{
          height: 40,
          width: 40,
          right: -10,
          top: -5,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Ionicons name="ellipsis-vertical-sharp" size={24} sx={{ color: '$text.primary' }} />
      </View>
    </Dropdown>
  );
}

export default PostActions;
