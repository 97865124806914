import { useCallback, useEffect, useRef } from 'react';
import { useFocusEffect } from '@react-navigation/native';

function useRefreshOnFocus(refresh, condition = true) {
  const firstRenderRef = useRef(true);
  const conditionRef = useRef(condition);

  useEffect(() => {
    conditionRef.current = condition;
  }, [condition]);

  useFocusEffect(
    useCallback(() => {
      // React Query already fetch data on the first render
      if (firstRenderRef.current) {
        firstRenderRef.current = false;
        return;
      }

      if (!conditionRef.current) {
        return;
      }

      refresh();
    }, [refresh])
  );
}

export default useRefreshOnFocus;
