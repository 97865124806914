function getDOMElementNumberOfLines(el) {
  const factor = 20;
  const previousLineHeight = el.style.lineHeight;
  // eslint-disable-next-line no-param-reassign
  el.style.lineHeight = `${factor}px`;

  const { height } = el.getBoundingClientRect();
  // eslint-disable-next-line no-param-reassign
  el.style.lineHeight = previousLineHeight;

  return Math.round(height / factor);
}

export default getDOMElementNumberOfLines;
