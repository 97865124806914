import useMeQuery from '@hooks/queries/useMeQuery';
import useAuthStore from '@src/stores/authStore';

function usePermissions(options) {
  const { residenceId } = useAuthStore();

  const { data } = useMeQuery({
    select: (user) => user?.residences?.find((residence) => residence.id === residenceId)?.permissions ?? {},
    ...options,
  });

  return data ?? {};
}

export default usePermissions;
