const MIME_TYPE = {
  png: 'image/png',
  jpg: 'image/jpeg',
  webp: 'image/webp',
  pdf: 'application/pdf',
};

export const IMAGE_MIMES = [MIME_TYPE.png, MIME_TYPE.jpg, MIME_TYPE.webp];

export const FILE_MIMES = [MIME_TYPE.png, MIME_TYPE.jpg, MIME_TYPE.webp, MIME_TYPE.pdf];

export default MIME_TYPE;
