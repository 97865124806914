import { useQueryClient } from 'react-query';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import useApiMutation from './useApiMutation';

function useMarkNotificationAsOpenMutation(options) {
  const queryClient = useQueryClient();

  return useApiMutation('post', ({ id }) => `/v1/notifications/${id}/mark-as-open`, {
    ...options,
    onSuccess: () => {
      queryClient.refetchQueries(QUERY_CACHE_KEY.notifications());
    },
  });
}

export default useMarkNotificationAsOpenMutation;
