/* eslint-disable react/no-unstable-nested-components */
import { Text, View, Ionicons, Pressable } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import UserSettings from '@ui/organisms/UserSettings';
import ProfileForm from '@ui/organisms/ProfileForm';
import useCurrentResidenceQuery from '@hooks/queries/useCurrentResidenceQuery';
import useTranslate from '@hooks/useTranslate';
import Screen from '@ui/templates/Screen';
import SCREENS from '@constants/Screens';
import useOpenGtu from '@hooks/useOpenGtu';
import useOpenPrivacyPolicy from '@hooks/useOpenPrivacyPolicy';
import { Platform } from 'react-native';

function EditProfileScreen() {
  const { t } = useTranslate();
  const openGtu = useOpenGtu();
  const openPrivacy = useOpenPrivacyPolicy();

  const isWeb = 'web' === Platform.OS;

  const { data: residence } = useCurrentResidenceQuery();

  return (
    <Screen
      title={t('EditProfileScreen.title')}
      presentation={EditProfileScreen.presentation}
      sx={{
        maxWidth: 938,
        width: '100%',
        mx: 'auto',
      }}
      scrollable
    >
      <ProfileForm />
      <UserSettings sx={{ mt: '$4' }} />
      <View sx={{ my: '$6' }}>
        <View sx={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
          <Ionicons name="home-sharp" size={16} sx={{ color: '$grey.600' }} />
          <Text sx={{ color: '$grey.600', ml: '$3' }}>
            <FormattedMessage id="EditProfileScreen.residence" />
          </Text>
        </View>
        <Text sx={{ textAlign: 'center', mt: '$2' }}>{residence.name}</Text>
        {isWeb ? (
          <Text sx={{ color: '$primary.main', textAlign: 'center', mt: '$6' }}>
            <FormattedMessage
              id="EditProfileScreen.terms"
              values={{
                gtuLink: (chunk) => (
                  <Text
                    onPress={() => openGtu()}
                    sx={{
                      textDecorationLine: 'underline',
                      textDecorationStyle: 'solid',
                    }}
                  >
                    {chunk}
                  </Text>
                ),
                privacyLink: (chunk) => (
                  <Text
                    onPress={() => openPrivacy()}
                    sx={{
                      textDecorationLine: 'underline',
                      textDecorationStyle: 'solid',
                    }}
                  >
                    {chunk}
                  </Text>
                ),
              }}
            />
          </Text>
        ) : (
          <>
            <Pressable onPress={openGtu}>
              <Text sx={{ textAlign: 'center', mt: '$6', color: '$primary.main' }}>
                <FormattedMessage id="common.gtu" />
              </Text>
            </Pressable>
            <Pressable onPress={openPrivacy}>
              <Text sx={{ textAlign: 'center', mt: '$3', color: '$primary.main' }}>
                <FormattedMessage id="common.privacy" />
              </Text>
            </Pressable>
          </>
        )}
      </View>
    </Screen>
  );
}

EditProfileScreen.screenName = SCREENS.editProfile;
EditProfileScreen.presentation = 'card';

export default EditProfileScreen;
