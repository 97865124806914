import { useQueryClient } from 'react-query';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import useApiMutation from './useApiMutation';

function useLikePostMutation(postId, options) {
  const queryClient = useQueryClient();
  const queryKey = QUERY_CACHE_KEY.post(postId);

  return useApiMutation('post', `/v1/posts/${postId}/likes`, {
    ...options,
    onMutate: (...args) => {
      // Update likes counter if the post is in the cache
      const previousData = queryClient.getQueryData(queryKey);
      if (previousData) {
        queryClient.setQueryData(queryKey, (post) => ({
          ...post,
          currentUserLikes: true,
          likesCount: post.likesCount + 1,
        }));
      }

      if ('function' === typeof options?.onMutate) {
        options.onMutate(...args);
      }

      return { previousData };
    },
    onError: (err, data, context) => {
      // Rollback cache on failure
      queryClient.setQueryData(queryKey, context.previousData);

      if ('function' === typeof options?.onError) {
        options.onError(err, data, context);
      }
    },
    onSuccess: (...args) => {
      queryClient.invalidateQueries(queryKey);

      if ('function' === typeof options?.onSuccess) {
        options.onSuccess(...args);
      }
    },
  });
}

export default useLikePostMutation;
