import { Button, Input, Text, View } from '@ui/atoms';
import { FormControl } from '@ui/molecules';
import useTranslate from '@hooks/useTranslate';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import useToast from '@hooks/useToast';
import { useState } from 'react';
import useCheckForgottenCodeMutation from '@hooks/mutations/useCheckForgottenCodeMutation';

function CodeStep({ onSuccess, email }) {
  const { t } = useTranslate();
  const toast = useToast();

  const [value, setValue] = useState('');

  const { mutate, isLoading } = useCheckForgottenCodeMutation({
    onSuccess: () => {
      onSuccess({ code: value });
    },
    onError: (err) => {
      if (422 === err.response?.status) {
        toast.error(t('errors.checkData'));
      } else if (410 === err.response?.status) {
        toast.error(t('ForgottenScreen.CodeStep.expired'));
      } else if (429 === err.response?.status) {
        toast.error(t('errors.api.throttle'));
      } else {
        toast.error(t('errors.api.default'));
      }
    },
  });

  const handleSubmit = () => {
    mutate({ email, code: value });
  };

  return (
    <>
      <Text variant="subtitle2">
        <FormattedMessage id="ForgottenScreen.CodeStep.title" />
      </Text>
      <Text sx={{ mt: '$2' }}>
        <FormattedMessage id="ForgottenScreen.CodeStep.text" values={{ email }} />
      </Text>

      <FormControl label={t('ForgottenScreen.CodeStep.code.label')} sx={{ mt: '$5' }} required>
        <Input onChangeText={setValue} keyboardType="number-pad" maxLength={5} />
      </FormControl>
      <View sx={{ mt: '$5' }}>
        <Button
          disabled={0 === value.trim().length}
          loading={isLoading}
          onPress={handleSubmit}
          max
          color="secondary"
          title={t('common.verb.continue')}
        />
      </View>
    </>
  );
}

CodeStep.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default CodeStep;
