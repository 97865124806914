import { Image, Pressable, Text, View } from '@ui/atoms';
import useWebView from '@hooks/useWebView';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';

function LinkPreview({ url, metadata, sx }) {
  const openWebView = useWebView();

  return (
    <Pressable onPress={() => openWebView(metadata.title, url)}>
      <View
        sx={{
          maxWidth: 500,
          borderRadius: '$2',
          bg: '$grey.100',
          borderColor: '$grey.300',
          overflow: 'hidden',
          borderWidth: 1,
          ...sx,
        }}
      >
        {metadata.image && (
          <Image
            source={{ uri: metadata.image }}
            sx={{
              width: '100%',
              height: 240,
              resizeMode: 'cover',
              bg: '$grey.300',
            }}
          />
        )}
        <View sx={{ p: '$4' }}>
          {metadata.title && <Text>{metadata.title}</Text>}
          {metadata.domain && <Text variant="small">{metadata.domain}</Text>}
          {metadata.description && <Text variant="small">{metadata.description}</Text>}
        </View>
      </View>
    </Pressable>
  );
}

LinkPreview.propTypes = {
  sx: CustomPropTypes.style,
  url: PropTypes.string.isRequired,
  metadata: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    domain: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

LinkPreview.defaultProps = {
  sx: undefined,
};

export default LinkPreview;
