import { useMutation } from 'react-query';
import axios from '@src/utils/axios';

/**
 * @param {string} method
 * @param {string|Function} url
 * @param {UseMutationOptions} options
 * @returns {UseMutationResult}
 */
function useApiMutation(method, url, options = {}) {
  const { mutationFnOptions, ...queryOptions } = options;

  return useMutation((data) => {
    let body;
    if (data instanceof FormData) {
      body = data;
    } else if (data) {
      const { params, ...rest } = data;
      body = rest;
    }

    return axios.request({
      method,
      url: 'function' === typeof url ? url(data?.params) : url,
      data: body ?? undefined,
      ...mutationFnOptions,
    });
  }, queryOptions);
}

export default useApiMutation;
