/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import { createNavigatorFactory, StackActions, useNavigationBuilder } from '@react-navigation/native';
import * as React from 'react';

import { NativeStackView } from '@react-navigation/native-stack';
import CustomStackRouter from '@src/navigation/CustomStackRouter';

/*
 * File copied from React Navigation to use CustomStackRouter
 * See @react-navigation/native-stack/src/navigators/createNativeStackNavigator.tsx
 */
function NativeStackNavigator({ id, initialRouteName, children, screenListeners, screenOptions, ...rest }) {
  const { state, descriptors, navigation, NavigationContent } = useNavigationBuilder(CustomStackRouter, {
    id,
    initialRouteName,
    children,
    screenListeners,
    screenOptions,
  });

  React.useEffect(
    () =>
      navigation?.addListener?.('tabPress', (e) => {
        const isFocused = navigation.isFocused();

        // Run the operation in the next frame so we're sure all listeners have been run
        // This is necessary to know if preventDefault() has been called
        requestAnimationFrame(() => {
          if (0 < state.index && isFocused && !e.defaultPrevented) {
            // When user taps on already focused tab and we're inside the tab,
            // reset the stack to replicate native behaviour
            navigation.dispatch({
              ...StackActions.popToTop(),
              target: state.key,
            });
          }
        });
      }),
    [navigation, state.index, state.key]
  );

  return (
    <NavigationContent>
      <NativeStackView {...rest} state={state} navigation={navigation} descriptors={descriptors} />
    </NavigationContent>
  );
}

export default createNavigatorFactory(NativeStackNavigator);
