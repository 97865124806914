import RESIDENCE_USER_ROLE from '@constants/ResidenceUserRole';
import RESIDENCE_TYPE from '@constants/ResidenceType';

const POST_TYPE = {
  classic: 'classic',
  alert: 'alert',
  document: 'document',
  repost: 'repost',
  news: 'news',
  discussion: 'discussion',
  echangeEntreJardinier: 'echangeEntreJardinier',
  jePreviens: 'jePreviens',
  jaiBesoin: 'jaiBesoin',
  help: 'help',
  unBesoin: 'unBesoin',
  unSoucis: 'unSoucis',
};

export function isKnownPostType(type) {
  return Object.values(POST_TYPE).includes(type);
}

export function hasCategory(type) {
  return [
    POST_TYPE.classic,
    POST_TYPE.alert,
    POST_TYPE.echangeEntreJardinier,
    POST_TYPE.jePreviens,
    POST_TYPE.jaiBesoin,
    POST_TYPE.help,
    POST_TYPE.unBesoin,
    POST_TYPE.unSoucis,
  ].includes(type);
}

export function getPostTypeMaxLength(type) {
  switch (type) {
    case POST_TYPE.document:
    case POST_TYPE.news:
    case POST_TYPE.discussion:
      return 1600;
    default:
      return 800;
  }
}

export function getPublishablePostTypes(role, residenceType) {
  let publishablePostTypes = Object.values(POST_TYPE)
    .filter((type) => type !== POST_TYPE.repost) // Exclut le type "repost"
    .filter((type) => {
      if (role === RESIDENCE_USER_ROLE.resident) {
        // Autoriser le résident à publier "discussion"
        return ![POST_TYPE.document, POST_TYPE.news].includes(type);
      }
      return true;
    });

  // Ajout des types spécifiques en fonction du type de résidence

  if (RESIDENCE_TYPE.garden === residenceType) {
    publishablePostTypes.push(POST_TYPE.echangeEntreJardinier, POST_TYPE.jePreviens, POST_TYPE.discussion);
  } else {
    publishablePostTypes = publishablePostTypes.filter(
      (type) => ![POST_TYPE.echangeEntreJardinier, POST_TYPE.jePreviens].includes(type)
    );
  }

  if (RESIDENCE_TYPE.student === residenceType) {
    publishablePostTypes.push(POST_TYPE.jaiBesoin, POST_TYPE.help, POST_TYPE.discussion); // Ajout du type "discussion"
  } else {
    publishablePostTypes = publishablePostTypes.filter((type) => ![POST_TYPE.jaiBesoin, POST_TYPE.help].includes(type));
  }

  if (RESIDENCE_TYPE.office === residenceType) {
    publishablePostTypes.push(POST_TYPE.unBesoin, POST_TYPE.unSoucis, POST_TYPE.discussion);
  } else {
    publishablePostTypes = publishablePostTypes.filter(
      (type) => ![POST_TYPE.unBesoin, POST_TYPE.unSoucis].includes(type)
    );
  }

  if (RESIDENCE_TYPE.community === residenceType) {
    publishablePostTypes.push(POST_TYPE.classic, POST_TYPE.alert, POST_TYPE.discussion);
  } else {
    publishablePostTypes = publishablePostTypes.filter((type) => ![POST_TYPE.classic, POST_TYPE.alert].includes(type));
  }

  // Remove duplicates by converting to a Set and back to an array
  publishablePostTypes = Array.from(new Set(publishablePostTypes));

  return publishablePostTypes;
}

export function getVisiblePostTypes(residenceType) {
  let visiblePostTypes = Object.values(POST_TYPE).filter(
    (type) =>
      ![
        POST_TYPE.classic,
        POST_TYPE.alert,
        POST_TYPE.echangeEntreJardinier,
        POST_TYPE.jePreviens,
        POST_TYPE.jaiBesoin,
        POST_TYPE.help,
        POST_TYPE.unBesoin,
        POST_TYPE.unSoucis,
      ].includes(type)
  );

  // Ajout des types spécifiques en fonction du type de résidence
  if (RESIDENCE_TYPE.project === residenceType) {
    visiblePostTypes.push(POST_TYPE.document, POST_TYPE.news);
  }

  if (RESIDENCE_TYPE.garden === residenceType) {
    visiblePostTypes.push(POST_TYPE.echangeEntreJardinier, POST_TYPE.jePreviens, POST_TYPE.discussion);
  }

  if (RESIDENCE_TYPE.student === residenceType) {
    visiblePostTypes.push(POST_TYPE.jaiBesoin, POST_TYPE.help, POST_TYPE.discussion);
  }

  if (RESIDENCE_TYPE.office === residenceType) {
    visiblePostTypes.push(POST_TYPE.unBesoin, POST_TYPE.unSoucis, POST_TYPE.discussion);
  }

  if (RESIDENCE_TYPE.community === residenceType) {
    visiblePostTypes.push(POST_TYPE.classic, POST_TYPE.alert, POST_TYPE.discussion);
  }

  // Remove duplicates by converting to a Set and back to an array
  visiblePostTypes = Array.from(new Set(visiblePostTypes));

  return visiblePostTypes;
}

export default POST_TYPE;
