/* eslint-disable react/jsx-props-no-spreading */
import { useWindowDimensions } from 'react-native';
import { Pressable, View } from '@ui/atoms';
import { Fragment, useEffect, useRef, useState } from 'react';
import PopoverPropTypes, { PopoverDefaultProps } from '@ui/templates/Popover/PopoverPropTypes';
import {
  shift,
  useFloating,
  offset as floatingOffset,
  FloatingPortal,
  FloatingOverlay,
  autoUpdate,
} from '@floating-ui/react-dom-interactions';

function Popover({ trigger, children, width, placement, offset, open, setOpen, sx, unmount }) {
  const window = useWindowDimensions();
  const [localOpen, localSetOpen] = useState(false);

  // eslint-disable-next-line no-param-reassign
  open = open ?? localOpen;
  // eslint-disable-next-line no-param-reassign
  setOpen = setOpen ?? localSetOpen;

  const ref = useRef();
  const triggerRef = useRef();

  const { x, y, reference, floating, update } = useFloating({
    placement,
    strategy: 'absolute',
    middleware: [shift(), floatingOffset(offset)],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    const listener = (event) => {
      if (triggerRef.current?.contains(event.target)) {
        return;
      }

      setOpen((prev) => {
        const isClickInside = ref.current?.contains(event.target);
        if (!isClickInside) {
          return false;
        }

        return prev;
      });
    };

    document.addEventListener('click', listener, { capture: true });

    return () => document.removeEventListener('click', listener);
  }, [setOpen, open, ref]);

  useEffect(() => {
    update();
  }, [update, window.width, open]);

  const Overlay = open ? FloatingOverlay : Fragment;
  const overlayProps = open ? { lockScroll: true } : {};

  return (
    <>
      <Pressable
        ref={(el) => {
          reference(el);
          triggerRef.current = el;
        }}
        onPress={() => setOpen((prev) => !prev)}
      >
        {trigger}
      </Pressable>
      <FloatingPortal>
        {(open || !unmount) && (
          <Overlay {...overlayProps}>
            <View
              ref={floating}
              sx={{
                position: 'absolute',
                top: y ?? 0,
                left: x ?? 0,
                visibility: open ? 'visible' : 'hidden',
                width,
              }}
            >
              <View ref={ref} sx={sx}>
                {children}
              </View>
            </View>
          </Overlay>
        )}
      </FloatingPortal>
    </>
  );
}

Popover.propTypes = PopoverPropTypes;

Popover.defaultProps = PopoverDefaultProps;

export default Popover;
