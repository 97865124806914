import useTranslate from '@hooks/useTranslate';
import useWebView from '@hooks/useWebView';
import { useCallback } from 'react';
import config from '@src/config';

function useOpenPrivacyPolicy() {
  const { t } = useTranslate();
  const openWebView = useWebView();

  return useCallback(() => {
    openWebView(t('common.privacy'), config.privacy);
  }, [t, openWebView]);
}

export default useOpenPrivacyPolicy;
