import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import { useQueryClient } from 'react-query';
import useApiMutation from './useApiMutation';

function useCreateEventMutation(options) {
  const queryClient = useQueryClient();

  return useApiMutation('post', `/v1/events`, {
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(QUERY_CACHE_KEY.feed());

      if ('function' === typeof options?.onSuccess) {
        options.onSuccess(...args);
      }
    },
  });
}

export default useCreateEventMutation;
