import PropTypes from 'prop-types';
import './style.css';
import View from '@ui/atoms/View';
import CustomPropTypes from '@utils/CustomPropTypes';

function Skeleton({ width, height, radius, sx }) {
  return (
    <div className="skeleton">
      <View sx={{ bg: '$grey.200', width, height, borderRadius: radius, ...sx }} />
    </div>
  );
}

Skeleton.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  radius: PropTypes.number,
  sx: CustomPropTypes.style,
};

Skeleton.defaultProps = {
  radius: 4,
  sx: undefined,
};

export default Skeleton;
