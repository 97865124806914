import { Pressable, ProgressBar, Text, UserAvatar, View } from '@ui/atoms';
import CustomPropTypes from '@utils/CustomPropTypes';
import { FormattedMessage } from 'react-intl';
import useOpenUserProfile from '@hooks/useOpenUserProfile';

function ChoicesAnswers({ survey }) {
  const openUserProfile = useOpenUserProfile();

  const choices = survey.choices
    .map((choice) => {
      return {
        ...choice,
        answers: survey.answers.filter((answer) => {
          return answer.choices.includes(choice.id);
        }),
      };
    })
    .filter((choice) => 0 < choice.answers.length);

  return (
    <View>
      <View>
        {survey.results?.map((choiceResult) => (
          <ProgressBar
            key={choiceResult.choiceId}
            label={survey.choices.find((choice) => choice.id === choiceResult.choiceId).name}
            value={choiceResult.average}
          />
        ))}
      </View>
      <View sx={{ mt: '$2' }}>
        {choices.map((choice) => {
          return (
            <View key={choice.id} sx={{ mt: '$4' }}>
              <Text sx={{ mb: '$2' }} variant="subtitle2">
                <FormattedMessage
                  id="ui.SurveyAnswers.ChoicesAnswers.groupTitle"
                  values={{ name: choice.name, count: choice.answers.length }}
                />
              </Text>
              {choice.answers.map((answer) => (
                <Pressable
                  onPress={() => openUserProfile(answer.user.id)}
                  sx={{ flexDirection: 'row', alignItems: 'center', my: '$1' }}
                  key={answer.user.id}
                >
                  <UserAvatar user={answer.user} size="medium" />
                  <Text sx={{ ml: '$4' }} variant="subtitle">
                    {answer.user.fullname}
                  </Text>
                </Pressable>
              ))}
            </View>
          );
        })}
      </View>
    </View>
  );
}

ChoicesAnswers.propTypes = {
  survey: CustomPropTypes.survey.isRequired,
};

export default ChoicesAnswers;
