import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import { useQueryClient } from 'react-query';
import useApiMutation from './useApiMutation';

function useUpdateProfileMutation(options) {
  const queryClient = useQueryClient();

  return useApiMutation('patch', `/v1/profile`, {
    ...options,
    onSuccess: (res, ...rest) => {
      queryClient.setQueryData(QUERY_CACHE_KEY.profile(), res.data);

      if ('function' === typeof options?.onSuccess) {
        options.onSuccess(res, ...rest);
      }
    },
  });
}

export default useUpdateProfileMutation;
