import PropTypes from 'prop-types';

export const DropdownOptionType = PropTypes.shape({
  icon: PropTypes.node, // Web only
  label: PropTypes.string.isRequired,
  destructive: PropTypes.bool,
  visible: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
});

const DropdownPropTypes = {
  options: PropTypes.arrayOf(DropdownOptionType).isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,

  // Web only
  width: PropTypes.number,
  offset: PropTypes.number,
  rtl: PropTypes.bool,
  placement: PropTypes.oneOf([
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'bottom-start',
    'bottom-end',
    'left-start',
    'left-end',
  ]),
};

export const DropdownDefaultProps = {
  width: undefined,
  offset: 0,
  placement: 'bottom-start',
  rtl: false,
  disabled: false,
};

export default DropdownPropTypes;
