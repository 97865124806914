import { WebView } from 'react-native-webview';
import PropTypes from 'prop-types';
import SCREENS from '@constants/Screens';
import Screen from '@ui/templates/Screen';

function WebScreen({ route }) {
  const { uri, title } = route.params;

  return (
    <Screen title={title} presentation={WebScreen.presentation} sx={{ p: 0 }}>
      <WebView originWhitelist={['*']} source={{ uri }} style={{ flex: 1 }} />
    </Screen>
  );
}

WebScreen.screenName = SCREENS.web;
WebScreen.presentation = 'card';
WebScreen.visibility = 'public';

WebScreen.propTypes = {
  navigation: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      uri: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired,
};

export default WebScreen;
