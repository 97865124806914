import { useQueryClient } from 'react-query';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import useApiMutation from './useApiMutation';

function useDeleteSurveyMutation(surveyId, options) {
  const queryClient = useQueryClient();

  return useApiMutation('delete', `/v1/surveys/${surveyId}`, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(QUERY_CACHE_KEY.feed());
      queryClient.resetQueries(QUERY_CACHE_KEY.survey(surveyId));

      if ('function' === typeof options?.onSuccess) {
        await options.onSuccess(...args);
      }
    },
  });
}

export default useDeleteSurveyMutation;
