import { View, Pressable, Text, Ionicons, Hidden } from '@ui/atoms';
import { GuestLayout } from '@ui/layouts';
import SCREENS from '@constants/Screens';
import { useNavigation } from '@react-navigation/native';
import useBreakpointName from '@hooks/useBreakpointName';
import { FormattedMessage } from 'react-intl';
import EmailStep from '@src/screens/ForgottenScreen/EmailStep';
import CodeStep from '@src/screens/ForgottenScreen/CodeStep';
import ResetStep from '@src/screens/ForgottenScreen/ResetStep';
import { useState } from 'react';

const STEPS = {
  email: 'email',
  code: 'code',
  reset: 'reset',
};

const STEP_COMPONENT = {
  [STEPS.email]: EmailStep,
  [STEPS.code]: CodeStep,
  [STEPS.reset]: ResetStep,
};

function ForgottenScreen() {
  const { navigate } = useNavigation();
  const breakpoint = useBreakpointName();

  const [email, setEmail] = useState(null);
  const [code, setCode] = useState(null);
  const [step, setStep] = useState(STEPS.email);
  const StepComponent = STEP_COMPONENT[step];

  const handleSuccess = (data) => {
    switch (step) {
      case STEPS.email:
        setStep(STEPS.code);
        setEmail(data.email);
        break;
      case STEPS.code:
        setStep(STEPS.reset);
        setCode(data.code);
        break;
      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <GuestLayout scrollable withoutLogo={'xs' === breakpoint}>
      <View sx={{ pb: '$10' }}>
        <Hidden except="xs">
          <Pressable
            onPress={() => navigate(SCREENS.login)}
            sx={{ flexDirection: 'row', alignItems: 'center', mb: '$5' }}
          >
            <Ionicons name="arrow-back-sharp" size={24} sx={{ mr: '$2', color: '$text.primary' }} />
            <Text variant="button">
              <FormattedMessage id="common.back" />
            </Text>
          </Pressable>
        </Hidden>

        <StepComponent onSuccess={handleSuccess} email={email} code={code} />
      </View>
    </GuestLayout>
  );
}

ForgottenScreen.screenName = SCREENS.forgotten;
ForgottenScreen.visibility = 'guest';

export default ForgottenScreen;
