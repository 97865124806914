/* eslint-disable react/jsx-props-no-spreading, react/forbid-prop-types */
import React from 'react';
import { View, Text } from '@ui/atoms';
import isEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import Input from '@ui/atoms/Input';
import Select from '@ui/atoms/Select';
import FilePicker from '@ui/atoms/FilePicker';
import FormError from '@ui/atoms/FormError';

function FormControl({
  label,
  required = false,
  variant = 'default',
  error = undefined,
  inputProps = undefined,
  labelProps = undefined,
  children,
  ...props
}) {
  if (required) {
    label += ' *';
  }

  return (
    <View {...props}>
      <Text
        variants={['label', variant === 'light' && 'labelLight'].filter((v) => v)}
        {...labelProps}
        sx={{ ...labelProps?.sx, mb: '$1' }}
      >
        {label}
      </Text>
      {React.Children.map(children, (child) => {
        if ([Input, Select, FilePicker].includes(child.type)) {
          return React.cloneElement(child, { error: child.props.error || !!error });
        }

        return child;
      })}
      {error && typeof error !== 'boolean' && (
        <View sx={{ mt: '$2' }}>
          <FormError error={error} />
        </View>
      )}
    </View>
  );
}

FormControl.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'default']),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ key: PropTypes.string, values: PropTypes.object })]),
  inputProps: PropTypes.object,
  labelProps: PropTypes.object,
};

export default React.memo(FormControl, isEqual);
