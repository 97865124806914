import { useCallback } from 'react';
import { Platform } from 'react-native';
import useTranslate from '@hooks/useTranslate';
import ConfirmAlertDialog from '@ui/organisms/ConfirmAlertDialog';
import { useActionSheet } from '@expo/react-native-action-sheet';
import useDialog from '@hooks/useDialog';

export default function useConfirmAlert() {
  const { t } = useTranslate();
  const { open } = useDialog();
  const { showActionSheetWithOptions } = useActionSheet();

  return useCallback(
    ({ title, description, onConfirm, confirmLabel, destructive = false }) => {
      if ('web' === Platform.OS) {
        open(ConfirmAlertDialog, {
          title,
          description,
          onConfirm,
          confirmLabel,
          destructive,
        });

        return;
      }

      showActionSheetWithOptions(
        {
          title,
          message: description,
          options: [confirmLabel ?? t('common.verb.validate'), t('common.verb.cancel')],
          cancelButtonIndex: 1,
          destructiveButtonIndex: destructive ? 0 : undefined,
        },
        (index) => {
          if (0 === index) {
            onConfirm();
          }
        }
      );
    },
    [open, showActionSheetWithOptions, t]
  );
}
