import { ActivityIndicator, FlatList, Text, View } from '@ui/atoms';
import useTranslate from '@hooks/useTranslate';
import Screen from '@ui/templates/Screen';
import SCREENS from '@constants/Screens';
import useBlockedUsersQuery from '@hooks/queries/useBlockedUsersQuery';
import { FormattedMessage } from 'react-intl';
import BlockedUserRow from '@ui/organisms/BlockedUserRow';
import useThemeColor from '@hooks/useThemeColor';

function BlockedUsersScreen() {
  const { t } = useTranslate();
  const primaryColor = useThemeColor('$primary.main');

  const { data, isLoading } = useBlockedUsersQuery();

  return (
    <Screen title={t('BlockedUsersScreen.title')} presentation={BlockedUsersScreen.presentation} sx={{ p: 0, pt: 0 }}>
      {isLoading ? (
        <View
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: '$8',
          }}
        >
          <ActivityIndicator size="large" color={primaryColor} />
        </View>
      ) : (
        <FlatList
          ListEmptyComponent={
            <Text variant="subtitle" sx={{ textAlign: 'center', m: '$4' }}>
              <FormattedMessage id="BlockedUsersScreen.empty" />
            </Text>
          }
          data={data.items}
          sx={{ flexGrow: 0 }}
          keyExtractor={(item) => item.id}
          renderItem={({ item, index }) => <BlockedUserRow isLast={index === data.items.length - 1} user={item} />}
        />
      )}
    </Screen>
  );
}

BlockedUsersScreen.presentation = 'modal';
BlockedUsersScreen.screenName = SCREENS.blockedUsers;

export default BlockedUsersScreen;
