import { useEffect, useState } from 'react';

/**
 * @param {Function} fn
 * @param {Array|undefined} deps
 * @param {boolean} throwOnError Make the component "crash" if the promise is not resolved
 */
function useAsyncEffect(fn, deps, throwOnError = true) {
  const [, setState] = useState(); // This is a fake state used to throw an error catchable by the errorBoundary
  /* eslint-disable */
  useEffect(() => {
    Promise.resolve(fn()).catch((e) => {
      if (throwOnError) {
        setState(() => {
          throw e; // The magic happens here ;)
        });
      }
    });
  }, deps);
  /* eslint-enable */
}

export default useAsyncEffect;
