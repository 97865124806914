import unflatten from '@utils/unflatten';

function apiErrorsToFormik(apiErrors, setErrors) {
  const formikErrors = {};
  Object.keys(apiErrors).forEach((key) => {
    // eslint-disable-next-line prefer-destructuring
    formikErrors[key] = apiErrors[key][0];
  });

  setErrors(unflatten(formikErrors));
}

export default apiErrorsToFormik;
