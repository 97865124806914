import { useQueryClient } from 'react-query';
import useApiMutation from '@hooks/mutations/useApiMutation';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';

function useDeleteCommentMutation(id, modelType, modelId, options) {
  const queryClient = useQueryClient();

  return useApiMutation('delete', `/v1/comments/${id}`, {
    ...options,
    onSuccess: () => {
      return queryClient.invalidateQueries(QUERY_CACHE_KEY.comments(modelType, modelId));
    },
  });
}

export default useDeleteCommentMutation;
