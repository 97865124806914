import useTranslate from '@hooks/useTranslate';
import { AddPostForm } from '@ui/organisms';
import SCREENS from '@constants/Screens';
import Screen from '@ui/templates/Screen';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import { useNavigation } from '@react-navigation/native';
import useToast from '@hooks/useToast';
import usePostQuery from '@hooks/queries/usePostQuery';
import useUpdatePostMutation from '@hooks/mutations/useUpdatePostMutation';
import { ActivityIndicator, View } from '@ui/atoms';
import useThemeColor from '@hooks/useThemeColor';

function EditPostScreen({ route }) {
  const toast = useToast();
  const { t } = useTranslate();
  const { navigate, goBack } = useNavigation();
  const primaryColor = useThemeColor('$primary.main');

  const { id } = route.params || {};

  const { data: post, isLoading } = usePostQuery(id);

  const { mutateAsync } = useUpdatePostMutation(id);

  const handleSubmit = async (data) => {
    const res = await mutateAsync(data);
    toast.success(t('EditPostScreen.success'));

    goBack();
    navigate(SCREENS.post, { id: res.data.id });
  };

  return (
    <Screen title={t('EditPostScreen.title')} presentation={EditPostScreen.presentation} scrollable>
      {isLoading ? (
        <View
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: '$8',
          }}
        >
          <ActivityIndicator size="large" color={primaryColor} />
        </View>
      ) : (
        <AddPostForm onSubmit={handleSubmit} embed={post.embed} post={post} />
      )}
    </Screen>
  );
}

EditPostScreen.presentation = 'modal';
EditPostScreen.screenName = SCREENS.editPost;

EditPostScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      embed: PropTypes.shape({
        id: CustomPropTypes.uuid,
        type: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default EditPostScreen;
