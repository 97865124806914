import { Input, Text, View } from '@ui/atoms';
import { FormControl } from '@ui/molecules/index';
import useTranslate from '@hooks/useTranslate';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TouchableOpacity } from 'react-native';
import ImagesPicker from '@ui/organisms/ImagesPicker';
import CustomPropTypes from '@utils/CustomPropTypes';

function ChoiceInput({
  number,
  name,
  image,
  onChangeName,
  onDeleteRow,
  uploading,
  onSelectImage,
  onDeleteImage,
  required,
  errors,
  touched,
}) {
  const { t } = useTranslate();

  return (
    <View sx={{ position: 'relative' }}>
      {'function' === typeof onDeleteRow && (
        <View sx={{ position: 'absolute', top: 0, right: 0, zIndex: 200 }}>
          <TouchableOpacity onPress={onDeleteRow}>
            <Text sx={{ color: '$primary.main' }}>
              <FormattedMessage id="common.verb.delete" />
            </Text>
          </TouchableOpacity>
        </View>
      )}
      <FormControl
        label={t('ui.SurveyForm.ChoiceRow.name', { number })}
        error={touched?.name && errors?.name}
        required={required}
        sx={{ mb: '$2' }}
      >
        <Input value={name} maxLength={100} onChangeText={onChangeName} withCharCounter />
      </FormControl>
      <ImagesPicker
        max={1}
        loading={uploading}
        onDelete={onDeleteImage}
        onSelect={onSelectImage}
        images={
          image
            ? [
                {
                  id: image.id,
                  uri: image.preview ?? image.small,
                },
              ]
            : []
        }
      />
    </View>
  );
}

ChoiceInput.propTypes = {
  number: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  onDeleteImage: PropTypes.func.isRequired,
  onDeleteRow: PropTypes.func,
  uploading: PropTypes.bool,
  required: PropTypes.bool,
  errors: CustomPropTypes.formErrors,
  touched: CustomPropTypes.formTouched,
  image: PropTypes.shape({
    id: CustomPropTypes.uuid,
    preview: PropTypes.string,
    small: PropTypes.string,
  }),
};

ChoiceInput.defaultProps = {
  onDeleteRow: undefined,
  uploading: false,
  required: false,
  image: null,
  errors: undefined,
  touched: undefined,
};

export default ChoiceInput;
