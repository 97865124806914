import { useQuery } from 'react-query';
import QUERY_CACHE_KEY from '@src/constants/QueryCacheKey';

function useProfileQuery(options) {
  return useQuery(QUERY_CACHE_KEY.profile(), {
    staleTime: 10 * 60 * 1000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export default useProfileQuery;
