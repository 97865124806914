import PropTypes from 'prop-types';
import { FEED_ITEM_COMPONENT } from '@constants/FeedItemType';

function FeedItem({ type, id, visible }) {
  const Component = FEED_ITEM_COMPONENT[type];
  if (!Component) {
    return null;
  }

  return <Component id={id} visible={visible} sx={{ mb: '$6' }} />;
}

FeedItem.propTypes = {
  type: PropTypes.oneOf(Object.keys(FEED_ITEM_COMPONENT)).isRequired,
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default FeedItem;
