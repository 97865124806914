import { Button, View } from '@ui/atoms';
import { useFormik } from 'formik';
import { object, password, ref, string } from '@utils/yup';
import { Platform } from 'react-native';
import apiErrorsToFormik from '@utils/apiErrorsToFormik';
import useUpdatePasswordMutation from '@hooks/mutations/useUpdatePasswordMutation';
import useToast from '@hooks/useToast';
import useTranslate from '@hooks/useTranslate';
import { useNavigation } from '@react-navigation/native';
import { FormControl } from '@ui/molecules';
import PasswordInput from '@ui/atoms/PasswordInput';
import Screen from '@ui/templates/Screen';
import SCREENS from '@constants/Screens';

function EditPasswordScreen() {
  const toast = useToast();
  const navigation = useNavigation();
  const { t } = useTranslate();

  const { mutateAsync: updateAsyncPassword, isLoading } = useUpdatePasswordMutation();

  const form = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: object({
      currentPassword: string().min(2).required(),
      password: password().required(),
      passwordConfirmation: string()
        .required({ key: 'errors.validation.passwordConfirmation' })
        .oneOf([ref('password'), null], { key: 'errors.validation.passwordConfirmation' }),
    }),
    onSubmit: async (data, { setErrors }) => {
      try {
        await updateAsyncPassword({
          currentPassword: data.currentPassword,
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
        });

        navigation.goBack();
        toast.success(t('EditPasswordScreen.success'));
      } catch (err) {
        if (422 === err.response?.status) {
          apiErrorsToFormik(err.response.data.errors, setErrors);
          toast.error(t('errors.checkData'));
        } else {
          toast.error(t('errors.api.default'));
        }
      }
    },
  });

  const onSubmitEditing = (field) => Platform.select({ web: form.validateField(field), default: undefined });

  return (
    <Screen title={t('EditPasswordScreen.title')} presentation={EditPasswordScreen.presentation}>
      <FormControl
        label={t('EditPasswordScreen.currentPassword.label')}
        error={form.touched.currentPassword && form.errors.currentPassword}
        required
      >
        <PasswordInput
          valid={form.touched.currentPassword && !form.errors.currentPassword}
          autoComplete="password-new"
          autoCapitalize="none"
          textContentType="newPassword"
          value={form.values.currentPassword}
          onChangeText={form.handleChange('currentPassword')}
          returnKeyType="next"
          returnKeyLabel={t('common.next')}
          blurOnSubmit={false}
          onSubmitEditing={() => onSubmitEditing('currentPassword')}
          onBlur={form.handleBlur('password')}
        />
      </FormControl>
      <FormControl
        label={t('EditPasswordScreen.password.label')}
        sx={{ mt: '$4' }}
        error={form.touched.password && form.errors.password}
        required
      >
        <PasswordInput
          valid={form.touched.password && !form.errors.password}
          autoComplete="password-new"
          autoCapitalize="none"
          textContentType="newPassword"
          value={form.values.password}
          returnKeyType="next"
          returnKeyLabel={t('common.next')}
          blurOnSubmit={false}
          onChangeText={form.handleChange('password')}
          onBlur={form.handleBlur('password')}
          onSubmitEditing={() => onSubmitEditing('password')}
          passwordRules="minlength: 8; required: lower; required: upper; required: digit;"
        />
      </FormControl>
      <FormControl
        label={t('EditPasswordScreen.passwordConfirmation.label')}
        sx={{ mt: '$4' }}
        error={form.touched.passwordConfirmation && form.errors.passwordConfirmation}
        required
      >
        <PasswordInput
          valid={form.touched.passwordConfirmation && !form.errors.passwordConfirmation}
          autoComplete="password-new"
          autoCapitalize="none"
          textContentType="newPassword"
          value={form.values.passwordConfirmation}
          returnKeyType="done"
          returnKeyLabel={t('common.verb.done')}
          blurOnSubmit={false}
          onChangeText={form.handleChange('passwordConfirmation')}
          onBlur={form.handleBlur('passwordConfirmation')}
          onSubmitEditing={form.handleSubmit}
          passwordRules="minlength: 8; required: lower; required: upper; required: digit;"
        />
      </FormControl>
      <View sx={{ mt: '$4' }}>
        <Button title={t('common.verb.save')} color="primary" loading={isLoading} onPress={form.handleSubmit} />
      </View>
    </Screen>
  );
}

EditPasswordScreen.presentation = 'modal';
EditPasswordScreen.screenName = SCREENS.editPassword;

export default EditPasswordScreen;
