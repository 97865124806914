import useTranslate from '@hooks/useTranslate';
import useCreateEventMutation from '@hooks/mutations/useCreateEventMutation';
import { useNavigation } from '@react-navigation/native';
import SCREENS from '@constants/Screens';
import Screen from '@ui/templates/Screen';
import EventForm from '@ui/organisms/EventForm';

function CreateEventScreen() {
  const { t } = useTranslate();
  const { goBack, navigate } = useNavigation();

  const { mutateAsync } = useCreateEventMutation();

  const handleSubmit = async (data) => {
    const res = await mutateAsync(data);

    goBack();
    navigate(SCREENS.event, { id: res.data.id });
  };

  return (
    <Screen title={t('CreateEventScreen.title')} presentation={CreateEventScreen.presentation} scrollable>
      <EventForm onSubmit={handleSubmit} />
    </Screen>
  );
}

CreateEventScreen.presentation = 'modal';
CreateEventScreen.screenName = SCREENS.createEvent;

export default CreateEventScreen;
