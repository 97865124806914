import { QueryClient } from 'react-query';
import axios from './axios';

export function queryFnFactory(options = {}) {
  return async ({ queryKey, pageParam }) => {
    const items = [...queryKey];

    let queryStringObj = items.find((item) => 'object' === typeof item);
    if (pageParam) {
      const paginationKey = 'string' === typeof pageParam ? 'cursor' : 'page'; // determine pagination key
      if (queryStringObj) {
        queryStringObj[paginationKey] = pageParam;
      } else {
        queryStringObj = { [paginationKey]: pageParam };
      }
    }

    let url = items.filter((item) => 'object' !== typeof item).join('/');

    if (!url.startsWith('/')) {
      url = `/${url}`;
    }

    const { data } = await axios.request({
      method: 'get',
      url,
      params: queryStringObj,
      ...options,
    });

    return data;
  };
}

function queryClientFactory() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
        retry: (failureCount, err) => 3 > failureCount && ![401, 403, 404, 426].includes(err.response?.status),
        queryFn: queryFnFactory(),
        useErrorBoundary: (error) => 500 <= error.response?.status,
        notifyOnChangeProps: 'tracked',
      },
    },
  });
}

export default queryClientFactory();
