
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({
  "replaceAttrValues": {
    "#000": "{props.color}"
  }
});
        import { File, Flex, Image, Ionicons, Pressable, Skeleton, Text, View } from '@ui/atoms';
import { TextCollapse } from '@ui/molecules';
import PostEmbed from '@ui/organisms/PostEmbed';
import useWebView from '@hooks/useWebView';
import LightboxDialog from '@ui/molecules/LightboxDialog/LightboxDialog';
import Slider from '@ui/molecules/Slider';
import useDialogStore from '@src/stores/dialogStore';

  const LikeOutlinedIcon = __ReactNativeSvgLoader(import('@src/assets/icons/likeOutlined.svg'));
  ;

  const CommentIcon = __ReactNativeSvgLoader(import('@src/assets/icons/comment.svg'));
  ;
import SCREENS from '@constants/Screens';
import { useNavigation } from '@react-navigation/native';
import useThemeColor from '@hooks/useThemeColor';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import { usePost } from '@ui/organisms/Post/postContext';
import { useEffect, useState } from 'react';
import useCommentsQuery from '@hooks/queries/useCommentsQuery';
import COMMENTABLE_MODEL from '@constants/CommentableModel';
import { LinkPreview } from '@ui/organisms';
import { FormattedMessage } from 'react-intl';
import { Platform } from 'react-native';
import useTranslate from '@hooks/useTranslate';
import { useSx } from 'dripsy';

function PostContent({ onPressCommentsCounter, sx }) {
  const sxify = useSx();
  const { open: openDialog } = useDialogStore();
  const primaryColor = useThemeColor('$primary.main');
  const openWebView = useWebView();
  const { t } = useTranslate();
  const { navigate } = useNavigation();

  const post = usePost();

  const { data: comments, isLoading: isLoadingComment } = useCommentsQuery(COMMENTABLE_MODEL.post, post.id);

  const [sliderKey, setSliderKey] = useState(1);

  // Change slider key when images changes to re-render swiper
  useEffect(() => setSliderKey((prev) => prev + 1), [post.images]);

  const openLikesModal = () => navigate(SCREENS.postLikes, { postId: post.id });

  const handlePressFile = () => openWebView(post.file.filename, post.file.url);

  const handlePressImage = (index) => {
    return openDialog(LightboxDialog, {
      images: post.images.map((image) => ({
        id: image.id,
        uri: image.large,
      })),
      defaultIndex: index,
    });
  };

  const [firstImage] = post.images;
  const hasLikes = 0 < post.likesCount;

  const shouldShowLinkPreview = !post.embed && 0 === post.images.length && post.linkMetadata;

  return (
    <View sx={sx}>
      {post.title && (
        <Text variant="subtitle" sx={{ mb: '$2' }}>
          {post.title}
        </Text>
      )}

      {post.content && (
        <TextCollapse numberOfPreviewLines={6} sx={{ mb: '$6' }}>
          {post.content}
        </TextCollapse>
      )}

      {post.link && !shouldShowLinkPreview && (
        <View sx={{ mb: '$6' }}>
          <Text variant="subtitle" sx={{ mb: '$2' }}>
            <FormattedMessage id="ui.Post.PostContent.link" />
          </Text>
          {'web' === Platform.OS ? (
            <a
              style={sxify({ color: '$primary.main' })}
              href={post.link}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              {post.link}
            </a>
          ) : (
            <Text
              sx={{ color: '$primary.main', textDecorationLine: 'underline' }}
              onPress={() => openWebView(post.linkMetadata?.title ?? t('ui.Post.PostContent.link'), post.link)}
            >
              {post.link}
            </Text>
          )}
        </View>
      )}

      {post.link && shouldShowLinkPreview && (
        <LinkPreview url={post.link} metadata={post.linkMetadata} sx={{ mb: '$6' }} />
      )}

      {post.embed && <PostEmbed embed={post.embed} sx={{ mb: '$6' }} />}

      {0 < post.images.length && (
        <View sx={{ flex: 1, mb: '$6', position: 'relative' }}>
          {1 < post.images.length && (
            <>
              <Slider key={sliderKey} height={300}>
                {post.images.map((image, index) => (
                  <Pressable
                    key={image.id}
                    onPress={() => handlePressImage(index)}
                    sx={{ borderRadius: '$1', overflow: 'hidden' }}
                  >
                    <Image
                      source={{ uri: image.small }}
                      sx={{
                        width: '100%',
                        height: 300,
                        resizeMode: 'contain',
                        bg: '$grey.200',
                      }}
                    />
                  </Pressable>
                ))}
              </Slider>
              <View
                sx={{
                  position: 'absolute',
                  bg: '$common.white',
                  borderRadius: 12,
                  height: 24,
                  right: '$1',
                  bottom: '$1',
                  px: '$3',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  boxShadow: 'sm',
                }}
              >
                <Text color="$primary.main">{post.images.length}</Text>
                <Ionicons name="camera" size={18} sx={{ color: '$primary.main', ml: '$1' }} />
              </View>
            </>
          )}
          {1 === post.images.length && (
            <Pressable onPress={() => handlePressImage()} sx={{ borderRadius: '$1', overflow: 'hidden' }}>
              <Image
                source={{
                  uri: firstImage.small,
                }}
                sx={{
                  width: '100%',
                  height: 300,
                  resizeMode: 'contain',
                  bg: '$grey.200',
                }}
              />
            </Pressable>
          )}
        </View>
      )}

      {post.file && (
        <View sx={{ mb: '$6' }}>
          <File label={post.file.name} onPress={handlePressFile} />
        </View>
      )}

      <Flex>
        <Pressable
          disabled={!hasLikes}
          onPress={openLikesModal}
          sx={{ flexDirection: 'row', alignItems: 'center', mr: '$6' }}
        >
          <LikeOutlinedIcon height={16} width={17} color={primaryColor} />
          <Text
            variant="subtitle"
            sx={{ ml: '$2', textDecorationLine: hasLikes ? 'underline' : undefined, textDecorationStyle: 'solid' }}
          >
            {post.likesCount}
          </Text>
        </Pressable>
        {isLoadingComment ? (
          <Skeleton width={50} height={30} />
        ) : (
          <Pressable sx={{ flexDirection: 'row', alignItems: 'center' }} onPress={onPressCommentsCounter}>
            <CommentIcon height={20} width={17} color={primaryColor} />
            <Text variant="subtitle" sx={{ ml: '$2', textDecorationLine: 'underline', textDecorationStyle: 'solid' }}>
              {comments.count}
            </Text>
          </Pressable>
        )}
      </Flex>
    </View>
  );
}

PostContent.propTypes = {
  onPressCommentsCounter: PropTypes.func.isRequired,
  sx: CustomPropTypes.style,
};

PostContent.defaultProps = {
  sx: undefined,
};

export default PostContent;

        