const QUERY_CACHE_KEY = {
  // User
  me: () => ['v1', 'me'],
  profile: () => ['v1', 'profile'],
  blockedUsers: () => ['v1', 'users', 'blocked'],
  notifications: () => ['v1', 'notifications'],
  notificationsSettings: () => ['v1', 'notifications', 'settings'],

  // Residence
  neighbors: () => ['v1', 'neighbors'],
  neighbor: (userId) => ['v1', 'neighbors', userId],
  resources: () => ['v1', 'resources'],
  feed: (query) => ['v1', 'feed', query].filter((v) => v),

  // Posts
  post: (id) => ['v1', 'posts', id].filter((v) => v),
  postCategories: () => ['v1', 'posts', 'categories'],
  postLikes: (postId) => ['v1', 'posts', postId, 'likes'],

  // Comments
  comments: (type, id) => ['v1', 'comments', type, id].filter((v) => v),

  // Events
  event: (id) => ['v1', 'events', id].filter((v) => v),

  // Surveys
  survey: (id, query) => ['v1', 'surveys', id, query].filter((v) => v),
};

export const USER_RELATED_CACHE_KEY = [
  QUERY_CACHE_KEY.blockedUsers(),
  QUERY_CACHE_KEY.neighbors(),
  QUERY_CACHE_KEY.feed(),
  QUERY_CACHE_KEY.comments(),
  QUERY_CACHE_KEY.post(),
  QUERY_CACHE_KEY.event(),
  QUERY_CACHE_KEY.survey(),
];

export default QUERY_CACHE_KEY;
