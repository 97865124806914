import useTranslate from '@hooks/useTranslate';
import { AddPostForm } from '@ui/organisms';
import SCREENS from '@constants/Screens';
import Screen from '@ui/templates/Screen';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import { useNavigation } from '@react-navigation/native';
import useCreatePostMutation from '@hooks/mutations/useCreatePostMutation';
import useToast from '@hooks/useToast';

function AddPostScreen({ route }) {
  const toast = useToast();
  const { t } = useTranslate();
  const { navigate, goBack } = useNavigation();

  const { embed } = route.params || {};

  const { mutateAsync } = useCreatePostMutation();

  const handleSubmit = async (data) => {
    const res = await mutateAsync(data);
    toast.success(t('AddPostScreen.success'));

    goBack();
    navigate(SCREENS.post, { id: res.data.id });
  };

  return (
    <Screen title={t('AddPostScreen.title', { share: !!embed })} presentation={AddPostScreen.presentation} scrollable>
      <AddPostForm onSubmit={handleSubmit} embed={embed} />
    </Screen>
  );
}

AddPostScreen.presentation = 'modal';
AddPostScreen.screenName = SCREENS.addPost;

AddPostScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      embed: PropTypes.shape({
        id: CustomPropTypes.uuid,
        type: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default AddPostScreen;
