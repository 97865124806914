import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';

const PopoverPropTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.node.isRequired,
  width: PropTypes.number,
  offset: PropTypes.number,
  placement: PropTypes.oneOf([
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'bottom-start',
    'bottom-end',
    'left-start',
    'left-end',
  ]),
  sx: CustomPropTypes.style,
  open: PropTypes.bool,
  unmount: PropTypes.bool,
  setOpen: PropTypes.func,
};

export const PopoverDefaultProps = {
  width: undefined,
  offset: 0,
  placement: 'bottom-start',
  sx: undefined,
  open: undefined,
  setOpen: undefined,
  unmount: true,
};

export default PopoverPropTypes;
