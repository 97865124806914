import Screen from '@ui/templates/Screen';
import { Ionicons, Text, View } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import useDeviceDimensions from '@hooks/useDeviceDimensions';
import NotificationsList from '@ui/organisms/NotificationsList';
import { useIsFocused } from '@react-navigation/native';
import SCREENS from '@constants/Screens';
import useNotificationsStore, { countSelector } from '@src/stores/notificationsStore';
import PropTypes from 'prop-types';

function NotificationsScreen() {
  const isFocused = useIsFocused();
  const { width } = useDeviceDimensions();

  return (
    <Screen presentation={NotificationsScreen.presentation} sx={{ p: 0, pt: 0 }}>
      <NotificationsList
        visible={isFocused}
        width={width}
        ListHeaderComponent={
          <View sx={{ p: '$4', pb: '$2' }}>
            <Text variant="subtitle2" sx={{ color: '$primary.main' }}>
              <FormattedMessage id="NotificationsScreen.title" />
            </Text>
          </View>
        }
      />
    </Screen>
  );
}

NotificationsScreen.presentation = 'tab';
NotificationsScreen.screenName = SCREENS.notifications;

function TabBarIcon({ color }) {
  const notifications = useNotificationsStore(countSelector);

  return (
    <View sx={{ position: 'relative' }}>
      <Ionicons name="notifications-outline" size={24} color={color} />
      {0 < notifications ? (
        <View
          sx={{
            position: 'absolute',
            bg: '$red.notifications',
            height: 16,
            width: 16,
            top: -4,
            right: -4,
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text variant="small" sx={{ textAlign: 'center', fontWeight: 'medium', color: '$common.white' }}>
            {notifications}
          </Text>
        </View>
      ) : null}
    </View>
  );
}

TabBarIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

NotificationsScreen.options = {
  tabBarLabel: 'common.tabs.notifications',
  tabBarIcon: ({ color }) => <TabBarIcon color={color} />,
};

export default NotificationsScreen;
