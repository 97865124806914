import { useQueryClient } from 'react-query';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import useModalStore from '@src/stores/modalStore';
import useApiMutation from './useApiMutation';

function useDeletePostMutation(postId, options) {
  const queryClient = useQueryClient();
  const closeAll = useModalStore((state) => state.closeAll);

  return useApiMutation('delete', `/v1/posts/${postId}`, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(QUERY_CACHE_KEY.feed());
      queryClient.resetQueries(QUERY_CACHE_KEY.post(postId));

      closeAll();

      if ('function' === typeof options?.onSuccess) {
        options.onSuccess(...args);
      }
    },
  });
}

export default useDeletePostMutation;
