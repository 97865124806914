import { Avatar, Pressable, Text, UserAvatar, View } from '@ui/atoms';
import CustomPropTypes from '@utils/CustomPropTypes';
import { useSx } from 'dripsy';
import PropTypes from 'prop-types';
import icon from '@src/assets/icon.png';
import { FormattedRelativeTime } from 'react-intl';
import Markdown from 'react-native-markdown-display';

function NotificationRow({ notification, sx, onPress }) {
  const sxify = useSx();

  const background = notification.openAt ? undefined : '$transparent.primary.15';
  const hoverBackground = notification.openAt ? '$grey.200' : '$transparent.primary.20';

  const now = new Date().getTime();
  const offset = parseInt((now - new Date(notification.createdAt).getTime()) / 1000, 10);

  return (
    <Pressable
      onPress={() => onPress(notification)}
      style={(state) => {
        return sxify({
          p: '$4',
          flexDirection: 'row',
          alignItems: 'center',
          bg: state.hovered ? hoverBackground : background,
          ...sx,
        });
      }}
    >
      {notification.sender ? (
        <UserAvatar size="large" user={notification.sender} />
      ) : (
        <Avatar size="large" source={icon} />
      )}
      <View sx={{ ml: '$4', flex: 1 }}>
        <Markdown style={{ paragraph: { marginTop: 0, marginBottom: 0 } }}>{notification.message}</Markdown>
        <Text variant="small" sx={{ color: '$grey.700', mt: 2 }}>
          <FormattedRelativeTime value={offset * -1} updateIntervalInSeconds={120} />
          {notification.data?.residence && (
            <Text variant="small" sx={{ color: '$grey.700', mt: 2 }}>
              {' '}
              &bull; {notification.data.residence}
            </Text>
          )}
        </Text>
      </View>
    </Pressable>
  );
}

NotificationRow.propTypes = {
  onPress: PropTypes.func.isRequired,
  notification: CustomPropTypes.notification.isRequired,
  sx: CustomPropTypes.style,
};

NotificationRow.defaultProps = {
  sx: undefined,
};

export default NotificationRow;
