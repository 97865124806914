import { Button, Ionicons, Skeleton, Text, UserAvatar, View } from '@ui/atoms';
import { useNavigation } from '@react-navigation/native';
import useBlockUserMutation from '@hooks/mutations/useBlockUserMutation';
import PropTypes from 'prop-types';
import useNeighborQuery from '@hooks/queries/useNeighborQuery';
import { FormattedMessage } from 'react-intl';
import useTranslate from '@hooks/useTranslate';
import Screen from '@ui/templates/Screen/Screen';
import SCREENS from '@constants/Screens';
import useCurrentResidenceQuery from '@hooks/queries/useCurrentResidenceQuery';
import useConfirmAlert from '@hooks/useConfirmAlert';

function NeighborScreen({ route }) {
  const { t } = useTranslate();
  const showConfirmAlert = useConfirmAlert();
  const { goBack } = useNavigation();

  const { id } = route.params;

  const { data: residence } = useCurrentResidenceQuery();

  const { data: user, isLoading } = useNeighborQuery(id);
  const { mutateAsync: blockUserAsync } = useBlockUserMutation(id);

  const handleBlock = () => {
    showConfirmAlert({
      title: t('NeighborScreen.confirmBlockUser.title', { firstname: user.firstname }),
      description: t('NeighborScreen.confirmBlockUser.description'),
      destructive: true,
      confirmLabel: t('NeighborScreen.confirmBlockUser.confirm'),
      onConfirm: async () => {
        await blockUserAsync();
        goBack();
      },
    });
  };

  return (
    <Screen title={isLoading ? t('common.loading') : user.fullname} presentation={NeighborScreen.presentation}>
      <View
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          mt: '$4',
        }}
      >
        <View>
          <UserAvatar hideBadge sx={{ mr: '$2', alignSelf: 'center' }} size="extraLarge" user={user || {}} />
        </View>
        <View sx={{ mt: '$4', alignItems: 'center' }}>
          {isLoading ? (
            <Skeleton width={150} height={10} />
          ) : (
            <Text variant="subtitle2" sx={{ textAlign: 'center' }}>
              {user.fullname}
            </Text>
          )}
        </View>
        <Text sx={{ textAlign: 'center' }}>{user?.flatNumber}</Text>
        {isLoading ? (
          <View sx={{ mt: '$4', alignItems: 'center' }}>
            <Skeleton width={250} height={10} />
            <Skeleton sx={{ mt: '$2' }} width={180} height={10} />
            <Skeleton sx={{ mt: '$2' }} width={250} height={10} />
            <Skeleton sx={{ mt: '$2' }} width={180} height={10} />
          </View>
        ) : (
          <Text variant="regular" sx={{ textAlign: 'center', mt: '$4' }}>
            {user?.description}
          </Text>
        )}
        <View sx={{ mt: '$6', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <Ionicons name="home-sharp" size={11} sx={{ color: '$grey.600' }} />
          <Text sx={{ ml: '$2', textAlign: 'center', color: '$grey.600' }}>
            <FormattedMessage id="NeighborScreen.residence" />
          </Text>
        </View>
        <Text sx={{ textAlign: 'center' }}>{residence?.name}</Text>

        <View sx={{ mt: '$6', width: '40%', alignSelf: 'center' }}>
          <Button variant="outlined" color="red" onPress={handleBlock} title={t('common.verb.block')} />
        </View>
      </View>
    </Screen>
  );
}

NeighborScreen.presentation = {
  web: 'modal',
  default: 'card',
};

NeighborScreen.screenName = SCREENS.neighbor;

NeighborScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default NeighborScreen;
