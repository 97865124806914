import { AutoHeightInput, Button, Input, Text, View } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import { FormControl } from '@ui/molecules';
import useTranslate from '@hooks/useTranslate';
import { useFormik } from 'formik';
import { Platform } from 'react-native';
import { mixed, object, string } from '@utils/yup';
import useUploadMutation from '@hooks/mutations/useUploadMutation';
import useToast from '@hooks/useToast';
import { isAfter, addHours, setMinutes } from 'date-fns';
import Datepicker from '@ui/molecules/Datepicker';
import { useMemo, useState } from 'react';
import apiErrorsToFormik from '@utils/apiErrorsToFormik';
import deleteEmptyEntries from '@utils/deleteEmptyEntries';
import ImagesPicker from '@ui/organisms/ImagesPicker';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';

function EventForm({ event, onSubmit }) {
  const { t } = useTranslate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const defaultStart = useMemo(() => {
    let output = new Date();
    const minutes = output.getMinutes();
    // Find next quarter
    const nextQuarter = 0 !== minutes % 15 ? minutes - (minutes % 15) + 15 : minutes;
    output = setMinutes(output, nextQuarter);

    return output;
  }, []);

  const form = useFormik({
    initialValues: {
      title: event?.title || '',
      description: event?.description || '',
      startAt: event?.startAt ? new Date(event?.startAt) : defaultStart,
      endAt: event?.endAt ? new Date(event?.endAt) : addHours(defaultStart, 1),
      location: event?.location || '',
      thumbnail: event?.thumbnail?.isDefault ? undefined : event?.thumbnail,
    },
    validationSchema: object({
      title: string().min(2).required().max(60),
      description: string().min(2).required().max(600),
      startAt: string().nullable(),
      endAt: string().nullable(),
      location: string().min(2).required(),
      thumbnail: mixed().nullable(),
    }),
    onSubmit: async (data, { setErrors }) => {
      try {
        setLoading(true);

        const values = { ...data };
        if (values.thumbnail?.id === event?.thumbnail?.id) {
          values.thumbnail = undefined;
        } else {
          values.thumbnail = data.thumbnail?.id;
          if (null === data.thumbnail) {
            values.shouldDeleteThumbnail = true;
          }
        }

        await onSubmit(deleteEmptyEntries(values));
      } catch (err) {
        if (422 === err.response?.status) {
          apiErrorsToFormik(err.response.data.errors, setErrors);
          toast.error(t('errors.checkData'));
        } else {
          toast.error(t('errors.api.default'));
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const { mutate: uploadImage, isLoading: uploadingImage } = useUploadMutation({
    onSuccess: (res) => {
      form.setFieldValue('thumbnail', res.data);
    },
    onError: (err) => {
      if (422 === err.response?.status) {
        toast.error(err.response.data.errors?.file[0]);
      } else {
        toast.error(t('errors.api.default'));
      }
    },
  });

  const handleDeleteImage = () => form.setFieldValue('thumbnail', null);

  const handleStartConfirm = (date) => {
    form.setFieldValue('startAt', date);
    if (isAfter(date, form.values.endAt)) {
      form.setFieldValue('endAt', addHours(date, 1));
    }
  };

  const handleEndConfirm = (date) => form.setFieldValue('endAt', date);
  const handleSubmit = () => form.handleSubmit();

  const onSubmitEditing = Platform.select({ web: form.submitForm, default: undefined });

  return (
    <View>
      <FormControl
        label={t('ui.EventForm.title')}
        error={form.touched.title && form.errors.title}
        required
        sx={{ mb: '$4' }}
      >
        <Input
          value={form.values.title}
          maxLength={60}
          onChangeText={form.handleChange('title')}
          onBlur={form.handleBlur('title')}
          onSubmitEditing={onSubmitEditing}
        />
      </FormControl>
      <FormControl
        label={t('ui.EventForm.description')}
        sx={{ mb: '$4' }}
        error={form.touched.description && form.errors.description}
        required
      >
        <AutoHeightInput
          minHeight={100}
          maxLength={600}
          withCharCounter
          value={form.values.description}
          onChangeText={form.handleChange('description')}
          onBlur={form.handleBlur('description')}
        />
      </FormControl>
      <FormControl label={t('ui.EventForm.start')} sx={{ mb: '$4' }} required>
        <Datepicker value={form.values.startAt} onChange={handleStartConfirm} minDate={new Date()} withTime />
      </FormControl>
      <FormControl label={t('ui.EventForm.end')} sx={{ mb: '$4' }} required>
        <Datepicker value={form.values.endAt} onChange={handleEndConfirm} minDate={form.values.startAt} withTime />
      </FormControl>
      <FormControl
        label={t('ui.EventForm.location')}
        error={form.touched.location && form.errors.location}
        maxLength={30}
        withCharCounter
        required
        sx={{ mb: '$4' }}
      >
        <Input
          value={form.values.location}
          onChangeText={form.handleChange('location')}
          onBlur={form.handleBlur('location')}
          onSubmitEditing={onSubmitEditing}
        />
        <Text sx={{ mt: '$1', color: '$grey.600', fontSize: 12 }}>
          <FormattedMessage id="ui.EventForm.locationHelp" />
        </Text>
      </FormControl>
      <FormControl
        label={t('ui.EventForm.addMedia')}
        error={form.touched.addMedia && form.errors.addMedia}
        sx={{ mb: '$4' }}
      >
        <ImagesPicker
          images={
            form.values.thumbnail
              ? [
                  {
                    id: form.values.thumbnail.id,
                    uri: form.values.thumbnail.preview ?? form.values.thumbnail.small,
                  },
                ]
              : undefined
          }
          max={1}
          loading={uploadingImage}
          onSelect={uploadImage}
          onDelete={handleDeleteImage}
        />
        <Text sx={{ mt: '$1', color: '$grey.600', fontSize: 12 }}>
          <FormattedMessage id="ui.EventForm.noImage" />
        </Text>
      </FormControl>
      <Button title={t('ui.EventForm.submit')} color="primary" loading={loading} onPress={handleSubmit} />
    </View>
  );
}

EventForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  event: CustomPropTypes.event,
};

EventForm.defaultProps = {
  event: undefined,
};

export default EventForm;
