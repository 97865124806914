import { useQueryClient } from 'react-query';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import useApiMutation from './useApiMutation';

function useAnswerSurveyMutation(surveyId, options) {
  const queryClient = useQueryClient();

  return useApiMutation('put', `/v1/surveys/${surveyId}/answer`, {
    ...options,
    onSuccess: async (res, ...rest) => {
      // Provide fast feedback to user
      queryClient.setQueryData(QUERY_CACHE_KEY.survey(surveyId), res.data);

      // Get fresh data for all related queries
      queryClient.invalidateQueries(QUERY_CACHE_KEY.survey(surveyId));

      if ('function' === typeof options?.onSuccess) {
        options.onSuccess(res, ...rest);
      }
    },
  });
}

export default useAnswerSurveyMutation;
