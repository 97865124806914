import {
  mixed,
  string,
  number,
  bool,
  boolean,
  date,
  object,
  array,
  ref,
  lazy,
  reach,
  isSchema,
  addMethod,
  setLocale,
  ValidationError,
} from 'yup';

setLocale({
  mixed: {
    required: { key: 'errors.validation.required' },
    oneOf: { key: 'errors.validation.invalid' },
  },
  number: {
    positive: { key: 'errors.validation.number.positive' },
    min: ({ min }) => ({ key: 'errors.validation.number.min', values: { min } }),
    lessThan: ({ less }) => ({ key: 'errors.validation.number.lessThan', values: { less } }),
  },
  array: {
    min: ({ min }) => ({ key: 'errors.validation.array.min', values: { min } }),
  },
  string: {
    min: ({ min }) => ({ key: 'errors.validation.string.min', values: { min } }),
    max: ({ max }) => ({ key: 'errors.validation.string.min', values: { max } }),
    email: { key: 'errors.validation.string.email' },
  },
});

export const password = () => {
  return string().matches(/^(?=.{8,}$)(?=.*[a-z])(?=.*[0-9]).*$/, {
    message: { key: 'errors.validation.string.password' },
  });
};

addMethod(mixed, 'emptyAsNull', function emptyAsNull() {
  return this.nullable().transform((curr, orig) => ('' === orig ? null : curr));
});

export {
  mixed,
  string,
  number,
  bool,
  boolean,
  date,
  object,
  array,
  ref,
  lazy,
  reach,
  isSchema,
  addMethod,
  setLocale,
  ValidationError,
};
