import { Button, Ionicons, View } from '@ui/atoms';
import PropTypes from 'prop-types';
import useTranslate from '@hooks/useTranslate';
import useThemeColor from '@hooks/useThemeColor';
import { useState, useCallback } from 'react';
import useUploadMutation from '@hooks/mutations/useUploadMutation';
import 'react-native-get-random-values';
import { v4 as uuid } from 'uuid';
import ChoiceRow from '@ui/organisms/SurveyForm/ChoiceRow';
import useToast from '@hooks/useToast';
import CustomPropTypes from '@utils/CustomPropTypes';

function ChoicesList({ choices, setValues, errors, touched }) {
  const { t } = useTranslate();
  const toast = useToast();

  const primaryColor = useThemeColor('$primary.main');

  const [uploadingChoicePictures, setUploadingChoicePictures] = useState([]);

  const { mutateAsync: uploadImage } = useUploadMutation();

  const handleAddRow = () => {
    setValues((prev) => ({
      ...prev,
      choices: [
        ...choices,
        {
          id: uuid(),
          name: '',
          image: null,
        },
      ],
    }));
  };

  const handleDeleteRow = (id) => {
    setValues((prev) => ({
      ...prev,
      choices: prev.choices.filter((choice) => choice.id !== id),
    }));
  };

  const setChoiceValues = useCallback(
    (id, data) => {
      return setValues((prev) => ({
        ...prev,
        choices: prev.choices.map((choice) => {
          if (choice.id === id) {
            const newData = 'function' === typeof data ? data(choice) : data;
            return { ...choice, ...newData };
          }

          return choice;
        }),
      }));
    },
    [setValues]
  );

  const handleChangeName = (id, value) => setChoiceValues(id, { name: value });

  const handleUploadImage = useCallback(
    async (id, file) => {
      try {
        setUploadingChoicePictures((prev) => [...prev, id]);

        const res = await uploadImage(file);
        setChoiceValues(id, { image: res.data });
      } catch (err) {
        if (422 === err.response?.status) {
          toast.error(err.response.data.errors?.file[0]);
        } else {
          toast.error(t('errors.api.default'));
        }
      } finally {
        setUploadingChoicePictures((prev) => prev.filter((uploadingId) => uploadingId !== id));
      }
    },
    [setChoiceValues, t, toast, uploadImage]
  );

  const handleDeleteImage = (id) => setChoiceValues(id, { image: null });

  return (
    <>
      {choices.map((choice, index) => (
        <View sx={{ mb: '$4' }} key={choice.id}>
          <ChoiceRow
            id={choice.id}
            required={[0, 1].includes(index)}
            uploading={uploadingChoicePictures.includes(choice.id)}
            name={choice.name}
            image={choice.image}
            number={index + 1}
            onChangeName={(value) => handleChangeName(choice.id, value)}
            onDeleteImage={() => handleDeleteImage(choice.id)}
            onDeleteRow={[0, 1].includes(index) ? undefined : () => handleDeleteRow(choice.id)}
            onSelectImage={(file) => handleUploadImage(choice.id, file)}
            errors={errors?.[index]}
            touched={touched?.[index]}
          />
        </View>
      ))}
      {6 > choices.length && (
        <View sx={{ mt: '$2' }}>
          <Button
            startIcon={<Ionicons name="ios-add-outline" color={primaryColor} size={20} />}
            color="primary"
            title={t('ui.SurveyForm.ChoicesList.add')}
            onPress={handleAddRow}
            variant="outlined"
          />
        </View>
      )}
    </>
  );
}

ChoicesList.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: CustomPropTypes.uuid,
      name: PropTypes.string,
    })
  ).isRequired,
  setValues: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(CustomPropTypes.formErrors),
  touched: PropTypes.arrayOf(CustomPropTypes.formTouched),
};

ChoicesList.defaultProps = {
  errors: undefined,
  touched: undefined,
};

export default ChoicesList;
