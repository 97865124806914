import { useQueryClient } from 'react-query';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import useApiMutation from './useApiMutation';

function useUpdateNotificationsSettingsMutation(options) {
  const queryClient = useQueryClient();
  const queryKey = QUERY_CACHE_KEY.notificationsSettings();

  return useApiMutation('patch', `/v1/notifications/settings`, {
    ...options,
    onMutate: (data) => {
      queryClient.cancelQueries(queryKey);

      // Locally update affected items
      queryClient.setQueryData(queryKey, (prev) => {
        const { settings } = data;

        return {
          ...prev,
          items: prev.items.map((item) => {
            const updatedItem = settings.find((i) => i.type === item.type);
            if (updatedItem) {
              return { ...item, ...updatedItem };
            }

            return item;
          }),
        };
      });
    },
    onError: (...args) => {
      queryClient.invalidateQueries(queryKey);
      if ('function' === typeof options?.onError) {
        options.onError(...args);
      }
    },
  });
}

export default useUpdateNotificationsSettingsMutation;
