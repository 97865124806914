import useApiMutation from '@hooks/mutations/useApiMutation';
import QUERY_CACHE_KEY from '@constants/QueryCacheKey';
import { useQueryClient } from 'react-query';

function useReplyEventMutation(id, options) {
  const queryClient = useQueryClient();

  return useApiMutation('put', `/v1/events/${id}/reply`, {
    ...options,
    onSuccess: (res, data) => {
      // Fast feedback for user
      queryClient.setQueryData(QUERY_CACHE_KEY.event(id), (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          currentUserReply: data.reply,
        };
      });

      queryClient.invalidateQueries(QUERY_CACHE_KEY.event(id));
    },
  });
}

export default useReplyEventMutation;
