// eslint-disable-next-line no-restricted-imports
import { Image } from 'react-native';

function getImageSize(source) {
  return new Promise((resolve, reject) => {
    try {
      if ('string' === typeof source || source.uri) {
        // URI file (or local file for RN web)
        Image.getSize(source.uri || source, (width, height) => resolve({ width, height }), reject);
      } else {
        // Local file
        const { width, height } = Image.resolveAssetSource(source);
        resolve({ width, height });
      }
    } catch (err) {
      reject(err);
    }
  });
}

export default getImageSize;
