import { Platform } from 'react-native';

function determineScreenPresentation(config) {
  if (!config) {
    return 'card';
  }

  return 'string' === typeof config ? config : Platform.select(config);
}

export default determineScreenPresentation;
